import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './App';
import Signup from 'scenes/Auth/Signup/SignupContainer';
import Signin from 'scenes/Auth/Signin/SigninContainer';
import Activate from 'scenes/Auth/Activate/ActivateContainer';
import Private from 'scenes/Core/Private/PrivateContainer';
import Admin from 'scenes/Admin/Admin/AdminContainer';
import Administrate from 'scenes/Admin/Administrate/AdministrateContainer';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import AdminRoute from 'components/AdminRoute/AdminRoute';
import SuperAdminRoute from 'components/SuperAdminRoute/SuperAdminRoute';
import Forgot from 'scenes/Auth/Forgot/ForgotContainer';
import Reset from 'scenes/Auth/Reset/ResetContainer';
import Add from 'scenes/Core/Add/AddContainer';
import Modify from 'scenes/Core/Modify/ModifyContainer';
import Report from 'scenes/Core/Report/ReportContainer';
import AddLocation from 'scenes/Admin/AddLocation/AddLocationContainer';
import AddArea from 'scenes/Admin/AddArea/AddAreaContainer';
import Contacts from 'scenes/Core/Contacts/ContactsContainer';
import PrivacyPolicy from 'components/PrivacyPolicy/PrivacyPolicy';
import Settings from 'scenes/Admin/Settings/SettingsContainer';
import Debriefing from 'scenes/Core/Debriefing/DebriefingContainer';
import News from 'scenes/Core/News/NewsContainer';
import AddNews from 'scenes/Admin/AddNews/AddNewsContainer';
import AdminReport from 'scenes/Admin/AdminReport/AdminReportContainer';
import ConnectionReport from 'scenes/Admin/ConnectionReport/ConnectionReportContainer';
import PdfGenerator from 'components/PdfGenerator/PdfGeneratorContainer';

import { useClearBrowserCache } from 'react-clear-browser-cache';

const Routes = () => {
    // eslint-disable-next-line 
    const contextValue = useClearBrowserCache();
    return (
        <BrowserRouter>
            <Switch>
                
                <Route path="/" exact component={App} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/signin" exact component={Signin} />
                <Route path="/auth/activate/:token" exact component={Activate} />
                <Route path="/auth/password/forgot" exact component={Forgot} />
                <Route path="/auth/password/reset/:token" exact component={Reset} />
                <PrivateRoute path="/private" exact component={Private} />
                <AdminRoute path="/admin" exact component={Admin} />
                <AdminRoute path="/administrate" exact component={Administrate} />
                <AdminRoute path="/add-location" exact component={AddLocation} />
                <AdminRoute path="/add-area" exact component={AddArea} />
                <AdminRoute path="/settings" exact component={Settings} />
                <AdminRoute path="/add/news" exact component={AddNews} />
                <AdminRoute path="/admin/report" exact component={AdminReport} />
                <AdminRoute path="/admin/report/pdf" exact component={PdfGenerator} />
                <SuperAdminRoute path="/admin/connection-report" exact component={ConnectionReport} />
                
                <Route path="/add" exact component={Add} />
                <Route path="/modify" exact component={Modify} />
                <Route path="/report" exact component={Report} />
                <Route path="/debriefing" exact component={Debriefing} />

                <Route path="/contacts" exact component={Contacts} />
                <Route path="/news" exact component={News} />
                <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
