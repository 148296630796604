// == Action types ======================

export const CHANGE_DATALIST_TRIP_VALUE = 'CHANGE_DATALIST_TRIP_VALUE';
export const LOAD_MEMBERSHIPS = 'LOAD_MEMBERSHIPS';
export const LOAD_DEPARTMENTS = 'LOAD_DEPARTMENTS';
export const LOAD_CITIES = 'LOAD_CITIES';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_LOCATIONS = 'LOAD_LOCATIONS';
export const LOAD_TRIPS = 'LOAD_TRIPS';
export const LOAD_TRIPS_BY_ID = 'LOAD_TRIPS_BY_ID';
export const LOAD_SPECIES = 'LOAD_SPECIES';
export const LOAD_WEATHER = 'LOAD_WEATHER';
export const LOAD_ROLES = 'LOAD_ROLES';
export const LOAD_BLOOD_HANDLERS = 'LOAD_BLOOD_HANDLERS';
export const LOAD_RESTRICTED_AREAS = 'LOAD_RESTRICTED_AREAS';
export const LOAD_DEBRIEFINGS = 'LOAD_DEBRIEFINGS';
export const LOAD_TAKINGS = 'LOAD_TAKINGS';
export const LOAD_NEWS = 'LOAD_NEWS';
export const LOAD_ADMIN_REPORTS = 'LOAD_ADMIN_REPORTS';
export const LOAD_CONNECTION_REPORT = 'LOAD_CONNECTION_REPORT';

export const SAVE_MEMBERSHIPS = 'SAVE_MEMBERSHIPS';
export const SAVE_DEPARTMENTS = 'SAVE_DEPARTMENTS';
export const SAVE_CITIES = 'SAVE_CITIES';
export const SAVE_USERS = 'SAVE_USERS';
export const SAVE_BLOOD_HANDLERS = 'SAVE_BLOOD_HANDLERS';
export const SAVE_LOCATIONS = 'SAVE_LOCATIONS';
export const SAVE_TRIPS = 'SAVE_TRIPS';
export const SAVE_TRIPS_BY_ID = 'SAVE_TRIPS_BY_ID';
export const SAVE_SPECIES = 'SAVE_SPECIES';
export const SAVE_WEATHER = 'SAVE_WEATHER';
export const SAVE_ROLES = 'SAVE_ROLES';
export const SAVE_RESTRICTED_AREAS = 'SAVE_RESTRICTED_AREAS';
export const SAVE_DEBRIEFINGS = 'SAVE_DEBRIEFINGS';
export const SAVE_TAKINGS = 'SAVE_TAKINGS';
export const SAVE_NEWS = 'SAVE_NEWS';
export const SAVE_ADMIN_REPORTS = 'SAVE_ADMIN_REPORTS';
export const SAVE_CONNECTION_REPORT = 'SAVE_CONNECTION_REPORT';
export const UPDATE_TRIP = 'UPDATE_TRIP';
export const DELETE_TRIP = 'DELETE_TRIP';
export const DELETE_TRIP_IN_REDUCER = 'DELETE_TRIP_IN_REDUCER';
export const SAVE_CHECKED_DEBRIEFING = 'SAVE_CHECKED_DEBRIEFING';
export const SAVE_TAKINGS_IN_STORE = 'SAVE_TAKINGS_IN_STORE';
export const SIGNOUT = 'SIGNOUT';


// == Action creators

export const changeValue = (tripIndex, name, value) => ({
  type: CHANGE_DATALIST_TRIP_VALUE,
  tripIndex,
  name,
  value,
});

export const loadMemberships = () => ({
  type: LOAD_MEMBERSHIPS,
});

export const loadDepartments = () => ({
  type: LOAD_DEPARTMENTS,
});

export const loadCities = () => ({
  type: LOAD_CITIES,
});

export const loadUsers = () =>
({
  type: LOAD_USERS,
});

export const loadBloodHandlers = () =>
({
  type: LOAD_BLOOD_HANDLERS,
});

export const loadLocations = () => ({
  type: LOAD_LOCATIONS,
});

export const loadTrips = () => ({
  type: LOAD_TRIPS,
});

export const loadTripsById = () => ({
  type: LOAD_TRIPS_BY_ID,
});

export const loadSpecies = () => ({
  type: LOAD_SPECIES,
});

export const loadWeather = () => ({
  type: LOAD_WEATHER,
});

export const loadRoles = () => ({
  type: LOAD_ROLES,
});

export const loadRestrictedAreas = () => ({
  type: LOAD_RESTRICTED_AREAS,
});

export const loadDebriefings = () => ({
  type: LOAD_DEBRIEFINGS,
});

export const loadTakings = () => ({
  type: LOAD_TAKINGS,
});

export const loadNews = () => ({
  type: LOAD_NEWS,
});

export const loadAdminReports = () => ({
  type: LOAD_ADMIN_REPORTS,
});

export const loadConnectionReport = () => ({
  type: LOAD_CONNECTION_REPORT,
});

export const saveMemberships = (memberships) => ({
  type: SAVE_MEMBERSHIPS,
  memberships,
});

export const saveDepartments = (departments) => ({
  type: SAVE_DEPARTMENTS,
  departments,
});

export const saveCities = (cities) => ({
  type: SAVE_CITIES,
  cities,
});

export const saveUsers = (users) => ({
  type: SAVE_USERS,
  users,
});

export const saveBloodHandlers = (bloodHandlers) => ({
  type: SAVE_BLOOD_HANDLERS,
  bloodHandlers,
});

export const saveLocations = (locations) => ({
  type: SAVE_LOCATIONS,
  locations,
});

export const saveTrips = (trips) => ({
  type: SAVE_TRIPS,
  trips,
});

export const saveTripsById = (trips) => ({
  type: SAVE_TRIPS_BY_ID,
  trips,
});

export const saveSpecies = (species) => ({
  type: SAVE_SPECIES,
  species,
});

export const saveWeather = (weather) => ({
  type: SAVE_WEATHER,
  weather,
});

export const saveRoles = (roles) => ({
  type: SAVE_ROLES,
  roles,
});

export const saveRestrictedAreas = (restrictedAreas) => ({
  type: SAVE_RESTRICTED_AREAS,
  restrictedAreas,
});

export const saveDebriefings = (debriefings) => ({
  type: SAVE_DEBRIEFINGS,
  debriefings,
});

export const saveTakings = (takings) => ({
  type: SAVE_TAKINGS,
  takings,
});

export const saveNews = (news) => ({
  type: SAVE_NEWS,
  news,
});

export const saveAdminReports = (reports) => ({
  type: SAVE_ADMIN_REPORTS,
  reports,
});

export const saveConnectionReport = (reports) => ({
  type: SAVE_CONNECTION_REPORT,
  reports,
});

export const saveTakingsInStore = (tripId, takings) => ({
  type: SAVE_TAKINGS_IN_STORE,
  tripId,
  takings
});

export const updateTrip = (tripIndex) => ({
  type: UPDATE_TRIP,
  tripIndex,
});

//Parse data to middleware
export const deleteTrip = (tripIndex) => ({
  type: DELETE_TRIP,
  tripIndex,
});

export const deleteTripInReducer = (tripId) => ({
  type: DELETE_TRIP_IN_REDUCER,
  tripId,
});

// == Reducer
const initialState = {
  departmentsData: [],
  membershipsData: [],
  citiesData: [],
  usersData: [],
  locationsData: [],
  restrictedAreasData: [],
  tripsData: [],
  rolesData: [],
  tripsByIdData: [],
  speciesData: [],
  weatherData: {},
  bloodHandlersData: [],
  debriefingsData: [],
  takingsData: [],
  newsData: [],
  adminReportsData: [],
  connectionReportData: [],
};

function dataListReducer(state = initialState, action = {}) {

  switch (action.type) {

    // case CHANGE_VALUE:
    //   // console.log(action.name)
    //   return {
    //     ...state,
    //     [action.name]: action.value,
    //   };

    case SAVE_MEMBERSHIPS:
      console.log(action.memberships)
      return {
        ...state,
        membershipsData: action.memberships,
      };

    case SAVE_DEPARTMENTS:
      console.log(action.departments)
      return {
        ...state,
        departmentsData: action.departments,
      };

    case SAVE_CITIES:
      console.log(action.cities)
      return {
        ...state,
        citiesData: action.cities,
      };

    case SAVE_USERS:
      return {
        ...state,
        usersData: action.users,
      };

    case SAVE_BLOOD_HANDLERS:
      return {
        ...state,
        bloodHandlersData: action.bloodHandlers,
      };

    case SAVE_LOCATIONS:
      return {
        ...state,
        locationsData: action.locations,
      };

    case SAVE_TRIPS:
      return {
        ...state,
        tripsData: action.trips,
      };

    case SAVE_TRIPS_BY_ID:
      console.log(action.trips);
      return {
        ...state,
        tripsByIdData: action.trips,
      };

    case SAVE_SPECIES:
      console.log(action.species);
      return {
        ...state,
        speciesData: action.species,
      };

    case SAVE_WEATHER:
      console.log(action.weather);
      return {
        ...state,
        weatherData: action.weather,
      };

    case SAVE_ROLES:
      console.log(action.roles);
      return {
        ...state,
        rolesData: action.roles,
      };

    case SAVE_DEBRIEFINGS:
      console.log(action.debriefings);
      return {
        ...state,
        debriefingsData: action.debriefings,
      };

    case SAVE_TAKINGS:
      console.log(action.takings);
      return {
        ...state,
        takingsData: action.takings,
      };

    case SAVE_NEWS:
      console.log(action.news);
      return {
        ...state,
        newsData: action.news,
      };

    case SAVE_ADMIN_REPORTS:
      return {
        ...state,
        adminReportsData: action.reports,
      };

    case SAVE_CONNECTION_REPORT:
      return {
        ...state,
        connectionReportData: action.reports,
      };

    case SAVE_CHECKED_DEBRIEFING:
      console.log(state.debriefingsData)
      return state
    // return {
    //   ...state,
    //   debriefingsData: state.debriefingsData.map((trip, index) => {
    //     if (trip.id === action.tripId && trip.debriefing !== null && trip.debriefing !== '') {
    //       return {
    //         ...trip,
    //         deb_consulted: true,
    //       }
    //     } else {
    //       return trip
    //     }
    //   })
    // }

    case UPDATE_TRIP:
      return {
        ...state,
        tripsData:
          state.tripsData.map((currentTrip) => {
            if (state.tripsData.indexOf(currentTrip) === action.tripIndex) {
              return { ...currentTrip, state: 1 }
            }
            else
              return currentTrip
          },
          )

      };


    case SAVE_RESTRICTED_AREAS:
      console.log(action.restrictedAreas);
      return {
        ...state,
        restrictedAreasData: action.restrictedAreas.map(restrictedArea => ({
          id: restrictedArea.id,
          name: restrictedArea.name,
          active: restrictedArea.active,
          coordinates: JSON.parse(restrictedArea.coordinates)
        })),
      };

    case SAVE_TAKINGS_IN_STORE:
      console.log(action.restrictedAreas);
      return {
        ...state,
        tripsByIdData: state.tripsByIdData.map(trip => {
          if (trip.id === action.tripId) {
            return {
              ...trip, takings: action.takings
            }
          } else {
            return trip
          }
        }),
      };

    case CHANGE_DATALIST_TRIP_VALUE:
      return {
        ...state,
        tripsData:
          state.tripsData.map((currentTrip) => {
            if (state.tripsData.indexOf(currentTrip) === action.tripIndex) {
              return { ...currentTrip, [action.name]: action.value }
            }
            else
              return currentTrip
          },
          )

      };
    // Delete trip in reducer after delete in database
    case DELETE_TRIP_IN_REDUCER:
      console.log(action)
      return {
        ...state,
        tripsData:
          state.tripsData.filter((currentTrip) => currentTrip.id !== action.tripId)
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default dataListReducer;
