
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = ({ children, match }) => {
    
    const footer = () => (
        <>
            <div className="footer-container">
                <ul className="footer-ul row">
                <li className="col-md-6 justify-content-center">Cooked with <FontAwesomeIcon color='#edcda6' icon={faHeart} /> by Jonathan Dujols</li>

                    <li className="col-md-6"><Link to="/privacy-policy" className="forgotten-password">
                            Mentions Légales
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );

    return (
        <>
            {footer()}
        </>
    );
};

export default withRouter(Footer);
