/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from 'services/security/helpers'

import {
  SAVE_A_TAKING,
} from 'services/ducks/modules/taking';

const takingMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case SAVE_A_TAKING:
      console.log('le middleware save takings est appelé');
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/taking/report/${store.getState().taking.tripId}`,
        data: {
          cityId: store.getState().auth.cityId,
          takings: store.getState().taking.takings,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('REPORT SAVED SUCCESS', response);
          if (response.data.status === 'warning') {
            toast.warning(response.data.message);
          } else {
            toast.success(response.data.message);
          }
        })
        .catch(error => {
          console.log('REPORT SAVED ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    default:
      next(action);
  }
};

export default takingMiddleware;