import { connect } from 'react-redux';

import { changeValue, addNewRestrictedArea, updateRestrictedArea, deleteRestrictedArea } from 'services/ducks/modules/map';

import AddAreaForm from 'scenes/Admin/AddArea/AddAreaForm/AddAreaForm';

const mapStateToProps = (state) => ({
  restrictedAreasData: state.dataList.restrictedAreasData,
  restrictedAreaId: state.map.restrictedAreaId,
  areaName: state.map.areaName,
  areaActive: state.map.areaActive,
  addAreaChoice: state.map.addAreaChoice
});

const mapDispatchToProps = (dispatch) => ({
  addNewRestrictedArea: () => {
    dispatch(addNewRestrictedArea());
  },
  updateRestrictedArea: () => {
    dispatch(updateRestrictedArea());
  },
  deleteRestrictedArea: () => {
    dispatch(deleteRestrictedArea());
  },
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAreaForm);