import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/LayoutContainer';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Fade from 'react-reveal/Zoom';
import PropTypes from 'prop-types';

import moment from 'moment';

const ConnectionReport = ({
    cityId,
    loadConnectionReport,
    connectionReportData,
    connectionRecordLoader
}) => {

    const [records, setRecords] = useState([]);

    useEffect(() => {
        cityId !== null && loadConnectionReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null, connectionReportData === []]);

    useEffect(() => {
        let arrayRecord = [];
        connectionReportData.map((currentRecord) => {
            arrayRecord.push({ id: currentRecord.id ,name: currentRecord.user.firstname + ' ' + currentRecord.user.lastname, date: moment(currentRecord.connectingDate).format('dddd DD/MM/YY à HH:mm') });
            return false;
        })
        setRecords(arrayRecord);
    }, [connectionReportData]);

    const columns = [{
        dataField: 'id',
        text: 'Product ID',
        hidden: true
      },{
        dataField: 'name',
        text: 'Nom',
        headerStyle: {
            color: 'white',
            textAlign: 'center',
            backgroundColor: 'rgb(147, 147, 147, 0.8)',
            borderBottom: 'none',
        },
        style: {
            color: 'white',
            textAlign: 'center',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
            fontSize: '1rem',
            verticalAlign: 'middle',
            backgroundColor: 'rgb(147, 147, 147, 0.5)'
        }
    }, {
        dataField: 'date',
        text: 'Date',
        headerStyle: {
            color: 'white',
            textAlign: 'center',
            backgroundColor: 'rgb(147, 147, 147, 0.8)',
            borderBottom: 'none',

        },
        style: {
            color: 'white',
            textAlign: 'center',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
            fontSize: '1rem',
            verticalAlign: 'middle',
            backgroundColor: 'rgb(147, 147, 147, 0.5)'
        },
        

    }];
    return (
        <Layout>
            <div className="container row flex-grow-2 justify-content-center">
                <div className="col-md-6 p-2 add-form-container">
                    <ToastContainer autoClose={1800} />
                    <Fade clear duration={500} delay={220}>
                        <h1 className="text-center">Rapport de connexions</h1>
                    </Fade>
                    {!connectionRecordLoader && <BootstrapTable classes="connectionReportTable" keyField='id' data={records} columns={columns} />}
                </div>
            </div>
        </Layout>
    );
};

ConnectionReport.propTypes = {
    cityId: PropTypes.number,
    loadConnectionReport: PropTypes.func,
    connectionReportData: PropTypes.array,
    connectionRecordLoader: PropTypes.bool,
};

export default ConnectionReport;
