import { connect } from 'react-redux';

import { loadRestrictedAreas } from 'services/ducks/modules/dataList';

import AddArea from 'scenes/Admin/AddArea/AddArea';

const mapStateToProps = (state) => ({
  cityLat: state.map.cityLat,
  restrictedAreasLoader: state.loader.restrictedAreasLoader
});

const mapDispatchToProps = (dispatch) => ({
  loadRestrictedAreas: () => {
    dispatch(loadRestrictedAreas());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddArea);