import React, { useEffect } from 'react';
import Layout from 'components/Layout/LayoutContainer';

import TripList from 'scenes/Core/Report/TripList/TripListContainer';
import Animal from 'scenes/Core/Report/Animal/AnimalContainer';
import AddSpecieBtn from 'scenes/Core/Report/AddSpecieBtn/AddSpecieBtnContainer';
// import UploadPhoto from 'components/UploadPhoto/UploadPhotoContainer';
import SubmitBtn from 'scenes/Core/Report/SubmitBtn/SubmitBtnContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Zoom from 'react-reveal/Zoom';
import { BallBeat } from 'react-pure-loaders';
import PropTypes from 'prop-types';

const Report = ({
    cityId,
    tripId,
    animalNbr,
    tripsByIdData,
    speciesData,
    tripByIdLoader,
    speciesLoader,

    loadTripsById,
    loadSpecies,
}) => {

    // On charge les sorties si elles ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadTripsById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || tripsByIdData === []]);

    // On charge les sorties si elles ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadSpecies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || speciesData === []]);

    return (
        <Layout>
            <div className="col-md-6 offset-md-3 container-fluid flex-grow-2 ">
                <ToastContainer autoClose={1800} />
                <Zoom clear duration={500} delay={220}>
                    <h1 className="p-3 text-center">Déclarer une prise</h1>
                </Zoom>
                <div className="text-center">
                    < BallBeat margin={'auto'} size={500} loading={tripByIdLoader || speciesLoader ? true : false} />
                </div>

                {!tripByIdLoader && <TripList />}

                {tripId !== null && <div className='report-takings-container'>
                    {Array.from(Array(animalNbr), (e, i) => {
                        return <Animal key={`animalComp${i}`} specieNum={i} />
                    })}
                </div>}

                {tripId !== null && !speciesLoader && !tripByIdLoader && <AddSpecieBtn />}
                {/* {tripId !== null && !speciesLoader && !tripByIdLoader && <UploadPhoto />}
                <UploadPhoto /> */}

                {tripId !== null && !speciesLoader && !tripByIdLoader && <SubmitBtn />}
            </div>
        </Layout>
    );
};

Report.propTypes = {
    cityId: PropTypes.number,
    tripId: PropTypes.number,
    animalNbr: PropTypes.number,
    tripsByIdData: PropTypes.arrayOf(
        PropTypes.object
    ),
    speciesData: PropTypes.arrayOf(
        PropTypes.object
    ),
    tripByIdLoader: PropTypes.bool,
    speciesLoader: PropTypes.bool,

    loadTripsById: PropTypes.func,
    loadSpecies: PropTypes.func,
};

export default Report;
