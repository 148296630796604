import React from 'react';
import Home from 'scenes/Core/Home/HomeContainer';
import { isAuth } from 'services/security/helpers';
import { Redirect } from 'react-router-dom';

       
const App = () => {
    
    return (
        <>
            {isAuth() && (
                <Home />
            )}
            {!isAuth() && (
                <Redirect to="/signin" />
            )}
        </>
    );
};

export default App;
