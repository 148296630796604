import { connect } from 'react-redux';

import RestrictedAreas from 'components/Map/RestrictedAreas/RestrictedAreas';

const mapStateToProps = (state) => ({
  restrictedAreasData: state.dataList.restrictedAreasData,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestrictedAreas);