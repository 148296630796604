import { connect } from 'react-redux';

import { loadTrips, loadWeather } from 'services/ducks/modules/dataList';
import { loadSettings } from 'services/ducks/modules/settings';
import { changeValue } from 'services/ducks/modules/map';

import Carrousel from 'components/Carrousel/Carrousel';

const mapStateToProps = (state) => ({
  tripsData: state.dataList.tripsData,
  citylat: state.map.cityLat,
  delayHour: state.settings.delayHour,
  cityId: state.auth.cityId,
  weatherData: state.dataList.weatherData,
  weatherLoader: state.loader.weatherLoader,
  tripsLoader: state.loader.tripsLoader,
  settingsLoader: state.loader.settingsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  loadTrips: () => {
    dispatch(loadTrips());
  },
  loadWeather: () => {
    dispatch(loadWeather());
  },
  loadSettings: () => {
    dispatch(loadSettings());
  },
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Carrousel);