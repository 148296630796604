import { connect } from 'react-redux';

import { addSpecie, removeSpecie } from 'services/ducks/modules/taking';

import AddSpecieBtn from 'scenes/Core/Report/AddSpecieBtn/AddSpecieBtn';

const mapStateToProps = (state) => ({
  animalNbr: state.taking.animalNbr,
});

const mapDispatchToProps = (dispatch) => ({
  addSpecie: () => {
    dispatch(addSpecie());
  },
  removeSpecie: () => {
    dispatch(removeSpecie());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddSpecieBtn);