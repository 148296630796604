
// == Action types ======================

export const CHANGE_NEWS_VALUE = 'CHANGE_NEWS_VALUE';
export const SAVE_A_NEWS = 'SAVE_A_NEWS';
export const UPDATE_A_NEWS = 'UPDATE_A_NEWS';
export const DELETE_A_NEWS = 'DELETE_A_NEWS';
export const SIGNOUT = 'SIGNOUT';


// == Action creators

export const changeValue = (name, value) => ({
  type: CHANGE_NEWS_VALUE,
  name,
  value,
});

export const saveANews = () => ({
  type: SAVE_A_NEWS,
});

export const updateANews = () => ({
  type: UPDATE_A_NEWS,
});

export const deleteANews = () => ({
  type: DELETE_A_NEWS,
});

// == Reducer
const initialState = {
  newsId: null,
  date: '',
  title: '',
  content: '',

};

function debriefingReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_NEWS_VALUE:
      // console.log(action.name)
      return {
        ...state,
        [action.name]: action.value,
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default debriefingReducer;
