
// == Action types ======================

export const SAVE_MAP_PARAMS = 'SAVE_MAP_PARAMS';
export const CHANGE_MAP_VALUE = 'CHANGE_MAP_VALUE';
export const ADD_NEW_RESTRICTED_AREA = 'ADD_NEW_RESTRICTED_AREA';
export const UPDATE_RESTRICTED_AREA = 'UPDATE_RESTRICTED_AREA';
export const DELETE_RESTRICTED_AREA = 'DELETE_RESTRICTED_AREA';
export const SIGNOUT = 'SIGNOUT';


// // == Action creators

export const changeValue = (name, value) => ({
  type: CHANGE_MAP_VALUE,
  name,
  value: value,
});

export const saveMapParams = (data) => ({
  type: SAVE_MAP_PARAMS,
  data,
});

export const addNewRestrictedArea = () => ({
  type: ADD_NEW_RESTRICTED_AREA,
});

export const updateRestrictedArea = () => ({
  type: UPDATE_RESTRICTED_AREA,
});

export const deleteRestrictedArea = () => ({
  type: DELETE_RESTRICTED_AREA,
});

// == Reducer
const initialState = {
  cityId: null,
  cityLat: null,
  cityLng: null,
  meridiem: '',
  date: '',
  border: [],
  restrictedAreas: [],
  restrictedAreaId: null,
  areaName: '',
  areaActive: false,
  areaCoordinates: [],
  addAreaChoice: 'Consulter',
};

function mapReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_MAP_VALUE:
      // console.log(action.name)
      return {
        ...state,
        [action.name]: action.value,
      };

    case SAVE_MAP_PARAMS:
      console.log(action.data)
      console.log('ttttttttttttttttttttttttttttttt')
      return {
        ...state,
        cityLat: action.data.city.lat,
        cityLng: action.data.city.lng,
        border: JSON.parse(action.data.city.border),
        zoom: action.data.city.zoom,
      };

      case SIGNOUT:
        return initialState;
        

    default:
      return state;
  }
}

export default mapReducer;
