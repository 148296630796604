import React from 'react';
import { getTheDay, getTheMonth, getTheDayNum } from 'services/helpers/date';

import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';
import PropTypes from 'prop-types';

const TripList = ({
    tripId,
    tripsByIdData,
    changeValue,
    loadTakingsByTripId
}) => {

    const handleChangeSelect = event => {
        const theTripSelected = tripsByIdData.find(trip => trip.id === event.value)
        loadTakingsByTripId(theTripSelected.takings)
        return changeValue('tripId', event.value)
    };

    /**
     * Change meridiem in french
     * @param {string} meridiem 
     */
    const meridiemToFrench = (meridiem) => {
        if (meridiem === 'morning') {
            return 'Matin';
        }
        return 'Soir';
    };


    // Données des select liste des sorties
    const tripsSelectOptions = [];

    tripsByIdData.map(trip => {

        if (trip.locationId !== null) {
            return tripsSelectOptions.push({ value: trip.id, label: `${getTheDay(trip.date)} ${getTheDayNum(trip.date)} ${getTheMonth(trip.date)} à ${trip.location.name} au ${meridiemToFrench(trip.meridiem)}` })
        }
        return null
    })

    return (
        <form>
            <div className="form-container mb-4">
                <label className="form-label">Sortie</label>
                <Select
                    options={tripsSelectOptions}
                    value={tripsSelectOptions.filter(obj => obj.value === tripId)}
                    isSearchable={false}
                    theme={selectStyle}
                    onChange={handleChangeSelect}
                />
            </div>
        </form>
    );
};

TripList.propTypes = {
    tripId: PropTypes.number,
    tripsByIdData: PropTypes.arrayOf(
        PropTypes.object
    ),
    changeValue: PropTypes.func,
    loadTakingsByTripId: PropTypes.func
};
export default TripList;
