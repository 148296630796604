import React, { useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Footer from 'components/Footer/Footer';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

const Activate = ({
    match,
    department,
    city,
    firstname,
    lastname,
    licenseNumber,
    membership,
    phone,
    email,
    changeValue,
    submitActivate 
}) => {

    useEffect(() => {
        let token = match.params.token;
        const {
            department,
            departmentId,
            city,
            cityId,
            firstname,
            lastname,
            licenseNumber,
            membership,
            membershipId,
            phone,
            email
        } = jwt.decode(token);
        if (token) {
            changeValue('department', department);
            changeValue('departmentId', departmentId);
            changeValue('city', city);
            changeValue('cityId', cityId);
            changeValue('firstname', firstname);
            changeValue('lastname', lastname);
            changeValue('licenseNumber', licenseNumber);
            changeValue('membership', membership);
            changeValue('membershipId', membershipId);
            changeValue('phone', phone);
            changeValue('email', email);
            changeValue('token', token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Ecoute du bouton Activer
    const clickSubmit = event => {
        event.preventDefault();
        submitActivate();
    };

    const activationLink = () => (
        <div className="form-container text-center">
            <h2 className="pt-5">ACCA de {city} ({department})</h2>
            <h3 className="pt-3">Activation du compte de {firstname} {lastname}</h3>
            
            <div className="user-card">
            <h3 className="">Récapitulatif</h3>
                <ul className="activate-listing">
                    <li>Prénom : <span>{firstname}</span></li>
                    <li>Nom : <span>{lastname}</span></li>
                    <li>Numéro de permis : <span>{licenseNumber}</span></li>
                    <li>Type de membre : <span>{membership}</span></li>
                    <li>Téléphone : <span>{phone}</span></li>
                    <li>Email : <span>{email}</span></li>
                </ul>
            </div>
           
            <button className="btn btn-primary my-4 mx-5" onClick={clickSubmit}>
                Activer le compte
            </button>
        </div>
    );

    return (
        <>
            <div className="signin-container row justify-content-between">

                <div className="signin-title-container column col-md-4 ">
                    <Link to="/" className="forgotten-password"><h1 className="title">Chasse Connect</h1></Link>
                </div>
                <ToastContainer autoClose={1800} />
                <div className="col-md-6 offset-md-3 d-flex justify-content-center">
                    <ToastContainer autoClose={3000} />
                    {activationLink()}
                </div>
                <Footer />
            </div>


        </>
    );
};

Activate.propTypes = {
    department: PropTypes.string,
    city: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    licenseNumber: PropTypes.string,
    membership: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    changeValue: PropTypes.func,
    submitActivate: PropTypes.func
};

export default Activate;
