import React from "react";
import 'react-toastify/dist/ReactToastify.min.css';

import Select from 'react-select';
import Toggle from 'react-toggle';
import 'leaflet-draw/dist/leaflet.draw.css';

import PropTypes, { object } from 'prop-types';
import { selectStyle } from 'services/helpers/select';

const AddAreaForm = ({
	restrictedAreasData,
	changeValue,
	addNewRestrictedArea,
	restrictedAreaId,
	areaName,
	areaActive,
	updateRestrictedArea,
	deleteRestrictedArea,
	addAreaChoice
}) => {

	/*
	 * Soumission du formulaire pour l'enregistrement d'une nouvelle zone
	 * @param {} event 
	 */
	const handleSubmitNewRestrictedArea = (event) => {
		event.preventDefault();
		addNewRestrictedArea();
	}

	/**
	 * Soumission du formulaire pour la modification d'une zone
	 * @param {} event 
	 */
	const handleUpdateRestrictedArea = (event) => {
		event.preventDefault();
		updateRestrictedArea();
	}

	/**
	 * Soumission du formulaire pour la suppression d'une zone
	 * @param {} event 
	 */
	const handleDeleteRestrictedArea = (event) => {
		event.preventDefault();
		deleteRestrictedArea();
	}

	/**
	 * Champ contrôlé
	 * @param {*} name 
	 */
	const handleChange = name => event => {
		changeValue(name, event.target.value)
	};



	////////SELECT////////

	// Fonction du champ controlé select pour les actions
	const handleChangeSelectChoice = e => {
		changeValue('addAreaChoice', e.value);
	};

	// Fonction du champ controlé select
	const handleChangeSelectedArea = e => {
		changeValue('restrictedAreaId', e.value);
		const area = restrictedAreasData.find(restrictedArea => restrictedArea.id === e.value);
		changeValue('areaName', area.name);
		changeValue('areaActive', area.active);
		changeValue('areaCoordinates', area.coordinates);
	};

	const selectChoice = [
		{ value: 'Consulter', label: 'Consulter' },
		{ value: "Ajouter", label: 'Ajouter' },
		{ value: 'Modifier', label: 'Modifier' },
		{ value: 'Supprimer', label: 'Supprimer' },
	]

	const selectRestrictedAreaOptions = [];

	restrictedAreasData.map(restrictedArea => {
		return selectRestrictedAreaOptions.push({ value: restrictedArea.id, label: restrictedArea.name });
	})


	return (
		<form>
			<div className="form-container">
				<label className="form-label">Action</label>
				<Select
					options={selectChoice}
					value={selectChoice.filter(obj => obj.value === addAreaChoice)}
					isSearchable={false}
					theme={selectStyle}
					onChange={handleChangeSelectChoice}
				/>
			</div>
			<div className="linebreak"></div>

			{ addAreaChoice === "Ajouter" &&
				<>
					<div className="form-container">
						<label className="form-label">Nom de la zone</label>
						<input className="form-input" value={areaName} onChange={handleChange('areaName')} type="text" />
					</div>
					<button className="btn btn-primary" onClick={handleSubmitNewRestrictedArea}>
						Enregistrer
		 			 </button>
				</>
			}

			{ addAreaChoice === "Modifier" &&
				<>
					<div className="form-container">
						<label className="form-label">Zone de restriction</label>
						<Select
							options={selectRestrictedAreaOptions}
							value={selectRestrictedAreaOptions.filter(obj => obj.value === restrictedAreaId)}
							isSearchable={false}
							theme={selectStyle}
							onChange={handleChangeSelectedArea}
						/>
					</div>

					<div className="form-container">
						<label className="form-label">Nom de la zone</label>
						<input className="form-input" value={areaName} onChange={handleChange('areaName')} type="text" />
					</div>

					{ restrictedAreaId !== null && (
						<>
							<div className="toggle-container">
								<label className="form-label">{(areaActive === true) ? 'Zone Active' : 'Zone Inactive'}</label>
								<Toggle
									checked={areaActive}
									name='burritoIsReady'
									value={areaActive}
									onChange={() => {
										changeValue("areaActive", !areaActive)
									}}
								/>
							</div>
							<button className="btn btn-primary" onClick={handleUpdateRestrictedArea}>
								Enregistrer
		 					</button>
						</>
					)}
				</>
			}

			{ addAreaChoice === "Supprimer" &&
				<>
					<div className="form-container">
						<label className="form-label">Zone de restriction</label>
						<Select
							options={selectRestrictedAreaOptions}
							value={selectRestrictedAreaOptions.filter(obj => obj.value === restrictedAreaId)}
							isSearchable={false}
							theme={selectStyle}
							onChange={handleChangeSelectedArea}
						/>
					</div>

					{ restrictedAreaId !== null && (
						<button className="btn btn-primary" onClick={handleDeleteRestrictedArea}>
							Supprimer
						</button>
					)}
				</>
			}
		</form>
	);
};

AddAreaForm.propTypes = {

	restrictedAreasData: PropTypes.arrayOf(object),
	changeValue: PropTypes.func,
	addNewRestrictedArea: PropTypes.func,
	restrictedAreaId: PropTypes.number,
	areaName: PropTypes.string,
	areaActive: PropTypes.bool,
	updateRestrictedArea: PropTypes.func,
	deleteRestrictedArea: PropTypes.func,
	addAreaChoice: PropTypes.string

	
};

export default AddAreaForm;