// == Action types ======================

export const CHANGE_SETTINGS_VALUE = 'CHANGE_SETTINGS_VALUE';
export const SAVE_HOUR_LIMITATION = 'SAVE_HOUR_LIMITATION';
export const SAVE_TOOGLE_LIMITATION = 'SAVE_TOOGLE_LIMITATION';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const SAVE_TOOGLE_REGISTRATION_MODE = 'SAVE_TOOGLE_REGISTRATION_MODE';
export const SAVE_DELAY_HOUR = 'SAVE_DELAY_HOUR';
export const SAVE_TOGGLE_FORCE_DEGRIEFING = 'SAVE_TOGGLE_FORCE_DEGRIEFING';
export const SIGNOUT = 'SIGNOUT';

// == Action creators

export const changeValue = (name, value) => ({
  type: CHANGE_SETTINGS_VALUE,
  name,
  value: value,
});

export const saveSettings = (settings) => ({
  type: SAVE_SETTINGS,
  settings
});

export const loadSettings = () => ({
  type: LOAD_SETTINGS,
});

export const saveHourLimitation = (hourLimitationType, hourLimitationDate) => ({
  type: SAVE_HOUR_LIMITATION,
  hourLimitationType,
  hourLimitationDate
});

export const saveToogleLimitation = (limitationType, limitationBool) => ({
  type: SAVE_TOOGLE_LIMITATION,
  limitationType,
  limitationBool
});

export const saveToogleRegistrationMode = (limitRegistration) => ({
  type: SAVE_TOOGLE_REGISTRATION_MODE,
  limitRegistration,
});

export const saveDelayHour = (delayHour) => ({
  type: SAVE_DELAY_HOUR,
  delayHour,
});

export const saveToogleForceDebriefing = (forceDebriefing) => ({
  type: SAVE_TOGGLE_FORCE_DEGRIEFING,
  forceDebriefing,
});

// == Reducers
const initialState = {
  eveningLimitation: false,
  eveningLimitationHour: '',
  morningLimitation: false,
  morningLimitationHour: '',
  limitRegistration: false,
  delayHour: null,
  forceDebriefing: false
};

function settingsReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_SETTINGS_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };

    case SAVE_SETTINGS:
      let newState = { ...state }
      action.settings.map(setting => (
        newState = {
          ...newState,
          [setting[0]]: setting[1]
        }
      ))
      return newState;

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default settingsReducer;
