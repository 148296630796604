/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from 'services/security/helpers'

import {
  ADD_NEW_RESTRICTED_AREA,
  UPDATE_RESTRICTED_AREA,
  DELETE_RESTRICTED_AREA,
  changeValue
} from 'services/ducks/modules/map';

import {
  loadRestrictedAreas,
} from 'services/ducks/modules/dataList';

const mapMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case ADD_NEW_RESTRICTED_AREA:
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/addRestrictedArea`,
        data: {
          roleId: store.getState().auth.roleId,
          areaName: store.getState().map.areaName,
          cityId: store.getState().auth.cityId,
          areaCoordinates: store.getState().map.areaCoordinates,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('ADDRESTRICTED SUCCESS', response);
          store.dispatch(loadRestrictedAreas());
          store.dispatch(changeValue('restrictedAreaId', null));
          store.dispatch(changeValue('areaName', ''));
          store.dispatch(changeValue('areaDescription', ''));
          store.dispatch(changeValue('areaCoordinates', []));
          console.log(response)
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('ADDRESTRICTED ERROR', error.response.data.error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case UPDATE_RESTRICTED_AREA:
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/updateRestrictedArea`,
        data: {
          areaId: store.getState().map.restrictedAreaId,
          roleId: store.getState().auth.roleId,
          cityId: store.getState().auth.cityId,
          areaName: store.getState().map.areaName,
          active: store.getState().map.areaActive,
          areaCoordinates: store.getState().map.areaCoordinates,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('UPDATERESTRICTED SUCCESS', response);
          store.dispatch(loadRestrictedAreas());
          console.log(response)
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('UPDATERESTRICTED ERROR', error.response.data.error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case DELETE_RESTRICTED_AREA:
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/deleteRestrictedArea`,
        data: {
          areaId: store.getState().map.restrictedAreaId,
          roleId: store.getState().auth.roleId,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('DELETERESTRICTED SUCCESS', response);
          store.dispatch(loadRestrictedAreas());
          store.dispatch(changeValue('restrictedAreaId', null));
          store.dispatch(changeValue('areaName', ''));
          store.dispatch(changeValue('areaDescription', ''));
          store.dispatch(changeValue('areaCoordinates', []));
          console.log(response)
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('DELETEESTRICTED ERROR', error.response.data.error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    default:
      next(action);
  }
};

export default mapMiddleware;