/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from 'services/security/helpers'
import { loadNews } from 'services/ducks/modules/dataList';

import {
  SAVE_A_NEWS,
  UPDATE_A_NEWS,
  DELETE_A_NEWS,
  changeValue
} from 'services/ducks/modules/news';

const debriefingMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case SAVE_A_NEWS:
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/news/add/`,
        data: {
          cityId: store.getState().auth.cityId,
          roleId: store.getState().auth.roleId,
          title: store.getState().news.title,
          content: store.getState().news.content,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('SAVE_NEWS SUCCESS', response);
          // store.dispatch(saveSettings(Object.entries(response.data.setting)));
          toast.success(response.data.message);
          store.dispatch(loadNews());
          store.dispatch(changeValue('newsId', null));
          store.dispatch(changeValue('title', ''));
          store.dispatch(changeValue('content', ''));
        })
        .catch(error => {
          console.log('SAVE_NEWS ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case UPDATE_A_NEWS:
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/news/update/`,
        data: {
          cityId: store.getState().auth.cityId,
          newsId: store.getState().news.newsId,
          roleId: store.getState().auth.roleId,
          title: store.getState().news.title,
          content: store.getState().news.content,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('UPDATE_NEWS SUCCESS', response);
          // store.dispatch(saveSettings(Object.entries(response.data.setting)));
          toast.success(response.data.message);
          store.dispatch(loadNews());
          store.dispatch(changeValue('newsId', null));
          store.dispatch(changeValue('title', ''));
          store.dispatch(changeValue('content', ''));
        })
        .catch(error => {
          console.log('UPDATE_NEWS ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case DELETE_A_NEWS:
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/news/delete/`,
        data: {
          newsId: store.getState().news.newsId,
          roleId: store.getState().auth.roleId,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('DELETE_NEWS SUCCESS', response);
          // store.dispatch(saveSettings(Object.entries(response.data.setting)));
          toast.success(response.data.message);
          store.dispatch(loadNews());
          store.dispatch(changeValue('newsId', null));
          store.dispatch(changeValue('title', ''));
          store.dispatch(changeValue('content', ''));
        })
        .catch(error => {
          console.log('DELETE_NEWS ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    

    default:
      next(action);
  }
};

export default debriefingMiddleware;