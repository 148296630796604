/* eslint-disable no-case-declarations */
import axios from 'axios';
import { isAuth, getCookie } from 'services/security/helpers'
import { enableLoader, disableLoader } from 'services/ducks/modules/loader';

import {
  LOAD_MEMBERSHIPS,
  LOAD_DEPARTMENTS,
  LOAD_CITIES,
  LOAD_USERS,
  LOAD_BLOOD_HANDLERS,
  LOAD_LOCATIONS,
  LOAD_TRIPS,
  LOAD_TRIPS_BY_ID,
  LOAD_SPECIES,
  LOAD_WEATHER,
  LOAD_ROLES,
  LOAD_RESTRICTED_AREAS,
  LOAD_DEBRIEFINGS,
  LOAD_TAKINGS,
  LOAD_NEWS,
  LOAD_ADMIN_REPORTS,
  LOAD_CONNECTION_REPORT,
  saveMemberships,
  saveDepartments,
  saveCities,
  saveLocations,
  saveUsers,
  saveBloodHandlers,
  saveTrips,
  saveTripsById,
  saveSpecies,
  saveWeather,
  saveRoles,
  saveRestrictedAreas,
  saveDebriefings,
  saveTakings,
  saveNews,
  saveAdminReports,
  saveConnectionReport,
} from 'services/ducks/modules/dataList';


// import { Router } from 'express'; //genere erreur

// let socket;

const authMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case LOAD_MEMBERSHIPS:
      store.dispatch(enableLoader('membershipsLoader'));
        axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API}/memberships`,
          // headers: {
          //   Authorization: `Bearer ${getCookie('token')}`
          // }
        })
          .then(response => {
            console.log('MEMBERSHIPS LOADED', response);
            store.dispatch(saveMemberships(response.data));
            store.dispatch(disableLoader('membershipsLoader'));
          })
          .catch(error => {
            console.log('MEMBERSHIPS ERROR', error);
          })
      next(action);
      break;

    case LOAD_DEPARTMENTS:
      store.dispatch(enableLoader('departmentsLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/departments`,
      })
        .then(response => {
          console.log('DEPARTMENTS LOADED', response);
          store.dispatch(saveDepartments(response.data));
          store.dispatch(disableLoader('departmentsLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('ACCOUNT ACTIVATION ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_CITIES:
      store.dispatch(enableLoader('citiesLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/cities/${store.getState().auth.departmentId}`,
      })
        .then(response => {
          console.log('CITIES LOADED', response);
          store.dispatch(saveCities(response.data));
          store.dispatch(disableLoader('citiesLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('ACCOUNT ACTIVATION ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_USERS:
      console.log('les utilisateurs essaient de se cgharger')
      store.dispatch(enableLoader('usersLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/users/${store.getState().auth.cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('USERS LOADED', response);
          store.dispatch(saveUsers(response.data));
          store.dispatch(disableLoader('usersLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('USERS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_BLOOD_HANDLERS:
      console.log('les conducteurs essaient de se cgharger')
      store.dispatch(enableLoader('bloodHandlersLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/bloodHandlers/${store.getState().auth.departmentId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('BLOOD_HANDLERS LOADED', response);
          store.dispatch(saveBloodHandlers(response.data));
          store.dispatch(disableLoader('bloodHandlersLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('BLOOD_HANDLERS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_LOCATIONS:
      console.log('les locations essaient de se cgharger')
      store.dispatch(enableLoader('locationsLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/locations/${store.getState().auth.cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOCATIONS LOADED', response);
          store.dispatch(saveLocations(response.data));
          store.dispatch(disableLoader('locationsLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('LOCATIONS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_TRIPS:
      console.log('les trips essaient de se charger')
      store.dispatch(enableLoader('tripsLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/trips/${store.getState().auth.cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('TRIPS LOADED', response);
          store.dispatch(saveTrips(response.data));
          store.dispatch(disableLoader('tripsLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('TRIPS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        })

      next(action);
      break;


    case LOAD_TRIPS_BY_ID:
      console.log('le total des trips par id essaient de se charger')
      store.dispatch(enableLoader('tripByIdLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/trips/id/${isAuth().id}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('TRIPS_BY_ID LOADED', response);
          store.dispatch(saveTripsById(response.data));
          store.dispatch(disableLoader('tripByIdLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('TRIPS_BY_ID LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_SPECIES:
      console.log('les especes essaient de se charger')
      store.dispatch(enableLoader('speciesLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/species`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOAD_SPECIES LOADED', response);
          store.dispatch(saveSpecies(response.data));
          store.dispatch(disableLoader('speciesLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('LOAD_SPECIES LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_RESTRICTED_AREAS:
      console.log('les zones de restriction essaient de se charger')
      store.dispatch(enableLoader('restrictedAreasLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/restrictedAreas/${isAuth().cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOAD_RESTRICTED_AREA LOADED', response);
          store.dispatch(saveRestrictedAreas(response.data));
          store.dispatch(disableLoader('restrictedAreasLoader'));
          console.log(response.data);
          // // setValues({ ...values, show: false });
          // toast.success(response.data.message);
        })
        .catch(error => {
          console.log('LOAD_RESTRICTED_AREA LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_WEATHER:
      console.log('les especes essaient de se charger')
      store.dispatch(enableLoader('weatherLoader'));
      axios({
        method: 'GET',
        // url: `https://www.prevision-meteo.ch/services/json/lat=${store.getState().map.cityLat}lng=${store.getState().map.cityLng}`,
        // url: `https://www.prevision-meteo.ch/services/json/meilhan-40`,
        url: `${process.env.REACT_APP_API}/weather/${isAuth().cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOAD_WEATHER LOADED', response);

          let weather = {
            sunrise: response.data[0].sunrise,
            sunset: response.data[0].sunset,
          };

          response.data.map(currentWeather => (
            weather = {
              ...weather,
              [currentWeather.meridiem]: {
                temp: currentWeather.temp,
                rain: currentWeather.rain,
                windSpeed: currentWeather.windSpeed,
                dirWind: currentWeather.dirWind,
                conditionKey: currentWeather.conditionKey,
                hour: currentWeather.hour,
              }
            }
          ))

          store.dispatch(saveWeather(weather));
          store.dispatch(disableLoader('weatherLoader'));
        })
        .catch(error => {
          console.log('LOAD_WEATHER LOADED ERROR', error);
        })

      next(action);
      break;

    case LOAD_ROLES:
      console.log('les roles essaient de se charger')
      store.dispatch(enableLoader('rolesLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/roles`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOAD_ROLES LOADED', response);
          store.dispatch(saveRoles(response.data));
          store.dispatch(disableLoader('rolesLoader'));

          console.log(response.data);
        })
        .catch(error => {
          console.log('LOAD_ROLES LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_DEBRIEFINGS:
      console.log('les debriefings essaient de se charger')
      store.dispatch(enableLoader('debriefingsLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/debriefings/${isAuth().cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOAD_DEBRIEFINGS LOADED', response);
          store.dispatch(saveDebriefings(response.data));
          store.dispatch(disableLoader('debriefingsLoader'));

          console.log(response.data);
        })
        .catch(error => {
          console.log('LOAD_DEBRIEFINGS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_TAKINGS:
      console.log('les takings essaient de se charger')
      store.dispatch(enableLoader('takingsLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/takings/${isAuth().cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOAD_DEBRIEFINGS LOADED', response);
          store.dispatch(saveTakings(response.data));
          store.dispatch(disableLoader('takingsLoader'));

          console.log(response.data);
        })
        .catch(error => {
          console.log('LOAD_DEBRIEFINGS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_NEWS:
      console.log('les news essaient de se charger')
      store.dispatch(enableLoader('newsLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/news/${isAuth().cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOAD_NEWS LOADED', response);
          store.dispatch(saveNews(response.data));
          store.dispatch(disableLoader('newsLoader'));

          console.log(response.data);
        })
        .catch(error => {
          console.log('LOAD_NEWS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_ADMIN_REPORTS:
      // console.log('les reportsadmin essaient de se charger')
      store.dispatch(enableLoader('pdfLoader'));
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/admin/reports/`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        },
        data: {
          cityId: store.getState().auth.cityId,
          roleId: store.getState().auth.roleId,
          reportUserId: store.getState().adminReport.userId,
          startingDate: store.getState().adminReport.startingDate,
          endingDate: store.getState().adminReport.endingDate,
        },
      })
        .then(response => {
          // console.log('LOAD_ADMIN_REPORTS LOADED', response);
          store.dispatch(saveAdminReports(response.data));
          store.dispatch(disableLoader('pdfLoader'));

          // console.log(response.data);
        })
        .catch(error => {
          console.log('LOAD_ADMIN_REPORTS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;
      
    case LOAD_CONNECTION_REPORT:
      // console.log('les reportsadmin essaient de se charger')
      store.dispatch(enableLoader('connectionRecordLoader'));
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/admin/connection-report`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        },
        data: {
          cityId: store.getState().auth.cityId,
          roleId: store.getState().auth.roleId,
        },
      })
        .then(response => {
          // console.log('LOAD_ADMIN_REPORTS LOADED', response);
          store.dispatch(saveConnectionReport(response.data));
          store.dispatch(disableLoader('connectionRecordLoader'));

          // console.log(response.data);
        })
        .catch(error => {
          console.log('LOAD_ADMIN_REPORTS LOADED ERROR', error);
          // toast.error(error.response.data.error);
        });

      next(action);
      break;


    default:
      next(action);
  }
};

export default authMiddleware;