import React, { useEffect } from 'react';
import Layout from 'components/Layout/LayoutContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { ChromePicker } from 'react-color';
import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';
import { BallBeat } from 'react-pure-loaders';
import PropTypes from 'prop-types';

const Private = ({
    role,
    firstname,
    lastname,
    cityId,
    licenseNumber,
    membershipId,
    membershipsData,
    color,
    colorPicker,
    phone,
    email,
    password,
    changeValue,
    submitPrivateProfile,
    profileLoader,
    membershipsLoader,
    loadMemberships
}) => {


    // Chargement des types de membre une fois le profil dans le store
    useEffect(() => {
        loadMemberships();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    const handleChange = name => event => {
        changeValue(name, event.target.value)
    };

    // Fonction du champ controlé select
    const handleChangeSelect = name => event => {
        if (name === 'membershipId' || name === 'locationId') {
            changeValue(name, parseInt(event.value))
            changeValue('membership', event.label)
        }
        return changeValue(name, event.value)
    };

    // Fonction pour soumettre le formulaire
    const clickSubmit = event => {
        event.preventDefault();
        submitPrivateProfile()
    };

    // Données des select memberships
    const membershipsSelect = [];

    membershipsData.map(membership =>
        membershipsSelect.push({ value: membership.id, label: membership.name })
    )

    const handleOpenColorPicker = (event) => {
        event.preventDefault();
        changeValue('colorPicker', !colorPicker);
        if (colorPicker) {
            changeValue('colorBtnText', 'Choisir');
        } else {
            changeValue('colorBtnText', 'Fermer');
        }
    }

    const handleChangeColor = (color) => {
        changeValue('color', color.hex);
    }

    const updateForm = () => (
        <form>
            <div className="form-container">
                <label className="form-label">Role</label>
                <input defaultValue={role} type="text" className="form-input" disabled />
            </div>
            <div className="form-container">
                <label className="form-label">Prénom</label>
                <input onChange={handleChange('firstname')} value={firstname} type="text" className="form-input" />
            </div>

            <div className="form-container">
                <label className="form-label">Nom</label>
                <input onChange={handleChange('lastname')} value={lastname} type="text" className="form-input" />
            </div>

            <div className="form-container">
                <label className="form-label">Numéro de permis</label>
                <input onChange={handleChange('licenseNumber')} value={licenseNumber} type="text" className="form-input" />
            </div>

            <div className="form-container">
                <label className="form-label">Email</label>
                <input defaultValue={email} type="email" className="form-input" disabled />
            </div>

            <div className="container-split">
                <div className="form-container-split">
                    <label className="form-label">Type de membre</label>
                    <Select
                        options={membershipsSelect}
                        value={membershipsSelect.filter(obj => obj.value === membershipId)}
                        isSearchable={false}
                        theme={selectStyle}
                        onChange={handleChangeSelect('membershipId',)}
                    />
                </div>
                <div className="form-container-split">

                    <label className="form-label">Couleur</label>
                    <div className='color-container'>
                        <div className='color-square' onClick={handleOpenColorPicker} style={{ cursor: 'pointer', backgroundColor: color, }}></div>
                        {colorPicker ? <div className='popover'>
                            <div className='cover' onClick={handleOpenColorPicker} />
                            <ChromePicker color={color} onChange={handleChangeColor} />
                        </div> : null}
                    </div>

                </div>

            </div>

            <div className="form-container">
                <label className="form-label">Téléphone</label>
                <input onChange={handleChange('phone')} value={phone} type="text" className="form-input" />
            </div>

            <div className="form-container">
                <label className="form-label">Mot de passe (modifier le mot de passe)</label>
                <input onChange={handleChange('password')} value={password} type="password" className="form-input" />
            </div>

            <div>
                <button className="btn btn-primary" onClick={clickSubmit}>
                    Modifier
                </button>
            </div>
        </form>
    );

    return (
        <Layout>
            <div className="container-fluid row flex-grow-2">
                <div className="col-md-6 offset-md-3">
                    <ToastContainer autoClose={1800} />
                    <h1 className="pt-5 text-center">Mon profil</h1>
                    <div className="loader">
                        < BallBeat margin={'auto'} loading={profileLoader || membershipsLoader ? true : false} />
                    </div>
                    {!profileLoader && !membershipsLoader && updateForm()}
                </div>
            </div>
        </Layout >
    );
};

Private.propTypes = {
    role: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    cityId: PropTypes.number,
    licenseNumber: PropTypes.string,
    membershipId: PropTypes.number,
    membershipsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    color: PropTypes.string,
    colorPicker: PropTypes.bool,
    phone: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    changeValue: PropTypes.func,
    submitPrivateProfile: PropTypes.func,
    profileLoader: PropTypes.bool,
    membershipsLoader: PropTypes.bool,
    loadMemberships: PropTypes.func,
};

export default Private;
