import { connect } from 'react-redux';

// import { loadProfilId } from 'src/actions/profil';
import { changeValue, submitSignup, resetState } from 'services/ducks/modules/auth';
import { loadMemberships, loadDepartments, loadCities } from 'services/ducks/modules/dataList';

import Signup from 'scenes/Auth/Signup/Signup';

const mapStateToProps = (state) => ({
  departmentsData: state.dataList.departmentsData,
  departmentId: state.auth.departmentId,
  citiesData: state.dataList.citiesData,
  cityId: state.auth.cityId,
  firstname: state.auth.firstname,
  lastname: state.auth.lastname,
  licenseNumber: state.auth.licenseNumber,
  email: state.auth.email,
  membershipsData: state.dataList.membershipsData,
  membershipId: state.auth.membershipId,
  phone: state.auth.phone,
  password: state.auth.password,
  passwordConfirmation: state.auth.passwordConfirmation,
  departmentsLoader: state.loader.departmentsLoader,
  citiesLoader: state.loader.citiesLoader,
  membershipsLoader: state.loader.membershipsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  submitSignup: () => {
    dispatch(submitSignup());
  },
  loadMemberships: () => {
    dispatch(loadMemberships());
  },
  loadDepartments: () => {
    dispatch(loadDepartments());
  },
  loadCities: () => {
    dispatch(loadCities());
  },
  resetState: () => {
    dispatch(resetState());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Signup);