// == Action types ======================

export const CHANGE_ADMIN_REPORT_VALUE = 'CHANGE_ADMIN_REPORT_VALUE';
export const SIGNOUT = 'SIGNOUT';

// == Action creators ===================

export const changeValue = (name, value) => (
  {
  type: CHANGE_ADMIN_REPORT_VALUE,
  name,
  value: value,
});

// == Reducer ===========================

const initialState = {
  userId: 0,
  startingDate: new Date(),
  endingDate: new Date(),
};

function adminReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_ADMIN_REPORT_VALUE:
      console.log(action.name)
      return {
        ...state,
        [action.name]: action.value,
      };

    case SIGNOUT:
      return initialState;
      
    default:
      return state;
  }
}

export default adminReducer;
