
// == Action types ======================

export const CHANGE_VALUE = 'CHANGE_VALUE';
export const SUBMIT_SIGNUP = 'SUBMIT_SIGNUP';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SUBMIT_ACTIVATE = 'SUBMIT_ACTIVATE';
export const SUBMIT_SIGNIN = 'SUBMIT_SIGNIN';
export const SUBMIT_FORGOT = 'SUBMIT_FORGOT';
export const SUBMIT_RESET = 'SUBMIT_RESET';
export const LOAD_PROFILE = 'LOAD_PROFILE';
export const SUBMIT_PRIVATE_PROFILE = 'SUBMIT_PRIVATE_PROFILE';
export const SIGNOUT = 'SIGNOUT';
export const RESET_STATE = 'RESET_STATE';
export const GET_CLIENT_VERSION = 'GET_CLIENT_VERSION';
export const REGISTER_ID_CONNECTION = 'REGISTER_ID_CONNECTION';
export const IS_SOCKET_IO_CONNECTED = 'IS_SOCKET_IO_CONNECTED';

// == Action creators

export const changeValue = (name, value) => ({
  type: CHANGE_VALUE,
  name,
  value: value,
});

export const submitSignup = () => ({
  type: SUBMIT_SIGNUP,
});

export const saveProfile = (data) => ({
  type: SAVE_PROFILE,
  data,
});

export const submitActivate = () => ({
  type: SUBMIT_ACTIVATE,
});

export const submitSignin = (history) => ({
  type: SUBMIT_SIGNIN,
  history,
});

export const submitForgot = (history) => ({
  type: SUBMIT_FORGOT,
  history,
});

export const submitReset = (history) => ({
  type: SUBMIT_RESET,
  history,
});

export const loadProfile = () => ({
  type: LOAD_PROFILE,
});

export const submitPrivateProfile = () => ({
  type: SUBMIT_PRIVATE_PROFILE,
});

export const signout = (history) => ({
  type: SIGNOUT,
  history,
});

export const resetState = (history) => ({
  type: RESET_STATE,
  history,
});

export const getClientVersion = () => ({
  type: GET_CLIENT_VERSION,
});

export const registerIdConnection = () => ({
  type: REGISTER_ID_CONNECTION,
});

export const isSocketIoConnected = (bool) => ({
  type: IS_SOCKET_IO_CONNECTED,
  bool
});

// == Reducer
const initialState = {
  profileLoaded: false,
  id: null,
  role: '',
  departmentId: null,
  cityId: null,
  cityLat: null,
  cityLng: null,
  firstname: '',
  lastname: '',
  licenseNumber: '',
  membershipId: null,
  phone: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  buttonText: 'Connexion',
  token: '',
  show: true,
  path: '',
  color: '',
  colorPicker: false,
  colorBtnText: 'Choisir',
  socketIoConnectedState: true
};

function authReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_VALUE:
      // console.log(action.name)
      return {
        ...state,
        [action.name]: action.value,
      };

    case RESET_STATE:
      return initialState;

    case SAVE_PROFILE:
      console.log(action.data)
      return {
        ...state,
        profileLoaded: true,
        id: action.data.id,
        departmentId: action.data.city.department.id,
        department: action.data.city.department.name,
        cityId: action.data.city.id,
        city: action.data.city.name,
        roleId: action.data.role.id,
        role: action.data.role.name,
        firstname: action.data.firstname,
        lastname: action.data.lastname,
        licenseNumber: action.data.licenseNumber,
        membershipId: action.data.membership.id,
        membership: action.data.membership.name,
        phone: action.data.phone,
        email: action.data.email,
        token: action.data.token,
        color: action.data.color,
      };

    case IS_SOCKET_IO_CONNECTED:
      return {
        ...state,
        socketIoConnectedState: action.bool
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default authReducer;
