/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie,isAuth } from 'services/security/helpers'
import { enableLoader, disableLoader } from 'services/ducks/modules/loader';

import {
  SAVE_TOOGLE_LIMITATION,
  SAVE_HOUR_LIMITATION,
  LOAD_SETTINGS,
  SAVE_TOOGLE_REGISTRATION_MODE,
  SAVE_DELAY_HOUR,
  SAVE_TOGGLE_FORCE_DEGRIEFING,
  saveSettings
} from 'services/ducks/modules/settings';

const settingsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case SAVE_TOOGLE_LIMITATION:
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/settings/toogleHourLimitation/`,
        data: {
          cityId: store.getState().auth.cityId,
          roleId: store.getState().auth.roleId,
          limitationType: action.limitationType,
          limitationBool: action.limitationBool,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('REPORT SAVED SUCCESS', response);
          store.dispatch(saveSettings(Object.entries(response.data.setting)));
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('REPORT SAVED ERROR', error);
          toast.error(error.response.error);
        });

      next(action);
      break;

    case SAVE_HOUR_LIMITATION:
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/settings/hourLimitation/`,
        data: {
          cityId: store.getState().auth.cityId,
          roleId: store.getState().auth.roleId,
          hourLimitationType: action.hourLimitationType,
          hourLimitationDate: action.hourLimitationDate,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('REPORT SAVED SUCCESS', response);

          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('REPORT SAVED ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case SAVE_TOOGLE_REGISTRATION_MODE:
      console.log('passe')
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/settings/toggleLimitRegistration/`,
        data: {
          cityId: store.getState().auth.cityId,
          roleId: store.getState().auth.roleId,
          limitRegistration: action.limitRegistration,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('SAVE_TOOGLE_REGISTRATION_MODE SAVED SUCCESS', response);

          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('SAVE_TOOGLE_REGISTRATION_MODE SAVED ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_SETTINGS:
      store.dispatch(enableLoader('settingsLoader'));
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/settings/getSettings/${isAuth().cityId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('GET_SETTINGS SUCCESS', response);
          store.dispatch(saveSettings(Object.entries(response.data.settings)));
          store.dispatch(disableLoader('settingsLoader'));
        })
        .catch(error => {
          store.dispatch(disableLoader('settingsLoader'));
          console.log('GET_SETTINGS ERROR', error);
        });

      next(action);
      break;

      case SAVE_DELAY_HOUR:
        console.log('passe')
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_API}/settings/delayHour/`,
          data: {
            cityId: store.getState().auth.cityId,
            roleId: store.getState().auth.roleId,
            delayHour: action.delayHour,
          },
          headers: {
            Authorization: `Bearer ${getCookie('token')}`
          }
        })
          .then(response => {
            console.log('SAVE_DELAY_HOUR SAVED SUCCESS', response);
  
            toast.success(response.data.message);
          })
          .catch(error => {
            console.log('SAVE_DELAY_HOUR SAVED ERROR', error);
            toast.error(error.response.data.error);
          });
  
        next(action);
        break;

      case SAVE_TOGGLE_FORCE_DEGRIEFING:
        console.log('passe')
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_API}/settings/forceDebriefing/`,
          data: {
            cityId: store.getState().auth.cityId,
            roleId: store.getState().auth.roleId,
            forceDebriefing: action.forceDebriefing,
          },
          headers: {
            Authorization: `Bearer ${getCookie('token')}`
          }
        })
          .then(response => {
            console.log('SAVE_DELAY_HOUR SAVED SUCCESS', response);
  
            toast.success(response.data.message);
          })
          .catch(error => {
            console.log('SAVE_DELAY_HOUR SAVED ERROR', error);
            toast.error(error.response.data.error);
          });
  
        next(action);
        break;

    default:
      next(action);
  }
};

export default settingsMiddleware;