import React, { useEffect } from 'react';
import Layout from 'components/Layout/LayoutContainer';
// import MapLocation from '../../containers/mapping/MapLocation';
import Map from 'components/Map/MapContainer';
import AddForm from 'scenes/Core/Add/AddForm/AddFormContainer';

import { isAuth } from 'services/security/helpers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Fade from 'react-reveal/Zoom';
import { BallBeat } from 'react-pure-loaders';

import PropTypes from 'prop-types';

const Add = ({
    ownUserId,
    cityId,
    roleId,
    loadUsers,
    usersData,
    locationsData,
    settingsData,
    restrictedAreasData,

    loadLocations,
    loadRestrictedAreas,
    changeValue,
    loadSettings,
    usersLoader,
    settingsLoader,
    locationsLoader,
    limitRegistration,
}) => {

    // Chargement en début de composant
    useEffect(() => {
        if (cityId != null) {
            // loadRestrictedAreas();
            // Mettre l'utilisateur par défaut en cas d'admin
            changeValue('userId', ownUserId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    // On charge les sorties si elles ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || usersData === []]);

    // On charge les aires de restriction si elles ne sont pas dans le store
	useEffect(() => {
		cityId !== null && loadLocations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cityId !== null, locationsData === []]);

    // On charge les aires de restriction si elles ne sont pas dans le store
	useEffect(() => {
		cityId !== null && loadRestrictedAreas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cityId !== null || restrictedAreasData === []]);

    // On charge les settings si elles ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || settingsData === []]);


    return (
        <Layout>
            <div className="container-fluid row flex-grow-2">

                <div className="col-md-6 add-form-container">
                    <ToastContainer autoClose={1800} />
                    {limitRegistration && roleId === 3 &&
                        <Fade clear duration={500} delay={220}>
                            <h1 className="text-center">Ajouter une sortie</h1>
                            <h5 className="text-center">Mode inscription</h5>
                            <h5 className="text-center">Un administrateur validera le lieu de chasse</h5>
                        </Fade>
                    }
                    {limitRegistration && (roleId === 1 || roleId === 2 || roleId === 4) &&
                        <Fade clear duration={500} delay={220}>
                            <h1 className="text-center">Ajouter une sortie</h1>
                            <h5 className="text-center">Mode inscription</h5>
                            <h5 className="text-center">Vous êtes administrateur</h5>
                        </Fade>
                    }
                    {!limitRegistration &&
                        <Fade clear duration={500} delay={220}>
                            <h1 className="text-center">Ajouter une sortie</h1>
                        </Fade>
                    }
                    <div className="loader">
                        < BallBeat margin={'auto'} loading={usersLoader || locationsLoader ? true : false} />
                    </div>
                    {!usersLoader && !settingsLoader && !locationsLoader && isAuth().role.name === 'Admin' && <h3 className="lead text-center">Administrateur</h3>}
                    {!usersLoader && !settingsLoader && !locationsLoader && <AddForm />}
                    {/* {!usersLoader && !settingsLoader && !locationsLoader && <AddForm />} */}
                </div>
                <div className="map-location-container col-md-6">
                    {!usersLoader && !locationsLoader &&
                        <Map
                            mapClass={'map-location'}
                            markers={'locationsMarkers'}
                            restrictedAreas={true}
                            wind={false}
                        />}
                </div>

            </div>
        </Layout>
    );
};

Add.propTypes = {
    ownUserId: PropTypes.number,
    cityId: PropTypes.number,
    roleId: PropTypes.number,
    loadUsers: PropTypes.func,
    usersData: PropTypes.arrayOf(
        PropTypes.object
    ),
    locationsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    settingsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    restrictedAreasData: PropTypes.arrayOf(
        PropTypes.object
    ),
    loadLocations: PropTypes.func,
    loadRestrictedAreas: PropTypes.func,
    changeValue: PropTypes.func,
    loadSettings: PropTypes.func,
    usersLoader: PropTypes.bool,
    settingsLoader: PropTypes.bool,
    locationsLoader: PropTypes.bool,
    limitRegistration: PropTypes.bool,
};

export default Add;
