import React, { useEffect } from "react";
import { MapContainer, Polygon } from 'react-leaflet'
import { isMobile } from "react-device-detect";
import Layers from 'components/Map/Layers/Layers'
import LocationsMarkers from 'components/Map/LocationsMarkers/LocationsMarkersContainer';
import TripsMarkers from 'components/Map/TripsMarkers/TripsMarkersContainer';
import RestrictedAreas from 'components/Map/RestrictedAreas/RestrictedAreasContainer';
import AddLocationMarkers from 'components/Map/AddLocationMarkers/AddLocationMarkersContainer';
import AddAreaPolygons from 'components/Map/AddAreaPolygons/AddAreaPolygonsContainer';
import { today } from 'services/helpers/date';
import WindDegreesLayer from "components/Map/WindDegreesLayer/WindDegreesLayerContainer";

import PropTypes from 'prop-types';

const MainMap = ({
    cityLat,
    cityLng,
    border,
    zoom,
    changeValue,

    mapClass,
    markers,
    addPolygons,
    restrictedAreas,
    wind,
}) => {

    const position = [cityLat, cityLng]

    const windyUrl = `https://www.windy.com/?${cityLat},${cityLng},${zoom}`;

    useEffect(() => {
        changeValue('meridiem', 'morning');
        changeValue('date', today());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Inverser lat et lng du contour
    const borderReverseLatLng = border.map((coord) => [coord[1], coord[0]])

    console.log(markers)
    return (
        <>
            {cityLat !== null &&
                <>
                    <MapContainer className={mapClass} dragging={!isMobile} tap={!isMobile} center={position} zoom={zoom} zoomControl={false}>
                        <Layers />
                        <Polygon color="purple" positions={borderReverseLatLng} />

                        {markers === 'locationsMarkers' && <LocationsMarkers />}
                        {markers === 'Consulter' && <LocationsMarkers />}
                        {markers === 'Ajouter' && <AddLocationMarkers />}
                        {markers === 'Modifier' && <AddLocationMarkers />}
                        {addPolygons === true && <AddAreaPolygons />}
                        {markers === 'tripsMarkers' && <TripsMarkers />}
                        {restrictedAreas === true && <RestrictedAreas />}
                        {markers === 'tripsMarkers' && <WindDegreesLayer />}
                    </MapContainer>
                    {wind === true && <a href={windyUrl}><button className="btn btn-live-weather" >Live Wind</button></a>}
                </>
            }
        </>
    );
};

MainMap.propTypes = {
    cityLat: PropTypes.number,
    cityLng: PropTypes.number,
    zoom: PropTypes.number,
    border: PropTypes.arrayOf(
        PropTypes.array
    ),
    changeValue: PropTypes.func,
}

export default MainMap;