import { connect } from 'react-redux';

import {loadLocations, loadTrips } from 'services/ducks/modules/dataList';
import {loadSettings} from 'services/ducks/modules/settings';

import Modify from 'scenes/Core/Modify/Modify';

const mapStateToProps = (state) => ({
  ownUserId: state.auth.id,
  roleId: state.auth.roleId,
  cityId: state.auth.cityId,
  limitRegistration: state.settings.limitRegistration,
  tripsData: state.dataList.tripsData,
  locationsData: state.dataList.locationsData,
  tripsLoader: state.loader.tripsLoader,
  locationsLoader: state.loader.locationsLoader,
  settingsLoader: state.loader.settingsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  loadTrips: () => {
    dispatch(loadTrips());
  },
  loadLocations: () => {
    dispatch(loadLocations());
  },
  loadSettings: () => {
    dispatch(loadSettings());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modify);