import React, { useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import { isAuth, isSweatLogin } from 'services/security/helpers';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select'
import { selectStyle } from 'services/helpers/select';

import Zoom from 'react-reveal/Zoom';
import Jump from 'react-reveal/Jump';
import Fade from 'react-reveal/Fade';
import RubberBand from 'react-reveal/RubberBand';

import { BallBeat } from 'react-pure-loaders';

import 'react-toastify/dist/ReactToastify.min.css';

const Signin = ({
    history,
    departmentsData,
    departmentId,
    citiesData,
    cityId,
    email,
    password,
    buttonText,
    changeValue,
    submitSignin,
    loadDepartments,
    loadCities,
    resetState,
    departmentsLoader,
    citiesLoader
}) => {

    const passwordInputRef = useRef(null);

    // Fait l'autofocus sur le mot de passe si sweatLogin
    useEffect(() => {
        console.log('RRRRRR')
        isSweatLogin() && cityId != null && passwordInputRef.current.focus();
    }, [cityId]);

    useEffect(() => {
        loadDepartments();
        resetState();
        changeValue('buttonText', 'Connexion');
        // rempli les champs si sweatLogin
        isSweatLogin()
            && changeValue('cityId', isSweatLogin().cityId)
        changeValue('departmentId', isSweatLogin().departmentId)
        changeValue('email', isSweatLogin().email)
        // passwordInputRef.current.focus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (departmentId != null) {
            loadCities();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentId]);

    // Fonction du champ controlé select
    const handleChangeSelect = name => event => {

        // Si on modifie le departement on supprime le cityId
        if (name === 'departmentId') {
            changeValue('cityId', null);
        }
        changeValue(name, parseInt(event.value))
    };

    // Fonction du champ controlé input
    const handleChangeInput = name => event => {
        console.log(event);
        changeValue(name, event.target.value)
    };

    // Soumettre le formulaire
    const clickSubmit = event => {
        event.preventDefault();
        // changeValue('buttonText', 'Connexion ...')
        submitSignin(history)
    };

    // Liste des departements
    const departementSelectOptions = [];

    departmentsData.map(department =>
        departementSelectOptions.push({ value: department.id, label: department.name })
    )

    // Liste des villes par departement
    const citySelectOptions = [];

    citiesData.map(city =>
        citySelectOptions.push({ value: city.id, label: city.name })
    )

    const signinForm = () => (
        <form>
            <Zoom clear duration={250} delay={0}>
                <div className="form-container">
                    <label className="form-label">Département</label>
                    <div className="text-center">
                        < BallBeat loading={departmentsLoader} />
                    </div>
                    {!departmentsLoader &&
                    <Select
                        menuPortalTarget={document.querySelector('body')}
                        options={departementSelectOptions}
                        value={departementSelectOptions.filter(obj => obj.value === departmentId)}
                        isSearchable={false}
                        placeholder='Votre département'
                        theme={selectStyle}
                        onChange={handleChangeSelect('departmentId')}
                    />
}
                </div>
            </Zoom>

            {departmentId != null && (
                <Zoom clear duration={250} delay={80}>
                    <div className="form-container">
                        <label className="form-label">Commune</label>
                        <div className="text-center">
                        < BallBeat loading={citiesLoader} />
                    </div>
                    {!citiesLoader &&
                        <Select
                            menuPortalTarget={document.querySelector('body')}
                            options={citySelectOptions}
                            setValue={cityId}
                            value={citySelectOptions.filter(obj => obj.value === cityId)}
                            isSearchable={false}
                            placeholder='Votre commune'
                            theme={selectStyle}
                            onChange={handleChangeSelect('cityId')}
                        />
                    }
                    </div>
                </Zoom>
            )}
            {cityId != null && (<>
                <Zoom clear duration={250} delay={160}>
                    <div className="form-container">
                        <label className="form-label">Email</label>
                        <input className="form-input" onChange={handleChangeInput('email')} value={email} type="email" />
                    </div>
                </Zoom>
                <Zoom clear duration={250} delay={240}>
                    <div className="form-container">
                        <label className="form-label">Mot de passe</label>
                        <input className="form-input" onChange={handleChangeInput('password')} value={password} type="password" ref={passwordInputRef} />
                    </div>
                </Zoom>
                <Jump clear duration={800} delay={160}>
                    <div className="form-container">
                        <button className="btn-primary" onClick={clickSubmit}>
                            {buttonText}
                        </button>
                    </div>
                </Jump>

            </>
            )}
        </form>
    );

    return (
        <>
            <div className="signin-container row justify-content-between">

                <div className="signin-title-container column col-md-4 ">
                    <h1 className="title">Chasse Connect</h1>
                </div>
                <div className="signin-form-container col-md-3 offset-md-8">



                    <ToastContainer autoClose={1800} />
                    {isAuth() ? <Redirect to="/" /> : null}

                    <RubberBand clear duration={800} delay={0}>
                        <h3 className="signin-title">Connexion</h3>
                    </RubberBand>

                    {signinForm()}


                    {cityId != null && (

                        <Link to="/auth/password/forgot" className="forgotten-password">
                            <Fade clear duration={1000} delay={800}>
                                Mot de passe oublié
                            </Fade>
                        </Link>

                    )}
                    <Fade clear duration={800} delay={150}>
                        <div className="linebreak"> </div>
                    </Fade>

                    <Link to="/signup" className="forgotten-password">
                    <Fade clear duration={1500} delay={250}>
                        <button className="btn-secondary">
                            
                                Créer un compte
                            
                        </button>
                        </Fade>
                    </Link>
                </div>
                <Footer />
            </div>

        </>
    );
};

export default Signin;
