import React from 'react';
import { withRouter } from 'react-router-dom';

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from 'prop-types';
const SubmitBtn = ({
    history,
    disableNotification,
    notification
}) => {

    const handleClickBtn = event => {
        disableNotification();
        history.push("/news")
    };

    return (
        <>
            <div className="notification-circle" onClick={handleClickBtn}>
                <FontAwesomeIcon className="notification-icon" color='#FCEEDB' icon={faBell} />
            </div>
            <div className="notification-number" onClick={handleClickBtn}>
                {notification}
            </div>
        </>
    );
};

SubmitBtn.propTypes = {
    notification: PropTypes.number,
    changesaveTakingsValue: PropTypes.func,
};
export default withRouter(SubmitBtn);
