import React, {useEffect} from "react";
import { Marker, Popup, Tooltip } from 'react-leaflet'
import { icon } from 'services/helpers/icons';
import Zoom from 'react-reveal/Zoom';
import moment from 'moment';

import PropTypes from 'prop-types';

const TripsMarkers = ({
    meridiem,
    date,
    tripsData,
    changeValue,
}) => {

    // Set the default meridiem map
    useEffect(() => {
        changeValue('meridiem', moment().hour() <= 12 ? 'morning' : 'evening');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        tripsData.map((trip) => {
            console.log(trip)
            if (trip.meridiem === meridiem && trip.date === date && trip.state === 1) {
                return <Zoom left>
                    <Marker key={`${trip.user.firstname} ${trip.user.lastname}ko`} position={[trip.location.lat, trip.location.lng]} icon={icon(trip.user.color, trip.ammunition)} >
                        <Popup>
                            <div style={{
                                fontWeight: "bold",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            >{`${trip.user.firstname} ${trip.user.lastname}`}
                            </div>
                            <br />
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            >{trip.location.name}
                            </div>
                        </Popup>
                        <Tooltip className="tooltip-marker" direction='right' offset={[-20, -10]} opacity={0.81} permanent background-color="none">
                            <span>{`${trip.user.firstname[0]}${trip.user.lastname[0]}`}</span>
                        </Tooltip>
                    </Marker>
                </Zoom>
            }
            return false;
        })
    );
};

TripsMarkers.propTypes = {
    meridiem: PropTypes.string,
    date: PropTypes.string,
    tripsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    changeValue: PropTypes.func
}

export default TripsMarkers;