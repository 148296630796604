import React from "react";
import { LayersControl, TileLayer } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

const Layers = () => {

  return (
    <LayersControl position="topright">

      <LayersControl.BaseLayer className='tile-layer-title' checked name="OpenStreetMap">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="GoogleMap">
        <ReactLeafletGoogleLayer apiKey='AIzaSyCBog6x4CTKOBSwOsDCB2tnid299taYUiE' type={'hybrid'} useGoogMapsLoader={true} />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="OpenStreetMap B&W" >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
    </LayersControl>
  );

}

export default Layers;  