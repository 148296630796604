/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';

import { SAVE_NEW_LOCATION, UPDATE_LOCATION, LOAD_MANAGEABLE_USER_PROFILE, SUBMIT_MANAGEABLE_PROFILE, saveManageableUserProfile, changeValue } from 'services/ducks/modules/admin';
import { loadLocations } from 'services/ducks/modules/dataList';

import { getCookie } from 'services/security/helpers'
import { enableLoader, disableLoader } from 'services/ducks/modules/loader';

const adminMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case SAVE_NEW_LOCATION:
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/location/add/${store.getState().auth.cityId}`,
        data: {
          cityId: store.getState().auth.cityId,
          newNameLocation: store.getState().admin.newNameLocation,
          lat: store.getState().admin.draggableLat,
          lng: store.getState().admin.draggableLng,
          roleId: store.getState().auth.roleId,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('ADDTRIP SUCCESS', response);
          // Remise à zéro du champ
          store.dispatch(changeValue('newNameLocation', ''));
          changeValue('draggableLat', store.getState().auth.cityLat);
          changeValue('draggableLng', store.getState().auth.cityLng);
          // Rechargement de tous les points
          store.dispatch(loadLocations());
          // store.dispatch(changeValue('ammunition', 'balle'));
          // store.dispatch(changeValue('locationId', 1));
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('ADDTRIP ERROR', error);
          // setValues({ ...values, buttonText: 'Submit' });
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case UPDATE_LOCATION:
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/location/update/${store.getState().admin.selectedLocationId}`,
        data: {
          cityId: store.getState().auth.cityId,
          selectedNameLocation: store.getState().admin.selectedNameLocation,
          lat: store.getState().admin.draggableLat,
          lng: store.getState().admin.draggableLng,
          active: store.getState().admin.locationActive,
          roleId: store.getState().auth.roleId,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('ADDTRIP SUCCESS', response);
          // Remise à zéro du champ
          store.dispatch(changeValue('newNameLocation', ''));
          changeValue('draggableLat', store.getState().auth.cityLat);
          changeValue('draggableLng', store.getState().auth.cityLng);
          // Rechargement de tous les points
          store.dispatch(loadLocations());
          // store.dispatch(changeValue('ammunition', 'balle'));
          // store.dispatch(changeValue('locationId', 1));
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('ADDTRIP ERROR', error);
          // setValues({ ...values, buttonText: 'Submit' });
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_MANAGEABLE_USER_PROFILE:
      console.log(action.manageableUserId);
      store.dispatch(enableLoader('manageableUserProfileLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/user/${action.manageableUserId}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('ADDTRIP SUCCESS', response);
          // Remise à zéro du champ
          // store.dispatch(changeValue('newNameLocation', ''));
          // changeValue('draggableLat', store.getState().auth.cityLat);
          // changeValue('draggableLng', store.getState().auth.cityLng);
          // // Rechargement de tous les points
          // store.dispatch(loadLocations());
          // // store.dispatch(changeValue('ammunition', 'balle'));
          // // store.dispatch(changeValue('locationId', 1));
          // toast.success(response.data.message);
          store.dispatch(saveManageableUserProfile(response.data));
          store.dispatch(disableLoader('manageableUserProfileLoader'));
        })
        .catch(error => {
          console.log('ADDTRIP ERROR', error);
          // setValues({ ...values, buttonText: 'Submit' });
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case SUBMIT_MANAGEABLE_PROFILE:

      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API}/user/update`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        },
        data: {
          id: store.getState().admin.manageableUser.id,
          roleId: store.getState().admin.manageableUser.roleId,
          originalRoleId: store.getState().admin.manageableUser.originalRoleId,
          connectedRoleId: store.getState().auth.roleId,
          firstname: store.getState().admin.manageableUser.firstname,
          lastname: store.getState().admin.manageableUser.lastname,
          licenseNumber: store.getState().admin.manageableUser.licenseNumber,
          email: store.getState().admin.manageableUser.email,
          membershipId: store.getState().admin.manageableUser.membershipId,
          phone: store.getState().admin.manageableUser.phone,
          password: store.getState().admin.manageableUser.password,
          state: store.getState().admin.manageableUser.state
        }
      })
        .then(response => {
          console.log('PRIVATE PROFILE UPDATE SUCCESS', response);
          // updateUser(response, () => {
          //   store.dispatch(changeValue('buttonText', 'Submitted'));
          //   // store.dispatch(changeValue('name', 'Submitted'));
          toast.success(`Le profil de ${response.data.firstname} ${response.data.lastname} été mis à jour`);
          // });
        })
        .catch(error => {
          console.log('PRIVATE PROFILE UPDATE ERROR', error.response.data.error);
          // store.dispatch(changeValue('buttonText', 'Submit'));
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    default:
      next(action);
  }
};

export default adminMiddleware;