import { connect } from 'react-redux';

import { changeValue } from 'services/ducks/modules/map';

import MainMap from 'components/Map/Map';

const mapStateToProps = (state) => ({
  cityLat: state.map.cityLat,
  cityLng: state.map.cityLng,
  border: state.map.border,
  zoom: state.map.zoom,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainMap);