import { connect } from 'react-redux';

import { disableNotification } from 'services/ducks/modules/notification';

import NotificationIcon from 'components/Layout/NotificationIcon/NotificationIcon';

const mapStateToProps = (state) => ({
  notification: state.notification.notification
});

const mapDispatchToProps = (dispatch) => ({
  disableNotification: () => {
    dispatch(disableNotification());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationIcon);