import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
// import createLogger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

//modules
import auth from 'services/ducks/modules/auth';
import externalUser from 'services/ducks/modules/externalUser';
import trip from 'services/ducks/modules/trip';
import dataList from 'services/ducks/modules/dataList';
import map from 'services/ducks/modules/map';
import admin from 'services/ducks/modules/admin';
import taking from 'services/ducks/modules/taking';
import contact from 'services/ducks/modules/contact';
import loader from 'services/ducks/modules/loader';
import settings from 'services/ducks/modules/settings';
import debriefing from 'services/ducks/modules/debriefing';
import news from 'services/ducks/modules/news';
import adminReport from 'services/ducks/modules/adminReport';
import notification from 'services/ducks/modules/notification';
//middlewares
import authMiddleware from 'services/ducks/middlewares/authMiddleware';
import dataListMiddleware from 'services/ducks/middlewares/dataListMiddleware';
import tripMiddleware from 'services/ducks/middlewares/tripMiddleware';
import adminMiddleware from 'services/ducks/middlewares/adminMiddleware';
import takingMiddleware from 'services/ducks/middlewares/takingMiddleware';
import mapMiddleware from 'services/ducks/middlewares/mapMiddleware';
import settingsMiddleware from 'services/ducks/middlewares/settingsMiddleware';
import debriefingMiddleware from 'services/ducks/middlewares/debriefingMiddleware';
import newsMiddleware from 'services/ducks/middlewares/newsMiddleware';
import notificationMiddleware from 'services/ducks/middlewares/notificationMiddleware';

// const loggerMiddleware = createLogger(); // initialize logger

// const createStoreWithMiddleware = applyMiddleware( loggerMiddleware)(createStore); // apply logger to redux

// const reducer = combineReducers({
//   toDoApp
// });
const rootReducer = combineReducers({
    // router: connectRouter(history),
    auth,
    externalUser,
    trip,
    dataList,
    map,
    admin,
    taking,
    contact,
    loader,
    settings,
    debriefing,
    news,
    adminReport,
    notification,
  });
  
  const enhancersWithDevTools = composeWithDevTools(
    applyMiddleware(
      authMiddleware,
      dataListMiddleware,
      tripMiddleware,
      adminMiddleware,
      takingMiddleware,
      mapMiddleware,
      settingsMiddleware,
      debriefingMiddleware,
      newsMiddleware,
      notificationMiddleware,
    ),
  );
  
  const enhancers = compose(
    applyMiddleware(
      authMiddleware,
      dataListMiddleware,
      tripMiddleware,
      adminMiddleware,
      takingMiddleware,
      mapMiddleware,
      settingsMiddleware,
      debriefingMiddleware,
      newsMiddleware,
      notificationMiddleware,
    ),
  );
  
  const store = createStore(rootReducer, process.env.REACT_APP_ENV === 'development' ? enhancersWithDevTools : enhancers);
  
  export default store;

//   export default rootReducer;

// const configureStore = (initialState) => createStoreWithMiddleware(reducer, initialState);
// export default configureStore;