import { connect } from 'react-redux';

import { loadTripsById, loadDebriefings  } from 'services/ducks/modules/dataList';
import { changeValue, saveDebriefing, saveCheckedDebriefings } from 'services/ducks/modules/debriefing';

import Debriefing from 'scenes/Core/Debriefing/Debriefing';

const mapStateToProps = (state) => ({
  tripId: state.debriefing.tripId,
  ownUserId: state.auth.id,
  cityId: state.auth.cityId,
  roleId: state.auth.roleId,
  boarObs: state.debriefing.boarObs,
  foxObs: state.debriefing.foxObs,
  comment: state.debriefing.comment,

  tripsByIdData: state.dataList.tripsByIdData,
  debriefingsData: state.dataList.debriefingsData,
  tripsByIdLoader: state.loader.tripsByIdLoader,
  debriefingsLoader: state.loader.debriefingsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  saveDebriefing: () => {
    dispatch(saveDebriefing());
  },
  loadDebriefings: () => {
    dispatch(loadDebriefings());
  },
  loadTripsById: () => {
    dispatch(loadTripsById());
  },
  saveCheckedDebriefings: (tripId) => {
    dispatch(saveCheckedDebriefings(tripId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Debriefing);