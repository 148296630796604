import { connect } from 'react-redux';

import { loadTripsById, loadSpecies } from 'services/ducks/modules/dataList';

import AddSpecieBtn from 'scenes/Core/Report/Report';

const mapStateToProps = (state) => ({
  cityId: state.auth.cityId,
  tripId: state.taking.tripId,
  tripsByIdData: state.dataList.tripsByIdData,
  speciesData: state.dataList.speciesData,
  animalNbr: state.taking.animalNbr,
  species1: state.taking.species1,
  species2: state.taking.species2,
  species3: state.taking.species3,
  number1: state.taking.number1,
  number2: state.taking.number2,
  number3: state.taking.number3,
  tripByIdLoader: state.loader.tripByIdLoader,
  speciesLoader: state.loader.speciesLoader,
});

const mapDispatchToProps = (dispatch) => ({

  loadTripsById: () => {
    dispatch(loadTripsById());
  },

  loadSpecies: () => {
    dispatch(loadSpecies());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddSpecieBtn);