import { connect } from 'react-redux';

import { changeValue } from 'services/ducks/modules/admin';

import AddLocation from 'components/Map/AddLocationMarkers/AddLocationMarkers';

const mapStateToProps = (state) => ({
    cityLat: state.map.cityLat,
    cityLng: state.map.cityLng,
    draggableLat: state.admin.draggableLat,
    draggableLng: state.admin.draggableLng,
    addLocationChoice: state.admin.addLocationChoice,
    selectedLocationId: state.admin.selectedLocationId,
    locationActive: state.admin.locationActive,
    locationsData: state.dataList.locationsData,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: ( name, value ) => {
    dispatch(changeValue( name, value ));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddLocation);