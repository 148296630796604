import React, { useEffect } from "react";
import Layout from 'components/Layout/LayoutContainer';
import Map from 'components/Map/MapContainer';
import AddAreaForm from 'scenes/Admin/AddArea/AddAreaForm/AddAreaFormContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import 'leaflet-draw/dist/leaflet.draw.css';
import { BallBeat } from 'react-pure-loaders';

import PropTypes from 'prop-types';

const AddArea = ({
	cityLat,
	loadRestrictedAreas,
	restrictedAreasLoader,
}) => {

	useEffect(() => {
		// Charger les lieux-dits
		loadRestrictedAreas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout>
			<div className="container-fluid flex-grow-2">
				<h1 className="p-2 text-center">Gestion des zones de restriction</h1>
				<ToastContainer autoClose={1800} />
				<div className="loader">
					< BallBeat margin={'auto'} loading={restrictedAreasLoader ? true : false} />
				</div>
				{!restrictedAreasLoader &&
					<div className="d-flex row" >
						<div className="col-md-6 d-flex justify-content-center">
							{/* {Attend d'avoir des coordonnées avant de charger la map sinon erreur} */}
							{cityLat != null && <Map
								mapClass={'map-location'}
								addPolygons={true}
								restrictedAreas={false}
								wind={false}
							/>}
						</div>
						<div className="col-md-6 d-flex justify-content-center">
							<AddAreaForm />
						</div>
					</div>

				}
			</div>
		</Layout>
	);
};

AddArea.propTypes = {
	cityLat: PropTypes.number,
	loadRestrictedAreas: PropTypes.func,
	restrictedAreasLoader: PropTypes.bool
};

export default AddArea;