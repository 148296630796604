import { connect } from 'react-redux';

// import { loadProfilId } from 'src/actions/profil';
import {changeValue, submitActivate } from 'services/ducks/modules/externalUser';

import Activate from 'scenes/Auth/Activate/Activate';

const mapStateToProps = (state) => ({
  department: state.externalUser.department,
  city: state.externalUser.city,
  firstname: state.externalUser.firstname,
  lastname: state.externalUser.lastname,
  licenseNumber: state.externalUser.licenseNumber,
  membership: state.externalUser.membership,
  phone: state.externalUser.phone,
  email: state.externalUser.email,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  submitActivate: () => {
    dispatch(submitActivate());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Activate);