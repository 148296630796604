import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/LayoutContainer';
import { Link } from 'react-router-dom';

import { isAuth } from 'services/security/helpers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { isMobile } from "react-device-detect";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Zoom';
import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';
import { BallBeat } from 'react-pure-loaders';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from 'prop-types';

const AdminReport = ({
    cityId,
    userId,
    startingDate,
    endingDate,
    loadUsers,
    usersData,
    loadAdminReports,
    changeValue,
    pdfLoader,
    usersLoader,
}) => {

    const [pdfGenrated, setPdfGenerated] = useState(false);

    // On charge les aires de restriction si elles ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null, usersData === []]);

    // Fonction du champ controlé select
    const handleChangeSelect = name => event => {
        if (name === 'userId') {
            setPdfGenerated(false);
            return changeValue(name, parseInt(event.value))
        }
        setPdfGenerated(false);
        return changeValue(name, event.value)
    };

    const handleDateChange = name => (date) => {
        changeValue(name, date);
        setPdfGenerated(false);
    };

    const materialTheme = createMuiTheme({
        overrides: {
            MuiFormControl: {
                marginNormal: {
                    marginTop: 0,
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#C49D6D',
                }
            },
            MuiPickersDay: {
                daySelected: {
                    backgroundColor: '#C49D6D',
                    '&:hover': {
                        backgroundColor: '#C49D6D'
                    },
                }
            },
            MuiButton: {
                textPrimary: {
                    color: '#C49D6D',
                    '&:hover': {
                        color: '#C49D6D'
                    },
                }
            }
        },
    });

    // Données des select user
    const userSelectOptions = [{ value: 0, label: `Tous` }];
    usersData.map(user =>
        userSelectOptions.push({ value: user.id, label: `${user.lastname} ${user.firstname}` })
    )

    const addForm = () => (

        <form>
            { (isAuth().role.name === 'Admin' || isAuth().role.name === 'SuperAdmin') && (
                <Fade clear duration={500}>
                    <div className="form-container">
                        <label className="form-label">Chasseur</label>
                        <Select
                            menuPortalTarget={document.querySelector('body')}
                            options={userSelectOptions}
                            value={userSelectOptions.filter(obj => obj.value === userId)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeSelect('userId')}
                        />
                    </div>
                </Fade>
            )}
            <Fade clear duration={500} delay={80}>
                <div className="form-container">
                    <label className="form-label">Date de début</label>
                    <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            // label="Date picker dialog"
                            format="DD/MM/yyyy"
                            value={startingDate}
                            onChange={handleDateChange('startingDate')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </ThemeProvider>
                </div>
            </Fade>
            <Fade clear duration={500} delay={80}>
                <div className="form-container">
                    <label className="form-label">Date de fin</label>
                    <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            // label="Date picker dialog"
                            format="DD/MM/yyyy"
                            value={endingDate}
                            onChange={handleDateChange('endingDate')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </ThemeProvider>
                </div>
            </Fade>
            <Zoom clear duration={500} delay={320}>
                <div className="form-container">
                    {!pdfGenrated &&
                        <button className="btn btn-primary" onClick={(event) => {
                            event.preventDefault();
                            loadAdminReports();
                            setPdfGenerated(true)
                        }}>
                            Générer PDF
                        </button>
                    }
                    {pdfGenrated &&
                        <Link to="/admin/report/pdf" >
                            <button className="btn btn-primary w-100">
                                Voir PDF
                        </button>
                        </Link>
                    }
                </div>
            </Zoom>
        </form>

    );

    return (
        <Layout>
            <div className="container-fluid row flex-grow-2">
                <div className="col-md-12 add-form-container">
                    <ToastContainer autoClose={1800} />
                    <Fade clear duration={500} delay={220}>
                        <h1 className="text-center">Bilan Administrateur</h1>
                    </Fade>
                    {console.log(isMobile)}
                    {isMobile &&
                        <Fade clear duration={500} delay={220}>
                            <h5 className="text-center">Version mobile non supportée</h5>
                        </Fade>
                    }
                    {!pdfLoader && !usersLoader && addForm()}
                    <div className="loader">
                        < BallBeat margin={'auto'} loading={pdfLoader && usersLoader ? true : false} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

AdminReport.propTypes = {
    cityId: PropTypes.number,
    userId: PropTypes.number,
    startingDate: PropTypes.instanceOf(Date),
    endingDate: PropTypes.instanceOf(Date),
    loadUsers: PropTypes.func,
    usersData: PropTypes.arrayOf(
        PropTypes.object
    ),
    loadAdminReports: PropTypes.func,
    changeValue: PropTypes.func,
    pdfLoader: PropTypes.bool,
    usersLoader: PropTypes.bool,
};

export default AdminReport;
