import { connect } from 'react-redux';

import { loadNews } from 'services/ducks/modules/dataList';
import { changeValue, saveANews, updateANews, deleteANews } from 'services/ducks/modules/news';

import AddNews from 'scenes/Admin/AddNews/AddNews';

const mapStateToProps = (state) => ({
  newsId: state.news.newsId,
  title: state.news.title,
  content: state.news.content,
  cityId: state.auth.cityId,
  roleId: state.auth.roleId,
  newsData: state.dataList.newsData,
  newsLoader: state.loader.newsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  saveANews: () => {
    dispatch(saveANews());
  },
  updateANews: () => {
    dispatch(updateANews());
  },
  deleteANews: () => {
    dispatch(deleteANews());
  },
  loadNews: () => {
    dispatch(loadNews());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNews);