
// == Action types ======================

export const CHANGE_EXTERNAL_USER_VALUE = 'CHANGE_EXTERNAL_USER_VALUE';
export const LOAD_EXTERNAL_USER_VALUE = 'LOAD_EXTERNAL_USER_VALUE';
export const SUBMIT_ACTIVATE = 'SUBMIT_ACTIVATE';
export const SIGNOUT = 'SIGNOUT';


// == Action creators

export const changeValue = (name, value) => ({
  type: CHANGE_EXTERNAL_USER_VALUE,
  name,
  value: value,
});


export const submitActivate = () => ({
  type: SUBMIT_ACTIVATE,
});

export const loadExternalUserValue = () => ({
  type: LOAD_EXTERNAL_USER_VALUE,
});

// == Reducer
const initialState = {
  role: '',
  department: '',
  city: '',
  firstname: '',
  lastname: '',
  licenseNumber: '',
  phone: '',
  email: '',
  buttonText: 'Submit',
  token: '',
};


function externalUser(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_EXTERNAL_USER_VALUE:
      // console.log(action.name)
      return {
        ...state,
        [action.name]: action.value,
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default externalUser;
