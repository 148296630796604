import { connect } from 'react-redux';

import LocationsMarkers from 'components/Map/LocationsMarkers/LocationsMarkers';

const mapStateToProps = (state) => ({
  locationsData: state.dataList.locationsData,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationsMarkers);