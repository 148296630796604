import React from "react";
import { Polygon, Tooltip } from 'react-leaflet'
import { iconLocation } from 'services/helpers/icons';

import PropTypes from 'prop-types';

const RestrictedAreas = ({
	restrictedAreasData,
}) => {

	return (
		restrictedAreasData.map(restrictedArea => {
			if (restrictedArea.active === true) {
				console.log(restrictedArea)
				return (
					<Polygon key={restrictedArea.name} color="#fc625a" positions={restrictedArea.coordinates} icon={iconLocation(true)} label={restrictedArea.name}>
						<Tooltip icon={iconLocation(true)}>{restrictedArea.name}</Tooltip>
					</Polygon>
				)
			}
			return false;
		})
	);
};

RestrictedAreas.propTypes = {
	restrictedAreasData: PropTypes.arrayOf(
		PropTypes.object
	),
};

export default RestrictedAreas;