import React, { useEffect, useRef, useMemo } from "react";
import { Marker, Tooltip } from 'react-leaflet';

import { iconLocation } from 'services/helpers/icons';

import PropTypes from 'prop-types';

const AddMapLocation = ({
	cityLat,
	cityLng,
	draggableLat,
	draggableLng,
	selectedLocationId,
	locationActive,
	changeValue,
	addLocationChoice,
	locationsData
}) => {

	useEffect(() => {
		changeValue('draggableLat', cityLat);
		changeValue('draggableLng', cityLng);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Mise à jour des Lat et lng du nouveau point dans le state
	 * @param {*} event 
	 */
	const markerRef = useRef(null);

	const updateMarker = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current
				const latLng = marker.getLatLng(); //get updated marker LatLng
				changeValue('draggableLat', latLng.lat);
				changeValue('draggableLng', latLng.lng);
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	return (
		<>
			{locationsData.map((location) => {
				if (location.active === true) {
					return (<Marker key={`${location.name}${location.id}`} position={[location.lat, location.lng]} color={'grey'} icon={iconLocation(false)} label='JD'>
						<Tooltip className="tooltip-marker" direction="center" offset={[0, -28]} opacity={0.81} permanent color={'grey'} background-color="none">
							<span>{`${location.name}`}</span>
						</Tooltip>
					</Marker>
					)
				}
				return false;
			})
			}

			{draggableLat != null && addLocationChoice === 'Ajouter' &&

				<Marker
					draggable={true}
					eventHandlers={updateMarker}
					ref={markerRef}
					position={[draggableLat, draggableLng]}
					icon={iconLocation(true)}
				>
				</Marker>
			}

			{draggableLat != null && addLocationChoice === 'Modifier' && selectedLocationId !== null &&
				<Marker
					color={locationActive ? "red" : 'grey'}
					draggable={true}
					eventHandlers={updateMarker}
					ref={markerRef}
					position={[draggableLat, draggableLng]}
					icon={iconLocation(locationActive)}
				>
				</Marker>
			}
		</>
	);
};

AddMapLocation.propTypes = {
	cityLat: PropTypes.number,
	cityLng: PropTypes.number,
	draggableLat: PropTypes.number,
	draggableLng: PropTypes.number,
	selectedLocationId: PropTypes.number,
	locationActive: PropTypes.bool,
	addLocationChoice: PropTypes.string,
	changeValue: PropTypes.func,
};

export default AddMapLocation;