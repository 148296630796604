import { connect } from 'react-redux';

import WindDegreesLayer from 'components/Map/WindDegreesLayer/WindDegreesLayer';

const mapStateToProps = (state) => ({
  meridiem: state.map.meridiem,
  date: state.map.date,
  weatherData: state.dataList.weatherData,
});

const mapDispatchToProps = (dispatch) => ({
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WindDegreesLayer);