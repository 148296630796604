import React, { useState, useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Map from 'components/Map/MapContainer';

import Layout from 'components/Layout/LayoutContainer';
import ModifyForm from 'scenes/Core/Modify/ModifyForm/ModifyFormContainer';
import { today } from 'services/helpers/date';
import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';

import Fade from 'react-reveal/Zoom';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BallBeat } from 'react-pure-loaders';

import PropTypes from 'prop-types';

const Modify = ({
    ownUserId,
    roleId,
    tripsData,
    locationsData,

    cityId,
    limitRegistration,
    tripsLoader,
    locationsLoader,
    settingsLoader,
    loadTrips,
    loadLocations,
}) => {



    const [selectedTrip, setSelectedTrip] = useState(null);

    //TODO socketio

    // On charge les sorties si elles ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadTrips();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || tripsData === []]);

    // On charge les sorties si elles ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || locationsData === []]);

    // Fonction du champ controlé select pour le chois de la sortie
    const handleChangeSelectTrip = e => {
        setSelectedTrip(e.value);
        console.log(e.value)
    };

    // Retourne à l'accueil si pas autorisé
    if (limitRegistration && roleId === 3) {
        return <Redirect to='/' />
    }

    // Listing des sorties
    const tripsSelect = () => {

        const selectTripsChoice = [];

        tripsData.map((currentTrip, tripIndex) => {

            let currentMeridiem = 'ce soir';

            if (currentTrip.meridiem === "morning" && currentTrip.date === today()) {
                currentMeridiem = 'ce matin';
            } else if (currentTrip.meridiem === "morning" && currentTrip.date !== today()) {
                currentMeridiem = 'demain matin';
            }

            // si admin ou superadmin on peut changer toutes les sorties sinon uniquement celles liées au userid
            if ((roleId === 1 || roleId === 2)) {
                selectTripsChoice.push({
                    value: tripIndex, label:
                        <Fragment>
                            {currentTrip.user.firstname}{' '}
                            {currentTrip.user.lastname}{' '}
                            {currentMeridiem}{' '}
                            {(currentTrip.state === 0 && limitRegistration) ? <FontAwesomeIcon color='#66afff' icon={faPaperPlane} /> : ''}
                            {(currentTrip.state === 1 && limitRegistration) ? <FontAwesomeIcon color='#38db0a' icon={faCheck} /> : ''}
                        </Fragment>
                })
            } else {
                ownUserId === currentTrip.userId && selectTripsChoice.push(
                    {
                        value: tripIndex,
                        label:
                            <Fragment>
                                {currentTrip.user.firstname}{' '}
                                {currentTrip.user.lastname}{' '}
                                {currentMeridiem}{''}
                                {(currentTrip.state === 0 && limitRegistration) ? <FontAwesomeIcon color='#66afff' icon={faPaperPlane} /> : ''}
                                {(currentTrip.state === 1 && limitRegistration) ? <FontAwesomeIcon color='#38db0a' icon={faCheck} /> : ''}
                            </Fragment>
                    }
                )
            }
            return false;
        })

        return (
            <Fade clear duration={500}>
                <form>
                    <div className="form-container pb-4">
                        <label className="form-label">Selectionner une sortie</label>
                        <Select
                            menuPortalTarget={document.querySelector('body')}
                            options={selectTripsChoice}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeSelectTrip}
                        />
                    </div>
                    {selectedTrip === null && <div clasName="pb-3"></div>}
                </form>
            </Fade>
        )
    }


    const addForm2 = () => (
        tripsData.map((currentTrip, tripIndex) => {
            if (tripIndex === selectedTrip) {
                return (
                    < ModifyForm
                        key={currentTrip.id + currentTrip.user.firstname}
                        tripIndex={tripIndex}
                        tripId={currentTrip.id}
                        date={currentTrip.date}
                        firstname={currentTrip.user.firstname}
                        lastname={currentTrip.user.lastname}
                        meridiem={currentTrip.meridiem}
                        ammunition={currentTrip.ammunition}
                        locationId={currentTrip.locationId}
                        state={currentTrip.state}
                    />
                )
            }
            return false;
        })
    )
    return (
        <Layout>
            <div className="container-fluid row flex-grow-2">

                <div className="col-md-6 add-form-container">
                    <ToastContainer autoClose={1800} />
                    {limitRegistration &&
                        <Fade clear duration={500} delay={150}>
                            <h1 className="p-2 text-center">Gérer les sorties</h1>
                        </Fade>
                    }
                    {!limitRegistration &&
                        <Fade clear duration={500} delay={150}>
                            <h1 className="p-2 text-center">Modifier une sortie</h1>
                        </Fade>
                    }
                    <div className="loader">
                        < BallBeat margin={'auto'} loading={tripsLoader || locationsLoader ? true : false} />
                    </div>
                    {!tripsLoader && !locationsLoader && !settingsLoader && tripsSelect()}
                    {tripsData !== [] && selectedTrip !== null && addForm2()}
                    
                    {tripsData.length === 0 && !tripsLoader && (
                        <h3 className="text-center">
                            Aucune sortie à modifier
                        </h3>
                    )}
                </div>
                <div className="map-location-container col-md-6">
                    {!locationsLoader &&
                        <Map
                            mapClass={'map-location'}
                            markers={'locationsMarkers'}
                            restrictedAreas={true}
                            wind={false}
                        />}
                </div>
            </div>
        </Layout>
    );

};

Modify.propTypes = {
    ownUserId: PropTypes.number,
    roleId: PropTypes.number,
    tripsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    loadTrips: PropTypes.func,
    loadLocations: PropTypes.func,
    locationsLoader: PropTypes.bool,
    settingsLoader: PropTypes.bool,
};

export default Modify;
