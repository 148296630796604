import { connect } from 'react-redux';

import { loadUsers, loadAdminReports } from 'services/ducks/modules/dataList';
import { changeValue } from 'services/ducks/modules/adminReport';

import AdminReport from 'scenes/Admin/AdminReport/AdminReport';

const mapStateToProps = (state) => ({
  cityId: state.auth.cityId,
  userId: state.adminReport.userId,
  startingDate: state.adminReport.startingDate,
  endingDate: state.adminReport.endingDate,

  usersData: state.dataList.usersData,
  usersLoader: state.loader.usersLoader,
  pdfLoader: state.loader.pdfLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  loadUsers: () => {
    dispatch(loadUsers());
  },
  loadAdminReports: () => {
    dispatch(loadAdminReports());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminReport);