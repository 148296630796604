import { connect } from 'react-redux';

// import { loadProfilId } from 'src/actions/profil';
import { changeValue, loadProfile } from 'services/ducks/modules/auth';
import { loadMemberships, loadUsers, loadRoles } from 'services/ducks/modules/dataList';
import { changeManageableUserValue, loadManageableUserProfile, resetManageableUserValue, submitManageableProfile } from 'services/ducks/modules/admin';

import Administrate from 'scenes/Admin/Administrate/Administrate';

const mapStateToProps = (state) => ({
  firstname: state.auth.firstname,
  manageableUser: state.admin.manageableUser,
  membershipsData: state.dataList.membershipsData,
  rolesData: state.dataList.rolesData,
  usersData: state.dataList.usersData,
  profileLoader: state.loader.profileLoader,
  membershipsLoader: state.loader.membershipsLoader,
  rolesLoader: state.loader.rolesLoader,
  usersLoader: state.loader.usersLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  changeManageableUserValue: (name, value) => {
    dispatch(changeManageableUserValue(name, value));
  },
  loadProfile: (history) => {
    dispatch(loadProfile(history));
  },
  loadMemberships: (history) => {
    dispatch(loadMemberships());
  },
  loadRoles: (history) => {
    dispatch(loadRoles());
  },
  loadUsers: (history) => {
    dispatch(loadUsers());
  },
  loadManageableUserProfile: (manageableUserId) => {
    dispatch(loadManageableUserProfile(manageableUserId));
  },
  resetManageableUserValue: () => {
    dispatch(resetManageableUserValue());
  },
  submitManageableProfile: () => {
    dispatch(submitManageableProfile());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Administrate);