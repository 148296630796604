import React, { useEffect } from 'react';

import { isAuth } from 'services/security/helpers';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Zoom';
import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';

import PropTypes from 'prop-types';

const AddForm = ({
    ownUserId,
    cityId,
    userId,
    roleId,
    loadUsers,
    usersData,
    meridiem,
    ammunition,
    locationId,
    locationsData,
    settingsData,

    loadLocations,
    loadRestrictedAreas,
    loadProfile,
    changeValue,
    submitTrip,
    loadSettings,
    usersLoader,
    settingsLoader,
    locationsLoader,
    limitRegistration,
}) => {

    // Chargement en début de composant
    useEffect(() => {
        if (cityId != null) {
            // Mettre l'utilisateur par défaut en cas d'admin
            changeValue('userId', ownUserId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    
    console.log(cityId)
    console.log(locationsData)
    // On charge les aires de restriction si elles ne sont pas dans le store
	
    // useEffect(() => {
    //         // Mettre l'utilisateur par défaut en cas d'admin
    //         changeValue('userId', ownUserId)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ownUserId]);
    
    // Chargement en début de composant
    useEffect(() => {
        if (locationsData !== null) {
            changeValue('locationId', locationsData[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationsData]);

    // Fonction du champ controlé select
    const handleChangeSelect = name => event => {
        if (name === 'userId' || name === 'locationId') {
            return changeValue(name, parseInt(event.value))

        }
        return changeValue(name, event.value)
    };

    // Fonction pour soumettre le formulaire
    const clickSubmit = event => {
        event.preventDefault();
        submitTrip();
    };

    // Données des select user
    const userSelectOptions = [];

    usersData.map(user =>
        user.state && userSelectOptions.push({ value: user.id, label: `${user.lastname} ${user.firstname}` })
    )

    // Données des select meridiem
    const meridiemSelectOptions = [
        { value: "morning", label: 'Ce matin' },
        { value: "evening", label: 'Ce soir' },
        { value: "morning+1", label: 'Demain matin' },
    ];

    // Données des select ammunition
    const ammunitionSelectOptions = [
        { value: "balle", label: 'Balle' },
        { value: "plomb", label: 'Plomb' },
        { value: "flêche", label: 'Flêche' },
    ];

    // Données des select location
    const locationSelectOptions = [];

    locationsData.map(location => {
        if (location.active === true) {
            return (locationSelectOptions.push({ value: location.id, label: location.name }))

        }
        return false
    })

    return (
        <form>
            { (isAuth().role.name === 'Admin' || isAuth().role.name === 'SuperAdmin') && (
                <Fade clear duration={500}>
                    <div className="form-container">
                        <label className="form-label">Chasseur</label>
                        <Select
                            menuPortalTarget={document.querySelector('body')}
                            options={userSelectOptions}
                            value={userSelectOptions.filter(obj => obj.value === userId)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeSelect('userId')}
                        />
                    </div>
                </Fade>
            )}
            <Fade clear duration={500} delay={80}>
                <div className="form-container">
                    <label className="form-label">Periode</label>
                    <Select
                        menuPortalTarget={document.querySelector('body')}
                        options={meridiemSelectOptions}
                        value={meridiemSelectOptions.filter(obj => obj.value === meridiem)}
                        isSearchable={false}
                        theme={selectStyle}
                        onChange={handleChangeSelect('meridiem')}
                    />
                </div>
            </Fade>
            <Fade clear duration={500} delay={160}>
                <div className="form-container">
                    <label className="form-label">Type de munition</label>
                    <Select
                        menuPortalTarget={document.querySelector('body')}
                        options={ammunitionSelectOptions}
                        value={ammunitionSelectOptions.filter(obj => obj.value === ammunition)}
                        isSearchable={false}
                        theme={selectStyle}
                        onChange={handleChangeSelect('ammunition')}
                    />
                </div>
            </Fade>
            {((limitRegistration && (roleId === 1 || roleId === 2 || roleId === 4)) || !limitRegistration) &&
                <Fade clear duration={500} delay={240}>
                    <div className="form-container">
                        <label className="form-label">Lieu-dit</label>
                        <Select
                            menuPortalTarget={document.querySelector('body')}
                            menuPlacement="top"
                            options={locationSelectOptions}
                            value={locationSelectOptions.filter(obj => obj.value === locationId)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeSelect('locationId')}
                        />
                    </div>
                </Fade>}
            <Zoom clear duration={500} delay={320}>
                <div className="form-container">
                    <button className="btn btn-primary" onClick={clickSubmit}>
                        Ajouter
                </button>

                </div>
            </Zoom>
        </form>
    );
};

AddForm.propTypes = {
    ownUserId: PropTypes.number,
    cityId: PropTypes.number,
    userId: PropTypes.number,
    loadUsers: PropTypes.func,
    usersData: PropTypes.array,
    meridiem: PropTypes.string,
    ammunition: PropTypes.string,
    locationId: PropTypes.number,
    loadLocations: PropTypes.func,
    locationsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    changeValue: PropTypes.func,
    submitTrip: PropTypes.func,
    locationsLoader: PropTypes.bool,
    settingsLoader: PropTypes.bool,
};

export default AddForm;
