// == Action types ======================

export const CHANGE_REPORT_VALUE = 'CHANGE_REPORT_VALUE';
export const CHANGE_TAKINGS = 'CHANGE_TAKINGS';
export const SAVE_A_TAKING = 'SAVE_A_TAKING';
export const DELETE_TAKING_IN_REDUCER = 'DELETE_TAKING_IN_REDUCER';
export const ADD_SPECIE = 'ADD_SPECIE';
export const REMOVE_SPECIE = 'REMOVE_SPECIE';
export const LOAD_TAKINGS_BY_TRIP_ID = 'LOAD_TAKINGS_BY_TRIP_ID';
export const SIGNOUT = 'SIGNOUT';

// == Action creators

export const changeValue = (name, value) => (
  {
    type: CHANGE_REPORT_VALUE,
    name,
    value,
  });

export const changeTakings = (id, name, value) => (
  {
    type: CHANGE_TAKINGS,
    id,
    name,
    value,
  });

export const loadTakingsByTripId = (takings) => (
  {
    type: LOAD_TAKINGS_BY_TRIP_ID,
    takings
  });

export const saveTakings = () => (
  {
    type: SAVE_A_TAKING,
  });

export const deleteTakingInReducer = () => (
  {
    type: DELETE_TAKING_IN_REDUCER,
  });

export const addSpecie = () => (
  {
    type: ADD_SPECIE,
  });

export const removeSpecie = () => (
  {
    type: REMOVE_SPECIE,
  });

// == Reducers
const initialState = {
  tripId: null,
  imgPath: '',
  animalNbr: 1,
  takings: [
    {
      specie: 1,
      sex: 'M',
      estimalWeight: null,
      braceletNumber: '',
      horns: null
    }
  ],
  image: null,
  submitBtnText: 'Valider la déclaration'
};

function reportReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_REPORT_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };

    case DELETE_TAKING_IN_REDUCER:
      return {
        tripId: state.tripId,
        nbrOfSpecies: 1,
        takings: [
          {
            specie: 1,
          }
        ],
        species1: 1,
        species2: null,
        species3: null,
        number1: '',
        number2: '',
        number3: '',
        bracelet_number: '',
        boarSex: 'M',
        boarEstimalWeight: null,
      }

    case CHANGE_TAKINGS:

      return {
        ...state,
        takings:
          state.takings.map((taking, index) => {
            if (index === action.id) {
              return { ...taking, [action.name]: action.value }
            } else {
              return taking
            }
          }),
      }

    case ADD_SPECIE:
      return {
        ...state,
        animalNbr: state.animalNbr + 1,
        takings: [
          ...state.takings,
          {
            specie: 1,
            sex: 'M',
            estimalWeight: undefined,
            braceletNumber: '',
            horns: undefined
          }
        ]
      }

    case REMOVE_SPECIE:
      return {
        ...state,
        animalNbr: state.animalNbr - 1,
        takings: state.takings.slice(0, -1),
      }

    case LOAD_TAKINGS_BY_TRIP_ID:
      if (action.takings.length !== 0) {
        return {
          ...state,
          animalNbr: action.takings.length,
          takings: action.takings,
          submitBtnText: 'Modifier la déclaration',
        }
      } else {
        return {
          ...state,
          animalNbr: 1,
          takings: [
            {
              specie: 2,
              sex: 'M',
              estimalWeight: undefined,
              braceletNumber: '',
              horns: undefined,
            }
          ],
          submitBtnText: 'Valider la déclaration',
        }
      }

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default reportReducer;
