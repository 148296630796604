import { connect } from 'react-redux';

import { changeTakings } from 'services/ducks/modules/taking';

import Animal from 'scenes/Core/Report/Animal/Animal';

const mapStateToProps = (state) => ({
  speciesData: state.dataList.speciesData,
  takings: state.taking.takings,
});

const mapDispatchToProps = (dispatch) => ({
  changeTakings: (id, name, value) => {
    dispatch(changeTakings(id, name, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Animal);