import React from 'react';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';
import PropTypes from 'prop-types';

const Animal = ({
    specieNum,
    
    speciesData,
    takings,
    changeTakings
}) => {

    const CHEVREUIL = 1;
    const SANGLIER = 2;

    const handleChangeSpecie = event => {
        changeTakings(specieNum, 'specie', event.value)
    };

    const handleChangeSex = event => {
        changeTakings(specieNum, 'sex', event.value)
    };

    const handleChangeBoarEstimalWeight = event => {
        changeTakings(specieNum, 'estimalWeight', parseInt(event.target.value))
    };

    const handleChangeBraceletNumber = event => {
        changeTakings(specieNum, 'braceletNumber', event.target.value)
    };

    const handleChangeHorns = event => {
        changeTakings(specieNum, 'horns', parseInt(event.target.value))
    };

    // Données des select liste des espèces

    const speciesSelectOptions = () => {
        const speciesSelectArray = [];
        speciesData.map(specie => {
            return speciesSelectArray.push({ value: specie.id, label: specie.name })
        })
        return speciesSelectArray;
    }

    const sexSelectOptions = [{ value: 'M', label: 'Male' }, { value: 'F', label: 'Femelle' }];

    /**
     * Ajouter un numéro de bracelet
     */
    const addBraceletNumber = () => (
        <Fade bottom duration={500}>
            <div className="form-container-bracelet">
                <label className="form-label">Numéro de bracelet</label>
                <input className="form-input" onChange={handleChangeBraceletNumber} value={takings[specieNum].braceletNumber} type="text" />
            </div>
        </Fade>
    )
    /**
     * Ajouter un numéro de bracelet
     */
    const addHorns = () => (
        <Fade bottom duration={500}>
            <div className="form-container-bracelet">
                <label className="form-label">Nombre de Cors</label>
                <input className="form-input" onChange={handleChangeHorns} value={takings[specieNum].horns} type="number" />
            </div>
        </Fade>
    )

    /**
     * Ajouter le sexe et le poid du sanglier
     */
    const addWeight = () => (
        <Fade bottom duration={500}>
            <div className="form-container-split">
                <label className="form-label">Poid(kg)</label>
                <input className="form-input-split" onChange={handleChangeBoarEstimalWeight} value={takings[specieNum].estimalWeight} type="number" />
            </div>

        </Fade>
    )

    return (
        <form>
            <Zoom clear duration={500} delay={220}>
                {specieNum > 0 && <div className="form-container">
                    <div className="linebreak"></div>
                </div>}
            </Zoom>
            <Zoom clear duration={500} delay={220}>
                <div className="container-split">
                    <div className="form-container-split">
                        <label className="form-label specie">Espèce</label>
                        <Select
                            menuPortalTarget={document.querySelector('body')}
                            options={speciesSelectOptions()}
                            value={speciesSelectOptions().filter(obj => obj.value === takings[specieNum].specie)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeSpecie}
                        />
                    </div>
                    <div className="form-container-split">
                        <label className="form-label">Sexe</label>
                        <Select
                            menuPortalTarget={document.querySelector('body')}
                            options={sexSelectOptions}
                            value={sexSelectOptions.filter(obj => obj.value === takings[specieNum].sex)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeSex}
                        />
                    </div>

                </div>
            </Zoom>
            <div className="container-split">
            {takings[specieNum].specie === SANGLIER && addWeight()}
            {takings[specieNum].specie === CHEVREUIL && addBraceletNumber()}
            {takings[specieNum].specie === CHEVREUIL && addHorns()}
            </div>
        </form>
    );
};

Animal.propTypes = {
    speciesData: PropTypes.arrayOf(
        PropTypes.object
    ),
    takings: PropTypes.arrayOf(
        PropTypes.object
    ),
    specieNum: PropTypes.number,
    changeTakings: PropTypes.func
};

export default Animal;
