import { connect } from 'react-redux';

import Admin from 'scenes/Admin/Admin/Admin';

const mapStateToProps = (state) => ({
  role: state.auth.role,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Admin);