import React, { useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Footer from 'components/Footer/Footer';
import { Link, } from 'react-router-dom';

import PropTypes from 'prop-types';

const Reset = ({
    match,
    email,
    city,
    newPassword,
    buttonText,
    changeValue,
    submitReset
}) => {

    useEffect(() => {
        changeValue('password', '')
        changeValue('buttonText', 'Modifier')
        let token = match.params.token;
        const { 
            email,
            city,
            cityId,
            departmentId,
         } = jwt.decode(token);
        if (token) {
            changeValue('email', email)
            changeValue('city', city)
            changeValue('cityId', cityId)
            changeValue('departmentId', departmentId)
            changeValue('token', token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = event => {
        changeValue('password', event.target.value)
        // setValues({ ...values, newPassword: event.target.value });
    };

    const clickSubmit = event => {
        event.preventDefault();
        submitReset();
    };

    const passwordResetForm = () => (

        <form>
            <div className="user-card text-center p-3 mb-5 mx-2">
            <h2 className="pb-3">ACCA de {city}</h2>
            <h3 className="">{email}</h3>
            </div>
            
            <h3 className="py-3">Nouveau mot de passe</h3>

            <div className="form-container">
                <input
                    className="form-input p-3"
                    onChange={handleChange}
                    value={newPassword}
                    type="password"
                    placeholder="Saisir le mot de passe"
                    required
                />
            </div>

            <div>
                {buttonText === 'Connexion' &&
                <Link to="/" className="forgotten-password">
                    <button className="btn btn-primary" >
                        {buttonText}
                    </button>
                </Link>
                }
                {buttonText === 'Modifier' &&
                    <button className="btn btn-primary" onClick={clickSubmit}>
                        {buttonText}
                    </button>
                }
            </div>
        </form>
    );

    return (

        <>
            <div className="signin-container row justify-content-between">
                <div className="signin-title-container column col-md-4 ">
                    <Link to="/" className="forgotten-password"><h1 className="title">Chasse Connect</h1></Link>
                </div>
                <ToastContainer autoClose={3000} />
                <div className="col-md-6 offset-md-3 d-flex align-items-center justify-content-center">
                    <ToastContainer autoClose={3000} />
                    {passwordResetForm()}
                </div>
                <Footer />
            </div>


        </>
    );
};

Reset.propTypes = {
    email: PropTypes.string,
    city: PropTypes.number,
    newPassword: PropTypes.string,
    buttonText: PropTypes.func,
    changeValue: PropTypes.func,
    submitReset: PropTypes.func
};

export default Reset;
