import { connect } from 'react-redux';

import { changeValue, loadSettings, saveHourLimitation, saveToogleLimitation, saveToogleRegistrationMode, saveDelayHour, saveToogleForceDebriefing } from 'services/ducks/modules/settings';

import Settings from 'scenes/Admin/Settings/Settings';

const mapStateToProps = (state) => ({
  eveningLimitation: state.settings.eveningLimitation,
  eveningLimitationHour: state.settings.eveningLimitationHour,
  morningLimitation: state.settings.morningLimitation,
  forceDebriefing: state.settings.forceDebriefing,
  morningLimitationHour: state.settings.morningLimitationHour,
  limitRegistration: state.settings.limitRegistration,
  delayHour: state.settings.delayHour,
  settingsLoader: state.loader.settingsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  loadSettings: () => {
    dispatch(loadSettings());
  },
  saveHourLimitation: (hourLimitationType, hourLimitationDate) => {
    dispatch(saveHourLimitation(hourLimitationType, hourLimitationDate));
  },
  saveToogleLimitation: (limitationType, limitationBool) => {
    dispatch(saveToogleLimitation(limitationType, limitationBool));
  },
  saveToogleRegistrationMode: (limitRegistration) => {
    dispatch(saveToogleRegistrationMode(limitRegistration));
  },
  saveDelayHour: (delayHour) => {
    dispatch(saveDelayHour(delayHour));
  },
  saveToogleForceDebriefing: (forceDebriefing) => {
    dispatch(saveToogleForceDebriefing(forceDebriefing));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);