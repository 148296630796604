/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';

import { getCookie } from 'services/security/helpers'

import {
  SUBMIT_TRIP,
  changeValue
} from 'services/ducks/modules/trip';

import {
  UPDATE_TRIP,
  DELETE_TRIP,
  deleteTripInReducer,
} from 'services/ducks/modules/dataList';

const tripMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case SUBMIT_TRIP:
    console.log('ttttt')
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/addTrip`,
        data: {
          userId: store.getState().trip.userId,
          meridiem: store.getState().trip.meridiem,
          ammunition: store.getState().trip.ammunition,
          locationId: store.getState().trip.locationId,
          cityId: store.getState().auth.cityId,
          roleId: store.getState().auth.roleId,
          limitRegistration: store.getState().settings.limitRegistration,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('ADDTRIP SUCCESS', response);
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('ADDTRIP ERROR', error.response.data.error);
          // setValues({ ...values, buttonText: 'Submit' });
          store.dispatch(changeValue('buttonText', 'Continuer'));
          toast.error(error.response.data.error);
        });

      next(action);
      break;

      case UPDATE_TRIP:
      console.log('le middleware update trip est appelé');
      // console.log(store.getState().dataList.tripsData[action.tripIndex].meridiem);
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/trip/update/${store.getState().dataList.tripsData[action.tripIndex].id}`,
        data: {
          connectedUserId: store.getState().auth.id,
          tripUserId: store.getState().dataList.tripsData[action.tripIndex].userId,
          date: store.getState().dataList.tripsData[action.tripIndex].date,
          meridiem: store.getState().dataList.tripsData[action.tripIndex].meridiem,
          ammunition: store.getState().dataList.tripsData[action.tripIndex].ammunition,
          locationId: store.getState().dataList.tripsData[action.tripIndex].locationId,
          cityId: store.getState().auth.cityId,
          roleId: store.getState().auth.roleId,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('TRIPS UPDATED', response);
          if (response.data.type === 'warning'){
            toast.warning(response.data.message);
          } else {
            toast.success(response.data.message);
          }
        })
        .catch(error => {
          console.log('TRIPS UPDATED ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

      case DELETE_TRIP:
      console.log('le middleware delete trip est appelé');
      // console.log(store.getState().dataList.tripsData[action.tripIndex].meridiem);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/trip/delete/${store.getState().dataList.tripsData[action.tripIndex].id}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('TRIPS DELETED', response);
          toast.success(response.data.message);
          store.dispatch(deleteTripInReducer(store.getState().dataList.tripsData[action.tripIndex].id));
        })
        .catch(error => {
          console.log('TRIPS DELETE ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    default:
      next(action);
  }
};

export default tripMiddleware;