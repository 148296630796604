
// == Action types ======================

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const SAVE_NOTIFICATIONS = 'SAVE_NOTIFICATIONS';
export const DISABLE_NOTIFICATIONS = 'DISABLE_NOTIFICATIONS';
export const SIGNOUT = 'SIGNOUT';

// == Action creators

export const loadNotifications = () => ({
  type: LOAD_NOTIFICATIONS,
});

export const saveNotifications = (notification) => ({
  type: SAVE_NOTIFICATIONS,
  notification
});
export const disableNotification = () => ({
  type: DISABLE_NOTIFICATIONS,
});

// == Reducer
const initialState = {
  notification: 0

};

function notificationReducer(state = initialState, action = {}) {

  switch (action.type) {

    case SAVE_NOTIFICATIONS:
      return {
        ...state,
        notification: action.notification,
      };

    case DISABLE_NOTIFICATIONS:
      return {
        ...state,
        notification: 0
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default notificationReducer;
