import React from 'react';

import PropTypes from 'prop-types';

const AddSpecieBtn = ({
    animalNbr,
    addSpecie,
    removeSpecie,
}) => {

    const handleAddASpecie = event => {
        event.preventDefault();
        addSpecie()
    };

    const handleRemoveASpecie = event => {
        event.preventDefault();
        removeSpecie()
    };

    return (
        <div className=''>
            <div className="mt-4 d-flex justify-content-center">
                <button className="btn btn-color" onClick={handleAddASpecie}>
                    Ajouter un animal
                    </button>
            </div>
            {animalNbr > 1 && <div className="mt-4 d-flex justify-content-center">
                <button className="btn btn-color" onClick={handleRemoveASpecie}>
                    Supprimer un animal
                    </button>
            </div>}
        </div>
    );
};

AddSpecieBtn.propTypes = {
    animalNbr: PropTypes.number,
    addSpecie: PropTypes.func,
    removeSpecie: PropTypes.func,
};

export default AddSpecieBtn;
