// == Action types ======================

export const CHANGE_TRIP_VALUE = 'CHANGE_TRIP_VALUE';
export const SUBMIT_TRIP = 'SUBMIT_TRIP';
export const SIGNOUT = 'SIGNOUT';

// == Action creators

export const changeValue = (name, value) => ({
  type: CHANGE_TRIP_VALUE,
  name,
  value: value,
});

export const submitTrip = () => ({
  type: SUBMIT_TRIP,
});

// == Reducer
const initialState = {
  userId: null,
  meridiem: 'evening',
  ammunition: 'balle',
  locationId: 1,
  debriefings: []
};

// == Reducer
function tripReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_TRIP_VALUE:
      console.log(action.name)
      return {
        ...state,
        [action.name]: action.value,
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default tripReducer;
