import { connect } from 'react-redux';

import {changeValue, loadTakingsByTripId } from 'services/ducks/modules/taking';

import Report from 'scenes/Core/Report/TripList/TripList';

const mapStateToProps = (state) => ({
    tripsByIdData: state.dataList.tripsByIdData,
    tripId: state.taking.tripId,
  
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  loadTakingsByTripId: (takings) => {
    dispatch(loadTakingsByTripId(takings));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Report);