// Propriétés des selects
export const selectStyle = theme => ({
    ...theme,
    borderRadius: 5,
    zIndex: 9999,
    height: '2.4rem',
    colors: {
        ...theme.colors,
        text: '#CBA97E',
        primary25: '#CBA97E',
        primary: '#e8caa7',
        primary75: 'white',
        primary50: '#CBA97E',
    },
})

