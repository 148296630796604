import React from "react";
import { useMap, Tooltip, Polygon } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw';
import 'leaflet-draw/dist/leaflet.draw.css';

import PropTypes, { object } from 'prop-types';

const AddAreaPolygons = ({
	restrictedAreasData,
	restrictedAreaId,
	areaName,
	areaActive,
	areaCoordinates,
	addAreaChoice,
	changeValue,
}) => {

	const map = useMap()
	let drawnItems = {}
	let drawControl = {}

	const addPolygonTools = () => {

		var lc = document.getElementsByClassName('leaflet-draw leaflet-control');
		if(lc[0] !== undefined) {
			lc[0].remove();
		}

		drawnItems = new L.FeatureGroup();

		map.addLayer(drawnItems);
		drawControl = new L.Control.Draw({
			edit: {
				featureGroup: drawnItems,
				remove: false,
				edit: false,
			},
			draw:{
				rectangle: false,
				polyline: false,
				circle: false,
				marker: false,
				circlemarker: false,
			},
		});
		map.addControl(drawControl);
		map.on('draw:created', function (e) {
			const { layerType, layer } = e;

				if (layerType === 'polygon') {

					const originalCoordinates = layer.getLatLngs()[0];

					const transformedCoordinates = originalCoordinates.map(pointCoordinate => (
						[
							pointCoordinate.lat,
							pointCoordinate.lng
						])
					)
					changeValue('areaCoordinates', transformedCoordinates)
					L.polygon(transformedCoordinates).addTo(map);
				}
		});
	}

	const modifyPolygonTools = () => {

		var lc = document.getElementsByClassName('leaflet-draw leaflet-control');
		if(lc[0] !== undefined) {
			lc[0].remove();
		}

		var pl = document.getElementsByClassName('modifing-polygon');
		if(pl[0] !== undefined) {
			pl[0].remove();
		}

		var poly = L.polygon(areaCoordinates, {className: 'modifing-polygon', color:areaActive ? "blue" : "grey"}).addTo(map);

		drawnItems = new L.FeatureGroup();
		drawnItems.addLayer(poly);

		map.addLayer(drawnItems);
		drawControl = new L.Control.Draw({
			edit: {
				featureGroup: drawnItems,
				remove: false,
			},
			draw:{
				rectangle: false,
				polygon: false,
				polyline: false,
				circle: false,
				marker: false,
				circlemarker: false,
			},
		});

		
		map.addControl(drawControl);
		map.on('draw:edited', function (e) {
			

					//Récupération des coordonnées et transformation pour envoie dans le store avant sauvegarde
					const originalCoordinates = e.layers.getLayers()[0].getLatLngs()[0];

					const transformedCoordinates = originalCoordinates.map(pointCoordinate => (
						[
							pointCoordinate.lat,
							pointCoordinate.lng
						])
					)
					changeValue('areaCoordinates', transformedCoordinates)
		});
	}

	const removeMapTools = () => {
		var lc = document.getElementsByClassName('leaflet-draw leaflet-control');
		if(lc[0] !== undefined) {
			lc[0].remove();
		}

		var pl = document.getElementsByClassName('modifing-polygon');
		if(pl[0] !== undefined) {
			pl[0].remove();
		}
		
		return true;
	}

	return (
		<>
			{addAreaChoice === 'Consulter' && removeMapTools() && (

				restrictedAreasData.map(restrictedArea => (
					<Polygon key={restrictedArea.name} color={restrictedArea.active ? "blue" : "grey"} positions={restrictedArea.coordinates} label={restrictedArea.name}>
						<Tooltip>{restrictedArea.name}</Tooltip>
					</Polygon>
				))
			)}

			{addAreaChoice === 'Ajouter' && addPolygonTools()}


			{addAreaChoice === 'Modifier' &&  restrictedAreaId !== null && modifyPolygonTools()}

			{addAreaChoice === 'Supprimer' && removeMapTools() && (
				<Polygon key={restrictedAreaId} color={areaActive ? "blue" : 'grey'} positions={areaCoordinates} label={areaName}>
					<Tooltip>{areaName}</Tooltip>
				</Polygon>
			)}
		</>
	);
};

AddAreaPolygons.propTypes = {
	restrictedAreasData: PropTypes.arrayOf(object),
	restrictedAreaId: PropTypes.number,
	areaName: PropTypes.string,
	areaActive: PropTypes.bool,
	areaCoordinates: PropTypes.array,
	addAreaChoice: PropTypes.string,
	changeValue: PropTypes.func,
};

export default AddAreaPolygons;