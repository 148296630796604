import { connect } from 'react-redux';

import {loadUsers, loadBloodHandlers } from 'services/ducks/modules/dataList';
import {changeValue} from 'services/ducks/modules/contact';

import Contacts from 'scenes/Core/Contacts/Contacts';

const mapStateToProps = (state) => ({
  cityId: state.auth.cityId,
  departmentId: state.auth.departmentId,
  userRole: state.auth.role,
  usersData: state.dataList.usersData,
  bloodHandlersData: state.dataList.bloodHandlersData,
  contactId: state.contact.contactId,
  typeId: state.contact.typeId,
  card: state.contact.card,
  usersLoader: state.loader.usersLoader,
  bloodHandlersLoader: state.loader.bloodHandlersLoader,
});

const mapDispatchToProps = (dispatch) => ({

  loadUsers: () => {
    dispatch(loadUsers());
  },
  loadBloodHandlers: () => {
    dispatch(loadBloodHandlers());
  },
  changeValue: (action, value) => {
    dispatch(changeValue(action, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contacts);