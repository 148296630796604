import React, { useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Footer from 'components/Footer/Footer';

import { isAuth } from 'services/security/helpers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Select from 'react-select'
import { selectStyle } from 'services/helpers/select';
import { BallBeat } from 'react-pure-loaders';


const Signup = ({
    departmentsData,
    departmentId,
    citiesData,
    cityId,
    firstname,
    lastname,
    licenseNumber,
    email,
    membershipsData,
    membershipId,
    phone,
    password,
    passwordConfirmation,
    changeValue,
    submitSignup,
    loadMemberships,
    loadDepartments,
    loadCities,
    resetState,
    departmentsLoader,
    citiesLoader,
    membershipsLoader
}) => {

    // Demande la liste des memberships en choissisant une commune
    // Attend le choix d'une commune pour faire une demande
    useEffect(() => {
        loadDepartments();
        resetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (departmentId != null) {
            loadCities();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentId]);

    useEffect(() => {
        if (cityId != null) {
            loadMemberships();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    const btn = useRef();

    // Fonction du champ controlé select
    const handleChangeSelect = name => event => {
        changeValue(name, parseInt(event.value))
        if (btn.current) {
            btn.current.removeAttribute("disabled");
        }

    };

    // Fonction du champ controlé input
    const handleChangeInput = name => event => {
        console.log(event);
        changeValue(name, event.target.value)
        btn.current.removeAttribute("disabled");
    };

    // Fonction pour soummettre le formulaire
    const clickSubmit = event => {
        event.preventDefault();
        btn.current.setAttribute("disabled", true);
        submitSignup();
    };



    const departementSelectOptions = [];

    departmentsData.map(department =>
        departementSelectOptions.push({ value: department.id, label: department.name })
    )

    const citySelectOptions = [];

    citiesData.map(city =>
        citySelectOptions.push({ value: city.id, label: city.name })
    )

    const membershipSelectOptions = [];
    membershipsData.map(membership =>
        membershipSelectOptions.push({ value: membership.id, label: membership.name })
    )

    const signupForm = () => (
        <form>
            <div className="form-container">
                <label className="form-label">Département</label>
                <div className="text-center">
                    < BallBeat loading={departmentsLoader} />
                </div>
                {!departmentsLoader &&
                    <Select
                        options={departementSelectOptions}
                        setValue={departmentId}
                        isSearchable={false}
                        placeholder='Votre département'
                        theme={selectStyle}
                        onChange={handleChangeSelect('departmentId')}
                    />
                }
            </div>

            {departmentId != null && (
                <div className="form-container">
                    <label className="form-label">Commune</label>
                    <div className="text-center">
                        < BallBeat loading={citiesLoader} />
                    </div>
                    {!citiesLoader &&
                        <Select
                            options={citySelectOptions}
                            setValue={cityId}
                            isSearchable={false}
                            placeholder='Votre commune'
                            theme={selectStyle}
                            onChange={handleChangeSelect('cityId')}
                        />
                    }
                </div>
            )}

            {cityId != null && (
                <>
                    <div className="form-container">
                        <label className="form-label">Prénom</label>
                        <input className="form-input" onChange={handleChangeInput('firstname')} value={firstname} type="text" />
                    </div>
                    <div className="form-container">
                        <label className="form-label">Nom</label>
                        <input className="form-input" onChange={handleChangeInput('lastname')} value={lastname} type="text" />
                    </div>
                    <div className="form-container">
                        <label className="form-label">Numéro de permis</label>
                        <input className="form-input" onChange={handleChangeInput('licenseNumber')} value={licenseNumber} type="text" />
                    </div>

                    <div className="form-container">
                        <label className="form-label">Email</label>
                        <input className="form-input" onChange={handleChangeInput('email')} value={email} type="email" />
                    </div>
                    <div className="form-container">
                        <label className="form-label">Type de membre</label>
                        <div className="text-center">
                        < BallBeat loading={membershipsLoader} />
                    </div>
                    {!membershipsLoader &&
                        <Select
                            options={membershipSelectOptions}
                            setValue={membershipId}
                            isSearchable={false}
                            placeholder='Type de membre'
                            theme={selectStyle}
                            onChange={handleChangeSelect('membershipId')}
                        />
                    }
                    </div>

                    <div className="form-container">
                        <label className="form-label">Téléphone</label>
                        <input className="form-input" onChange={handleChangeInput('phone')} value={phone} type="tel" />
                    </div>

                    <div className="form-container">
                        <label className="form-label">Mot de passe</label>
                        <input className="form-input" onChange={handleChangeInput('password')} value={password} type="password" />
                    </div>
                    <div className="form-container">
                        <label className="form-label">Confirmer le mot de passe</label>
                        <input className="form-input" onChange={handleChangeInput('passwordConfirmation')} value={passwordConfirmation} type="password" />
                    </div>

                    <div>
                        <button ref={btn} className="btn-primary" onClick={clickSubmit}>
                            Valider
                        </button>
                    </div>
                </>
            )}
        </form>
    );

    return (
        <>
            <div className="signin-container row justify-content-between">
                <div className="signin-title-container column col-md-4">
                    <Link to="/signin" >
                        <h1 className="title">Chasse Connect</h1>
                    </Link>
                </div>
                <div className="col-md-3 align-self-center align-item-center">
                    <div>
                        <ToastContainer autoClose={3000} />
                        {isAuth() ? <Redirect to="/" /> : null}
                        <h3 className="p-2 text-center">Ajouter un compte</h3>
                        {signupForm()}
                    </div>
                    <div className="linebreak col-md-1 align-self-center">

                    </div>

                    <Link to="/signin" className="forgotten-password col-md-2 align-self-center">
                        <button className="btn-secondary">
                            Se connecter
                    </button>
                    </Link>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default Signup;
