import React from 'react';
import { Page, Text, View, Image, Font, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import exo from 'services/assets/fonts/Exo-VariableFont_wght.ttf'
import exoBold from 'services/assets/fonts/static/Exo-SemiBold.ttf'
import exoItalic from 'services/assets/fonts/static/Exo-Italic.ttf'

import PropTypes from 'prop-types';

// Create styles
Font.register(
    {
        family: 'Exo',
        src: exo
    }
);
Font.register(
    {
        family: 'Exo-bold',
        src: exoBold
    }
);
Font.register(
    {
        family: 'Exo-Italic',
        src: exoItalic
    }
);
Font.register(
    {
        family: 'Caveat',
        src: '/fonts/Caveat-Regular.ttf'
    }
);

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
    },
    title: {
        fontFamily: 'Exo-bold',
        fontWeight: 'bold',
        color: '#6a470f',
        fontSize: 30,
        // flexDirection: 'column',
        backgroundColor: 'white'
    },
    accaTitle: {
        fontFamily: 'Exo-Italic',
        // fontWeight: 'bold',
        color: '#6a470f',
        fontSize: 15,
        // flexDirection: 'column',
        backgroundColor: 'white'
    },
    section: {
        margin: 10,
        padding: 10,
        // flexGrow: 1
    },
    sectionReverse: {
        flexDirection: 'row',
        margin: 10,
        padding: 10,
        // flexGrow: 1
    },
    sectionReportTitle: {
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Exo',
        fontSize: 18,
        margin: 10,
        padding: 10,
        // flexGrow: 1
    },
    pdf: {
        width: '100vw',
        height: '100vh'
    },
    logoDepartment: {
        width: 50,
        height: 50
    },
    logoChasseConnect: {
        // margin: 'auto',
        marginLeft: 80,
        width: 150,
        height: 150
    },
    row: {
        flexDirection: 'row',
    },
    headerText: {

    },
    cell: {
        padding: 5,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        margin: 0,
        justifyContent: 'center',
    },
    table: { width: "96%", margin: "0 auto", marginLeft: "auto", marginRight: "auto", borderStyle: "solid", fontFamily: 'Exo', borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol: { width: "11%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 2, justifyContent: 'center' },
    tableColEmpty: { width: "100%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 2, justifyContent: 'center' },
    tableCol2: { width: "7%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 2, justifyContent: 'center' },
    tableCol3: { width: "13%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 2, justifyContent: 'center' },
    tableCol4: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 2, justifyContent: 'center' },
    tableCol5: { width: "9%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 2, justifyContent: 'center' },
    tableCol6: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 2, flexDirection: 'row', justifyContent: 'center' },

    tableColSanglier: { width: "28%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, flexDirection: 'column', justifyContent: 'center' },

    tableColSanglierTitleCont: { width: "100%", height: 20, borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0, justifyContent: 'center' },

    tableColSanglierDetailsCont: { width: "100%", height: 20, borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, flexDirection: 'row', justifyContent: 'center' },

    tableColSanglierDetails: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, flexDirection: 'row', textAlign: 'center', alignItems: 'center' },

    tableColSanglierSex: { width: "7%", minHeight:6, borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center' },

    tableColSanglierSexItem: { width: '100%',  borderStyle: "solid", borderWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0, flexDirection: 'row', textAlign: 'center', alignItems: 'center', flexGrow: 1 },

    tableColRenard: { width: "14%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, flexDirection: 'column', justifyContent: 'center' },

    tableColRenardTitleCont: { width: "100%", height: 20, borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0, justifyContent: 'center' },

    tableColRenardDetailsCont: { width: "100%", height: 20, borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, flexDirection: 'row', justifyContent: 'center' },

    tableColRenardDetails: { width: "50%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, flexDirection: 'row', textAlign: 'center', alignItems: 'center' },

    tableColLastDetails: { borderWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0, borderBottomWidth: 0 },

    tableCell: { fontSize: 10, textAlign: "center" },
    tableCell2: { fontSize: 10, padding: 2, textAlign: "center" },

    sectionResume: { margin: '40px auto', fontFamily: 'Exo', alignItems: 'center' },
    resumeTitle: { fontSize: 20, marginBottom: 20 },
    resumeElement: { fontSize: 13 }
});

const PdfGenerator = ({
    adminReportsData,
    city,
    userId,
    startingDate,
    endingDate
}) => {
console.log(startingDate)
console.log(endingDate)
    const totalKills = (specie) => {
        let i = 0;
        adminReportsData.map(trip => {
            if (trip.takings !== null) {
                trip.takings.map(taking => {
                    console.log(specie)
                    if (taking.specie.name === specie) {
                        i = i+1;
                    }
                    return false;
                })
            }
            return false;
        })
        return i;
    }

    const totalObs = (specie) => {
        let i = 0;
        adminReportsData.map(trip => {
            if (trip.debriefing !== null) {
                if (trip.debriefing[specie] !== null) {
                    i = i + trip.debriefing[specie];
                }
                return false;
            }
            return false;
        })
        return i;
    }
    return (
        <div style={styles.pdf}>
            <PDFViewer width='100%' height='100%!important'>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.sectionReverse}>

                            <View style={styles.section}>
                                <Text style={styles.title}>Chasse Connect</Text>
                                <Text style={styles.accaTitle}>ACCA de {city}</Text>
                                <Image style={styles.logoDepartment} src={`../../assets/img/Landes.png`} />
                            </View>
                            <View style={styles.section}>
                                <Image style={styles.logoChasseConnect} src={`../../assets/img/deer-icon5.png`} />
                                {/* <Logo /> */}
                            </View>
                        </View>
                        <View style={styles.sectionReportTitle}>
                            <Text>{`Bilan des sorties${userId !== 0 && adminReportsData.length !== 0 ? ' de ' + adminReportsData[0].user.firstname + ' ' + adminReportsData[0].user.lastname : ''}`}</Text>
                            <Text>du {moment(startingDate).format('DD/MM/YYYY')} au {moment(endingDate).format('DD/MM/YYYY')}</Text>
                        </View>

                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Date</Text>
                                </View>
                                <View style={styles.tableCol3}>
                                    <Text style={styles.tableCell}>Tireur</Text>
                                </View>
                                <View style={styles.tableCol5}>
                                    <Text style={styles.tableCell}>Période</Text>
                                    <Text style={styles.tableCell}>Munition</Text>
                                </View>

                                <View style={styles.tableColSanglier}>
                                    <View style={styles.tableColSanglierTitleCont}>
                                        <Text style={styles.tableCell}>Sanglier</Text>
                                    </View>
                                    <View style={styles.tableColSanglierDetailsCont}>
                                        <View style={styles.tableColSanglierDetails}>
                                            <Text style={styles.tableCell}>Vu(s)</Text>
                                        </View>
                                        <View style={styles.tableColSanglierDetails}>
                                            <Text style={styles.tableCell}>Tué(s)</Text>
                                        </View>
                                        <View style={styles.tableColSanglierDetails}>
                                            <Text style={styles.tableCell}>Sexe</Text>
                                        </View>
                                        <View style={[styles.tableColSanglierDetails, styles.tableColLastDetails]}>
                                            <Text style={styles.tableCell}>Poids</Text>
                                        </View>
                                    </View>

                                </View>
                                <View style={styles.tableColRenard}>

                                    <View style={styles.tableColSanglierTitleCont}>
                                        <Text style={styles.tableCell}>Renard</Text>
                                    </View>
                                    <View style={styles.tableColRenardDetailsCont}>
                                        <View style={styles.tableColRenardDetails}>
                                            <Text style={styles.tableCell}>Vu(s)</Text>
                                        </View>
                                        <View style={[styles.tableColRenardDetails, styles.tableColLastDetails]}>
                                            <Text style={styles.tableCell}>Tué(s)</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableCol4}>
                                    <Text style={styles.tableCell}>Détails</Text>
                                </View>
                            </View>
                            {adminReportsData.length === 0 &&
                                <View style={styles.tableColEmpty}>
                                    <Text style={styles.tableCell}>Aucune sortie</Text>
                                </View>
                            }
                            {adminReportsData.map((currentValue) => {

                                let boarNbr = null
                                currentValue.takings.map((taking => {
                                    console.log()
                                    if (taking.specie.name === 'Sanglier') { boarNbr++ }
                                    return false;
                                }))

                                let foxNbr = null
                                currentValue.takings.map((taking => {
                                    console.log()
                                    if (taking.specie.name === 'Renard') { foxNbr++ }
                                    return false;
                                }))
                                return (
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{moment(currentValue.date).format('DD/MM/YYYY')}</Text>
                                        </View>
                                        <View style={styles.tableCol3}>
                                            <Text style={styles.tableCell}>{currentValue.user !== null && currentValue.user.firstname}</Text>
                                            <Text style={styles.tableCell2}>{currentValue.user !== null && currentValue.user.lastname}</Text>
                                        </View>
                                        <View style={styles.tableCol5}>
                                            <Text style={styles.tableCell}>{currentValue.meridiem === 'evening' ? 'Soir' : 'Matin'}</Text>
                                            <Text style={styles.tableCell}>{currentValue.ammunition}</Text>
                                        </View>
                                        <View style={styles.tableCol2}>
                                            <Text style={styles.tableCell}>{currentValue.debriefing !== null && currentValue.debriefing.foxObs !== 0 && currentValue.debriefing.boarObs}</Text>
                                        </View>
                                        <View style={styles.tableCol2}>
                                            <Text style={styles.tableCell}>{boarNbr}</Text>
                                        </View>
                                        <View style={styles.tableColSanglierSex}>
                                            {currentValue.takings.map((taking => {
                                                if (taking.specie.name === 'Sanglier') { 
                                                return <View style={styles.tableColSanglierSexItem}>
                                                    <Text style={styles.tableCell}>{taking.sex}</Text>
                                                </View>;
                                                }
                                                return false;
                                            }))}
                                        </View>
                                        <View style={styles.tableColSanglierSex}>
                                            {currentValue.takings.map((taking => {
                                                if (taking.specie.name === 'Sanglier') { 
                                                return <View style={styles.tableColSanglierSexItem}>
                                                    <Text style={styles.tableCell}>{taking.estimalWeight}</Text>
                                                </View>;
                                                }
                                                return false;
                                            }))}
                                        </View>
                                        <View style={styles.tableCol2}>
                                            <Text style={styles.tableCell}>{currentValue.debriefing !== null && currentValue.debriefing.foxObs !== 0 && currentValue.debriefing.foxObs}</Text>
                                        </View>
                                        <View style={styles.tableCol2}>
                                            <Text style={styles.tableCell}>{foxNbr}</Text>
                                        </View>
                                        <View style={styles.tableCol4}>
                                            <Text style={styles.tableCell}>{`Lieu-dit: ${currentValue.location.name !== null ? currentValue.location.name : 'aucune'}`}</Text>
                                            <Text style={styles.tableCell}>{`Météo: ${currentValue.conditionKey !== null ? currentValue.conditionKey : 'aucune'}`}</Text>
                                            <Text style={styles.tableCell}>{`Vent: ${currentValue.windSpeed !== null ? currentValue.windSpeed + ' km/h' : ''} ${currentValue.dirWind !== null ? currentValue.dirWind + '°' : 'aucune'} `}</Text>
                                            <Text style={styles.tableCell}>{`Commentaires:`}</Text>
                                            <Text style={styles.tableCell}>{`${currentValue.debriefing !== null ? '" ' + currentValue.debriefing.comment + ' "' : 'aucun'}`}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                        <View style={styles.sectionResume}>
                            <Text style={styles.resumeTitle}> Synthèse</Text>
                            <Text style={styles.resumeElement}> {`Nombre de sortie${adminReportsData.length !== 0 ? 's' : ''}: ${adminReportsData.length}`}</Text>
                            <Text style={styles.resumeElement}> {`Sanglier${totalObs('boarObs') !== 0 ? 's' : ''} vu${totalObs('boarObs') !== 0 ? 's' : ''}: ${totalObs('boarObs')}`}</Text>
                            <Text style={styles.resumeElement}> {`Sanglier${totalKills('Sanglier') !== 0 ? 's' : ''} tué${totalKills('Sanglier') !== 0 ? 's' : ''}: ${totalKills('Sanglier')}`}</Text>
                            <Text style={styles.resumeElement}> {`Renard${totalObs('foxObs') !== 0 ? 's' : ''} vu${totalObs('foxObs') !== 0 ? 's' : ''}: ${totalObs('foxObs')}`}</Text>
                            <Text style={styles.resumeElement}> {`Renard${totalKills('Renard') !== 0 ? 's' : ''} tué${totalKills('Renard') !== 0 ? 's' : ''}: ${totalKills('Renard')}`}</Text>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
};

PdfGenerator.propTypes = {
    adminReportsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    city: PropTypes.string,
    userId: PropTypes.number
};

export default PdfGenerator;