import { connect } from 'react-redux';

import { loadTrips, loadWeather } from 'services/ducks/modules/dataList';
import { getClientVersion, registerIdConnection } from 'services/ducks/modules/auth';

import Home from 'scenes/Core/Home/Home';

const mapStateToProps = (state) => ({
    cityId: state.auth.cityId,
    tripsData: state.dataList.tripsData,
    weatherData: state.dataList.weatherData,
});

const mapDispatchToProps = (dispatch) => ({
  registerIdConnection: () => {
    dispatch(registerIdConnection());
  },
  loadTrips: () => {
    dispatch(loadTrips());
  },
  loadWeather: () => {
    dispatch(loadWeather());
  },
  getClientVersion: () => {
    dispatch(getClientVersion());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);