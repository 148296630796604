import React from 'react';

import 'react-toastify/dist/ReactToastify.min.css';

import { getTheDay, getTheMonth, formatHour } from 'services/helpers/date';
import Fade from 'react-reveal/Pulse';
// Chargement du helper meteo
import { weatherIcons, windDirection } from 'services/helpers/weatherIcons';
import sunrise from 'services/assets/icons/sunrise.png'
import sunset from 'services/assets/icons/sunset.png'

import PropTypes from 'prop-types';

import { BallBeat } from 'react-pure-loaders';


const CarrouselItem = ({
    title,
    theDay,
    theRise,
    theRiseHour,
    weatherLoader,
    tripsLoader,
    settingsLoader,
    weatherData,
    trips,
    huntLimit
}) => {

    let itm = 80;

    return (
        <div className='caroussel-item'>

            <div className="caroussel-item--period">
                <h2>{title}</h2>
            </div>
            <div className="caroussel-item--block-date">
                <div className="caroussel-item--date">
                    {getTheDay(theDay)} {theDay.getDate()} {getTheMonth(theDay)} {theDay.getFullYear()}
                </div>
                <div className="caroussel-item--rise">
                    <img src={theRise === 'sunset' ? sunset : sunrise} className="rise-icon" alt="sunrise-icon" />
                    {theRiseHour}
                </div>
            </div>

            <div className="text-center">
                < BallBeat loading={weatherLoader} />
            </div>
            {!weatherLoader &&
                <div className="caroussel-item--weather">
                    <div className="caroussel-item--weather---forecast-title">
                        {weatherData !== undefined && `Prévisons à ${formatHour(weatherData.hour)}`}
                    </div>
                    <div className="caroussel-item--weather---forecast">
                        {weatherData !== undefined && weatherIcons[weatherData.conditionKey]}
                        {weatherData !== undefined && ' ' + weatherData.rain + 'mm '}

                        {weatherData !== undefined && windDirection(weatherData.dirWind)}
                        {weatherData !== undefined && weatherData.windSpeed + ' km/h'}
                    </div>
                </div >
            }

            <div className="text-center">
                < BallBeat loading={tripsLoader} />
            </div>
            {!tripsLoader &&
                <div className="caroussel-item--block-trips">
                    {trips.map(trip => {
                        itm = itm + 80
                        if (trip.state === 1) {
                            return (
                                <Fade key={trip.id + trip.date} duration={500} delay={itm}>
                                    <div className="caroussel-item--trips">
                                        <span>{trip.user.firstname} {trip.user.lastname}</span> chassera à <span>{trip.ammunition}</span> au lieu-dit <span>{trip.location.name}</span>
                                    </div>
                                </Fade>
                            )
                        }
                        return null;

                    })}
                    {trips.length === 0 &&
                        <div className="caroussel-item--trips">
                            Aucune sortie de prévue
                    </div>
                    }
                </div>
            }

            <div className="text-center">
                < BallBeat loading={settingsLoader} />
            </div>
            {huntLimit !== '' && huntLimit !== null && huntLimit !== undefined &&

                <div className="caroussel-item--ends">
                    La chasse {theRise === 'sunset' ? 'se termine' : 'commence'} à {huntLimit}
                </div>
            }
        </div>

    );
};

CarrouselItem.propTypes = {
    title: PropTypes.string,
    theDay: PropTypes.instanceOf(Date),
    theRise: PropTypes.string,
    theRiseHour: PropTypes.string,
    loaders: PropTypes.object,
    weatherData: PropTypes.object,
    trips: PropTypes.object,
    huntLimit: PropTypes.string
};

export default CarrouselItem;
