import React, { useEffect } from 'react';

import Layout from 'components/Layout/LayoutContainer';

import Fade from 'react-reveal/Zoom';

import moment from 'moment';
import localization from 'moment/locale/fr';

import 'react-toastify/dist/ReactToastify.min.css';
import { BallBeat } from 'react-pure-loaders';

import PropTypes from 'prop-types';

const News = ({
    takingsData,
    newsData,

    cityId,
    loadTakings,
    loadNews,
    takingsLoader,
    newsLoader
}) => {


    moment.updateLocale('fr', localization)

    useEffect(() => {
        loadTakings();
        loadNews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    const newsBlock = () => (
        <Fade clear duration={500} delay={0}>
            <div className="col-md-6 news-form-container">
                <h3 className="text-center">News</h3>
                <div className="news-container">
                    {newsData.map(currentNews =>
                        <div key={currentNews.date + currentNews.title} className="news-post">
                            <div className="news-header">
                            <p className="news-date">{moment(currentNews.date).format('Do MMMM YYYY')}</p>
                            <h5 className="news-title">{currentNews.title}</h5>
                            </div>
                            
                            <p className="news-content">{`"${currentNews.content}"`}</p>
                        </div>
                    )}
                    {newsData.length === 0 && 
                     <div className="text-center">Aucune news</div>
                    }

                </div>
            </div>
        </Fade>
    )

    const takingsBlock = () => (
        <Fade clear duration={500} delay={250}>
            <div className="col-md-6 takings-report-form-container">
                <h3 className="text-center">Derniers prélevements</h3>
                <div className="takings-report-container">
                    {takingsData.map((taking) =>
                    <>
                        <div key={taking.id + taking.trip.user.firstname} className="takings-report-post">
                            <div className="takings-report-title">
                                <div className="takings-report-date">{moment(taking.trip.date).format('Do MMMM YYYY')}</div>
                                {/* <a href="{taking.photo}">Voir photo</a> */}
                            </div>
                            <p className="takings-report-content">{`${taking.trip.user.firstname} ${taking.trip.user.lastname} a prélevé ${taking.animalCount === 1 ? 'un' : taking.animalCount} ${taking.specie.name}${taking.animalCount === 1 ? '' : 's'}`}</p>
                        </div>
                        <div className="news-linebreak"></div>
                        </>
                    )}
                    {takingsData.length === 0 && 
                     <div className="text-center">Aucun prélevement</div>
                    }
                </div>
            </div>
        </Fade>
    )

    return (
        <Layout>
            <div className="container-fluid d-flex flex-column">
                <h1 className="text-center">Actualités</h1>
                <div className="loader">
                    < BallBeat margin={'auto'} loading={takingsLoader || newsLoader ? true : false} />
                </div>
                <div className="news row">
                    {!takingsLoader && !newsLoader && takingsBlock()}
                    {!takingsLoader && !newsLoader && newsBlock()}
                </div>
            </div>
        </Layout>
    );

};

News.propTypes = {
    takingsData: PropTypes.array,
    newsData: PropTypes.array,
    cityId: PropTypes.number,
    loadTakings: PropTypes.func,
    loadNews: PropTypes.func,
    takingsLoader: PropTypes.bool,
    newsLoader: PropTypes.bool,
};

export default News;
