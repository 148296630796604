import React, { Fragment, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { pushRotate as Menu } from 'react-burger-menu'
import { Link, withRouter } from 'react-router-dom';
import { isAuth } from 'services/security/helpers';
import Footer from 'components/Footer/Footer';
import NotificationIcon from 'components/Layout/NotificationIcon/NotificationIconContainer';

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from 'prop-types';


const Layout = ({
    children,
    profileLoaded,
    match,
    history,
    signout,
    city,
    department,
    roleId,
    loadProfile,
    loadSettings,
    limitRegistration,
    settingsData,
    notification,
    socketIoConnectedState
}) => {

    // const [socketIoConnectedState, setSocketIoConnectedState] = useState(true)

    const isActive = path => {
        if (match.path === path) {
            return { color: '#edcda6', fontWeight: 'bold' };
        } else {
            return { color: '#fff' };
        }
    };

    useEffect(() => {

        isAuth() && loadProfile() && console.log('le profile se charge apès l\'authentification');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // On charge les settings si ils ne sont pas dans le store
    useEffect(() => {
        profileLoaded && loadSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileLoaded || settingsData === []]);

    const nav = () => (
        <Menu right >
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link to="/" className="nav-link" style={isActive('/')}>
                        Accueil
                    </Link>
                </li>

                {!isAuth() && (
                    <>
                        <li className="nav-item">
                            <Link to="/signin" className="nav-link" style={isActive('/signin')}>
                                Connexion
                        </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/signup" className="nav-link" style={isActive('/signup')}>
                                S'inscrire
                        </Link>
                        </li>
                    </>
                )}
                {isAuth() && (<>

                    <li className="nav-item">
                        <Link to="/add" className="nav-link" style={isActive('/add')}>
                            Ajouter une sortie
                        </Link>
                    </li>
                    {limitRegistration && roleId !== 3 &&
                        <li className="nav-item">
                            <Link to="/modify" className="nav-link" style={isActive('/modify')}>
                                Gérer les sorties
                            </Link>
                        </li>
                    }
                    {!limitRegistration &&
                        <li className="nav-item">
                            <Link to="/modify" className="nav-link" style={isActive('/modify')}>
                                Modifier une sortie
                            </Link>
                        </li>
                    }


                    <li className="nav-item">
                        <Link to="/report" className="nav-link" style={isActive('/report')}>
                            Déclarer une prise
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/debriefing" className="nav-link" style={isActive('/debriefing')}>
                            Comptes-rendus
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/news" className="nav-link" style={isActive('/news')}>
                            Actualités
                        </Link>
                    </li>
                    {isAuth() && (roleId === 1 || roleId === 2 || roleId === 4) && (
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive('/admin')} to="/admin">
                                Paramêtres
                        </Link>
                        </li>
                    )}

                    {isAuth() && isAuth().role.name === 'User' && (
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive('/private')} to="/private">
                                Mon profil
                        </Link>
                        </li>
                    )}
                    <li className="nav-item">
                        <Link to="/contacts" className="nav-link" style={isActive('/contacts')}>
                            Contacts
                        </Link>
                    </li>
                    <li className="nav-item">
                        <span
                            className="nav-link"
                            style={{ cursor: 'pointer', color: '#fff' }}
                            onClick={() => {
                                signout(history);
                            }}
                        >
                            Se déconnecter
                    </span>
                    </li>
                </>
                )
                }
            </ul>
        </ Menu>
    );

    return (
        <Fragment>
            {!isAuth() && (<Redirect to="/signin" />)}
            <div className="bg-container">
                {nav()}
                <div className="header">
                    <Link to="/" >
                        <h1 className="header-title">
                            Chasse Connect
                        </h1>
                    </Link>

                    <h4 className="header-city">{city}</h4>
                    {!socketIoConnectedState && <FontAwesomeIcon className="connected-icon" color='#fc625a' icon={faWifi} />}
                    {notification !== 0 && <NotificationIcon />}
                    <Link to="/add" >
                        <FontAwesomeIcon className="btn-add" color='#fc625a' icon={faPlusCircle} size="2x" />
                    </Link>
                    <h4 className="header-name">Bienvenue {isAuth().firstname}</h4>

                    <img
                        className="header-logo"
                        alt='departement-logo'
                        src={`../../assets/img/${department}.png`}
                    />
                </div>
                {children}
                <Footer />
            </div>

        </Fragment>
    );
};

Layout.propTypes = {

    signout: PropTypes.func,
    city: PropTypes.string,
    department: PropTypes.string,
    loadProfile: PropTypes.func,
    notification: PropTypes.bool
};

export default withRouter(Layout);
