import { connect } from 'react-redux';

import {changeValue, submitSignin, resetState } from 'services/ducks/modules/auth';
import { loadDepartments, loadCities } from 'services/ducks/modules/dataList';

import Signin from 'scenes/Auth/Signin/Signin';

const mapStateToProps = (state) => ({
  departmentsData: state.dataList.departmentsData,
  departmentId: state.auth.departmentId,
  citiesData: state.dataList.citiesData,
  cityId: state.auth.cityId,
  email: state.auth.email,
  password: state.auth.password,
  buttonText: state.auth.buttonText,
  departmentsLoader: state.loader.departmentsLoader,
  citiesLoader: state.loader.citiesLoader
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
submitSignin: (history) => {
    dispatch(submitSignin(history));
  },
  loadDepartments: (history) => {
    dispatch(loadDepartments(history));
  },
  loadCities: (history) => {
    dispatch(loadCities(history));
  },
  resetState: (history) => {
    dispatch(resetState(history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Signin);