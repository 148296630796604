import { connect } from 'react-redux';

import { saveTakings } from 'services/ducks/modules/taking';
import { saveTakingsInStore } from 'services/ducks/modules/dataList';

import AddSpecieBtn from 'scenes/Core/Report/SubmitBtn/SubmitBtn';

const mapStateToProps = (state) => ({
  tripId: state.taking.tripId,
  submitBtnText: state.taking.submitBtnText,
  takings: state.taking.takings
});

const mapDispatchToProps = (dispatch) => ({
  saveTakings: () => {
    dispatch(saveTakings());
  },
  saveTakingsInStore: (tripId,takings) => {
    dispatch(saveTakingsInStore(tripId,takings));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddSpecieBtn);