import { connect } from 'react-redux';

import PdfGenerator from 'components/PdfGenerator/PdfGenerator';

const mapStateToProps = (state, ownProps) => {console.log(state.trip.locationId); return ({
  adminReportsData: state.dataList.adminReportsData,
  startingDate: state.adminReport.startingDate,
  endingDate: state.adminReport.endingDate,
  city: state.auth.city,
  userId: state.adminReport.userId,
})};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PdfGenerator);