import React from 'react';

import { Link } from 'react-router-dom';

import Layout from 'components/Layout/LayoutContainer';

const Admin = ({role}) => {
console.log(role);
    return (
        <Layout>
            <div className="container-fluid flex-grow-2">
                <h2 className="pt-5 text-center">Paramêtres administrateur</h2>
                <div className="settings-btn-container">
                   
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 d-flex justify-content-center">
                                <Link to="/private" >
                                    <button className="btn btn-primary btn-settings" >
                                        Modifier mon profil
                                    </button>
                                </Link>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center">
                                <Link to="/administrate" >
                                    <button className="btn btn-primary btn-settings">
                                        Administrer un compte
                                    </button>
                                </Link>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center">
                                <Link to="/add-location" >
                                    <button className="btn btn-primary btn-settings">
                                        Gestion des lieu-dits
                                    </button>
                                </Link>
                            </div>

                            <div className="col-md-6 d-flex justify-content-center">
                                <Link to="/add-area" >
                                    <button className="btn btn-primary btn-settings">
                                        Gestion des zones de restriction
                                    </button>
                                </Link>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center">
                                <Link to="/add/news" >
                                    <button className="btn btn-primary btn-settings">
                                        Gestion des news
                                    </button>
                                </Link>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center">
                                <Link to="/settings" >
                                    <button className="btn btn-primary btn-settings">
                                        Paramêtres
                                    </button>
                                </Link>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center">
                                <Link to="/admin/report" >
                                    <button className="btn btn-primary btn-settings">
                                        Bilans admin
                                    </button>
                                </Link>
                            </div>
                            {role && role === 'SuperAdmin' &&
                            <div className="col-md-6 d-flex justify-content-center">
                                <Link to="/admin/connection-report" >
                                    <button className="btn btn-primary btn-settings">
                                        Rapport de connexion
                                    </button>
                                </Link>
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        </Layout >
    );
};

export default Admin;
