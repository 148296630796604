import { connect } from 'react-redux';

import { changeValue, saveNewLocation, updateLocation } from 'services/ducks/modules/admin';
import AddLocationForm from 'scenes/Admin/AddLocation/AddLocationForm/AddLocationForm';

const mapStateToProps = (state) => ({
    cityLat: state.map.cityLat,
    cityLng: state.map.cityLng,
    addLocationChoice: state.admin.addLocationChoice,

    newNameLocation: state.admin.newNameLocation,
    locationsData: state.dataList.locationsData,
    selectedLocationId: state.admin.selectedLocationId,
    selectedNameLocation: state.admin.selectedNameLocation,
    locationActive: state.admin.locationActive,
});

const mapDispatchToProps = (dispatch) => ({
  saveNewLocation: () => {
    dispatch(saveNewLocation());
  },
  updateLocation: () => {
    dispatch(updateLocation());
  },
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddLocationForm);