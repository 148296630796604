// == Action types ======================

export const DISABLE_LOADER = 'DISABLE_LOADER';
export const ENABLE_LOADER = 'ENABLE_LOADER';
export const SIGNOUT = 'SIGNOUT';

// == Action creators

export const disableLoader = (loader) => ({
  type: DISABLE_LOADER,
  loader,
});

export const enableLoader = (loader) => ({
  type: ENABLE_LOADER,
  loader,
});

// == Reducer
const initialState = {
  membershipsLoader: true,
  departmentsLoader: true,
  citiesLoader: true,
  usersLoader: true,
  bloodHandlersLoader: true,
  locationsLoader: true,
  tripsLoader: true,
  tripByIdLoader: true,
  speciesLoader: true,
  restrictedAreasLoader: true,
  weatherLoader: true,
  rolesLoader: true,
  profileLoader: true,
  manageableUserProfileLoader: true,
  settingsLoader: true,
  debriefingsLoader: true,
  takingsLoader: true,
  newsLoader: true,
  pdfLoader: false,
  connectionRecordLoader: true
};

function loaderReducer(state = initialState, action = {}) {

  switch (action.type) {

    case ENABLE_LOADER:
      console.log(action.name)
      return {
        ...state,
        [action.loader]: true
      };

    case DISABLE_LOADER:
      console.log(action.name)
      return {
        ...state,
        [action.loader]: false
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default loaderReducer;
