import { connect } from 'react-redux';

import { loadNews, loadTakings } from 'services/ducks/modules/dataList';

import News from 'scenes/Core/News/News';

const mapStateToProps = (state) => ({
  cityId: state.auth.cityId,
  takingsData: state.dataList.takingsData,
  newsData: state.dataList.newsData,
  takingsLoader: state.loader.takingsLoader,
  newsLoader: state.loader.newsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  loadNews: () => {
    dispatch(loadNews());
  },
  loadTakings: () => {
    dispatch(loadTakings());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(News);