import React, { useState, useEffect, Fragment } from 'react';
import Layout from 'components/Layout/LayoutContainer';

import moment from 'moment';
import localization from 'moment/locale/fr';
import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';

import Fade from 'react-reveal/Zoom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BallBeat } from 'react-pure-loaders';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sanglier from 'services/assets/icons/sanglier.png'
import renard from 'services/assets/icons/renard.png'

import PropTypes from 'prop-types';

const Debriefing = ({
    tripId,
    tripsByIdData,
    comment,
    cityId,
    roleId,
    debriefingsData,
    boarObs,
    foxObs,

    loadTripsById,
    loadDebriefings,
    changeValue,
    saveDebriefing,
    saveCheckedDebriefings,

    tripsByIdLoader,
    debriefingsLoader
}) => {

    const [selectedType, setSelectedType] = useState('read');

    moment.updateLocale('fr', localization)

    //TODO socketio
    useEffect(() => {
        loadTripsById();
        // loadDebriefings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    // On charge les debriefings si ils ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadDebriefings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || debriefingsData === []]);

    useEffect(() => {
        if (roleId === 3) {
            setSelectedType('write')
        }
    }, [roleId]);


    /**
     * HANDLES
     */
    const handleChangeInput = name => event => {
        changeValue(name, parseInt(event.target.value));
    }

    const handleChangeDebriefingType = (event) => {
        setSelectedType(event.value);
        changeValue('tripId', null);
        changeValue('boarObs', null);
        changeValue('foxObs', null);
        changeValue('comment', '');
    }

    const handleChangeDebriefingTrip = (event) => {
        changeValue('tripId', event.value);
        const selectedDebriefing = debriefingsData.find(debriefing => debriefing.tripId === event.value)
        console.log(selectedDebriefing)
        if (selectedDebriefing) {
            changeValue('comment', selectedDebriefing.comment);
            changeValue('boarObs', selectedDebriefing.boarObs);
            changeValue('foxObs', selectedDebriefing.foxObs);
        } else {
            changeValue('comment', '');
            changeValue('boarObs', null);
            changeValue('foxObs', null);
        }
    }

    const handleChangeDebriefingTripConsult = (event) => {
        changeValue('tripId', event.value);
        saveCheckedDebriefings(event.value);
        const selectedDebriefing = debriefingsData.find(debriefing => debriefing.tripId === event.value)
        console.log(selectedDebriefing)
        if (selectedDebriefing) {
            changeValue('comment', selectedDebriefing.comment);
            changeValue('boarObs', selectedDebriefing.boarObs);
            changeValue('foxObs', selectedDebriefing.foxObs);
            // changeValue('comment', selectedTrip.debriefing.comment && selectedTrip.debriefing.comment != null);
        } else {
            changeValue('comment', '');
            changeValue('boarObs', null);
            changeValue('foxObs', null);
        }
    }

    const handleSaveDebriefing = (event) => {
        event.preventDefault();
        saveDebriefing();
    }

    /**
     * ARRAYS SELECT
     */
    const tripsByIdSelect = () => {

        const selectTripsChoice = [];
        tripsByIdData.map((currentTrip) => {
            if (currentTrip.state === 1) {
                let currentMeridiem = 'soir';
                if (currentTrip.meridiem === "morning") {
                    currentMeridiem = 'matin';
                }
                selectTripsChoice.push({
                    value: currentTrip.id, label:
                        <Fragment>
                            {moment(currentTrip.date).format('Do MMMM YYYY')}{' '}
                            {currentMeridiem}{' ('}
                            {currentTrip.location.name}{') '}
                            {(currentTrip.debriefing !== null && currentTrip.debriefing.comment != null && currentTrip.debriefing !== '') ? <FontAwesomeIcon color='#66afff' icon={faEye} /> : ''}
                            {currentTrip.debriefing !== null && currentTrip.debriefing.boarObs !== null && currentTrip.debriefing.boarObs !== 0 && <img src={sanglier} className="deb-icon" alt="sanglier-icon" />}
                            {currentTrip.debriefing !== null && currentTrip.debriefing.foxObs !== null && currentTrip.debriefing.foxObs !== 0 && <img src={renard} className="deb-icon" alt="renard-icon" />}
                        </Fragment>

                })
                return false;
            }
            return false;

        })
        return selectTripsChoice;
    }

    const debriefingsSelect = () => {

        const selectTripsChoice = [];
        debriefingsData.map((currentDebriefing) => {
            let currentMeridiem = 'le soir';
            if (currentDebriefing.trip.meridiem === "morning") {
                currentMeridiem = 'le matin';
            }
            selectTripsChoice.push({
                value: currentDebriefing.tripId, label:
                    <Fragment>
                        {currentDebriefing.trip.user !== null ? currentDebriefing.trip.user.firstname : ''}{' '}
                        {currentDebriefing.trip.user !== null ? currentDebriefing.trip.user.lastname : ''}{' '}
                        {moment(currentDebriefing.trip.date).format('Do MMMM')}{' '}
                        {currentMeridiem}{' '}
                        {`(${currentDebriefing.trip.location.name})`}{'  '}
                        {(currentDebriefing.debriefing !== null && currentDebriefing.debriefing !== '') ? <FontAwesomeIcon color='#66afff' icon={faEye} /> : ''}{' '}
                        {currentDebriefing.boarObs !== 0 && currentDebriefing.boarObs!== null && <img src={sanglier} className="deb-icon" alt="sanglier-icon" />}
                        {currentDebriefing.foxObs !== 0 && currentDebriefing.foxObs!== null && <img src={renard} className="deb-icon" alt="renard-icon" />}

                        {currentDebriefing.deb_consulted === true ? <FontAwesomeIcon color='#38db0a' icon={faCheck} /> : ''}
                    </Fragment>
            })
            return false;
        })
        console.log(selectTripsChoice)
        return selectTripsChoice;
    }

    /**
     * JSX FUNCTIONS
     */
    const selectDebriefingType = () => {

        //Select du type
        const selectDebriefingChoice = [
            { value: 'read', label: 'Consulter' },
            { value: 'write', label: 'Ecrire' },
        ]

        return (
            <Fade clear duration={500} delay={150}>
                <form >
                    <div className="form-container">
                        <label className="form-label">Mode</label>
                        <Select
                            menuPlacement="bottom"
                            menuPortalTarget={document.querySelector('body')}
                            options={selectDebriefingChoice}
                            value={selectDebriefingChoice.filter(obj => obj.value === selectedType)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeDebriefingType}
                        />
                    </div>
                </form>
            </Fade>
        )
    }

    const selectDebriefingTrip = () => (
        <Fade clear duration={500} delay={150}>
            <form >
                <div className="form-container">
                    <label className="form-label">Sélectionner une sortie</label>

                    <Select
                        menuPlacement="bottom"
                        menuPortalTarget={document.querySelector('body')}
                        options={tripsByIdSelect()}
                        value={tripsByIdSelect().filter(obj => obj.value === tripId)}
                        isSearchable={false}
                        theme={selectStyle}
                        onChange={handleChangeDebriefingTrip}
                    />
                </div>
                {tripId != null &&
                    <>
                        <Fade clear duration={500} delay={250}>
                            <div className="observations-container">
                                <div className="observation-block">
                                    <label className="form-label observation-specie">Sanglier(s) vu(s)</label>
                                    <input
                                        className="form-input observation-qty"
                                        onChange={handleChangeInput('boarObs')}
                                        value={boarObs === null ? '' : boarObs}
                                        type="number"
                                        pattern="\d*"
                                        placeholder="0"
                                    />
                                </div>
                                <div className="observation-block">
                                    <label className="form-label observation-specie">Renard(s) vu(s)</label>
                                    <input
                                        className="form-input observation-qty"
                                        onChange={handleChangeInput('foxObs')}
                                        value={foxObs === null ? '' : foxObs}
                                        type="number"
                                        pattern="\d*"
                                        placeholder="0"
                                    />
                                </div>
                            </div>
                        </Fade>
                        <Fade clear duration={500} delay={250}>
                            <div className="form-container debrifing-text-arearea">
                                <label className="form-label">{comment === null || comment === '' ? 'Laisser un commentaire' : 'Modifer un commentaire'}</label>
                                <textarea className="form-input debriefing-input" type="text" value={comment} autoFocus={true} onChange={(e) => { changeValue('comment', e.target.value) }} />
                            </div>
                        </Fade>
                        <Fade clear duration={500} delay={250}>
                            <button type='submit' className="btn btn-primary" onClick={handleSaveDebriefing}>
                                Soumettre
				        </button>
                        </Fade>
                    </>
                }
            </form>
        </Fade >
    )

    const selectDebriefingList = () => (
        <Fade clear duration={500} delay={150}>
            <form >
                <div className="form-container">
                    <label className="form-label">Choisir un compte rendu</label>
                    <Select
                        menuPlacement="bottom"
                        menuPortalTarget={document.querySelector('body')}
                        options={debriefingsSelect()}
                        value={debriefingsSelect().filter(obj => obj.value === tripId)}
                        isSearchable={false}
                        theme={selectStyle}
                        onChange={handleChangeDebriefingTripConsult}
                    />
                </div>
                {tripId != null &&
                    <>
                        <Fade clear duration={500} delay={250}>
                            <div className="observations-container">
                                <div className="observation-block">
                                    <label className="form-label observation-specie">Sanglier(s) vu(s)</label>
                                    <input
                                        disabled
                                        className="form-input observation-qty"
                                        onChange={handleChangeInput('boarObs')}
                                        value={boarObs === null ? '' : boarObs}
                                        type="number"
                                        pattern="\d*"
                                        placeholder="0"
                                    />
                                </div>
                                <div className="observation-block">
                                    <label className="form-label observation-specie">Renard(s) vu(s)</label>
                                    <input
                                        disabled
                                        className="form-input observation-qty"
                                        onChange={handleChangeInput('foxObs')}
                                        value={foxObs === null ? '' : foxObs}
                                        type="number"
                                        pattern="\d*"
                                        placeholder="0"
                                    />
                                </div>
                            </div>
                        </Fade>
                        <Fade clear duration={500} delay={250}>
                            <div className="form-container debrifing-text-arearea">
                                <label className="form-label">Commentaire</label>
                                <textarea disabled className="form-input debriefing-input" type="text" value={comment} onChange={(e) => { changeValue('comment', e.target.value) }} />
                            </div>
                        </Fade>
                    </>
                }
            </form>
        </Fade>
    )

    return (
        <Layout>
            <div className="container-fluid col-md-6 offset-md-3 align-item-center flex-grow-2">
                <ToastContainer autoClose={1800} />
                <Fade clear duration={500} delay={150}>
                    <h1 className="p-2 text-center">Comptes-Rendus</h1>
                </Fade>

                <div className="loader">
                    < BallBeat margin={'auto'} loading={tripsByIdLoader || debriefingsLoader ? true : false} />
                </div>

                {(roleId === 1 || roleId === 2 || roleId === 4) && !tripsByIdLoader && !debriefingsLoader && selectDebriefingType()}
                {!tripsByIdLoader && !debriefingsLoader && selectedType === 'write' && selectDebriefingTrip()}
                {!tripsByIdLoader && !debriefingsLoader && selectedType === 'read' && selectDebriefingList()}

            </div>
        </Layout>
    );
};

Debriefing.propTypes = {
    tripId: PropTypes.number,
    tripsByIdData: PropTypes.array,
    comment: PropTypes.string,
    cityId: PropTypes.number,
    roleId: PropTypes.number,
    debriefingsData: PropTypes.array,

    loadTripsById: PropTypes.func,
    loadDebriefings: PropTypes.func,
    changeValue: PropTypes.func,
    saveDebriefing: PropTypes.func,
    saveCheckedDebriefings: PropTypes.func,

};

export default Debriefing;
