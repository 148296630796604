/* eslint-disable no-case-declarations */
import axios from 'axios';
import { getCookie } from 'services/security/helpers'

import {
  LOAD_NOTIFICATIONS,
  DISABLE_NOTIFICATIONS,
  saveNotifications
} from 'services/ducks/modules/notification';

const notificationMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case LOAD_NOTIFICATIONS:
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/notification/${store.getState().auth.id}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('LOAD_NOTIFICATIONS SUCCESS', response);
          store.dispatch(saveNotifications(response.data.notification.notificationNumber));
        })
        .catch(error => {
          console.log('LOAD_NOTIFICATIONS ERROR', error);
        });

      next(action);
      break;

    case DISABLE_NOTIFICATIONS:
      axios({
        method: 'put',
        url: `${process.env.REACT_APP_API}/notification/remove/${store.getState().auth.id}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('DISABLE_NOTIFICATIONS SUCCESS', response);
          // store.dispatch(saveNotifications(response.data.notification.notificationNumber));
        })
        .catch(error => {
          console.log('DISABLE_NOTIFICATIONS ERROR', error);
        });

      next(action);
      break;

    // case SAVE_HOUR_LIMITATION:
    //   axios({
    //     method: 'post',
    //     url: `${process.env.REACT_APP_API}/settings/hourLimitation/`,
    //     data: {
    //       cityId: store.getState().auth.cityId,
    //       roleId: store.getState().auth.roleId,
    //       hourLimitationType: action.hourLimitationType,
    //       hourLimitationDate: action.hourLimitationDate,
    //     },
    //     headers: {
    //       Authorization: `Bearer ${getCookie('token')}`
    //     }
    //   })
    //     .then(response => {
    //       console.log('REPORT SAVED SUCCESS', response);

    //       toast.success(response.data.message);
    //     })
    //     .catch(error => {
    //       console.log('REPORT SAVED ERROR', error);
    //       toast.error(error.response.data.error);
    //     });

    //   next(action);
    //   break;

    // case SAVE_TOOGLE_REGISTRATION_MODE:
    //   console.log('passe')
    //   axios({
    //     method: 'post',
    //     url: `${process.env.REACT_APP_API}/settings/toggleLimitRegistration/`,
    //     data: {
    //       cityId: store.getState().auth.cityId,
    //       roleId: store.getState().auth.roleId,
    //       limitRegistration: action.limitRegistration,
    //     },
    //     headers: {
    //       Authorization: `Bearer ${getCookie('token')}`
    //     }
    //   })
    //     .then(response => {
    //       console.log('SAVE_TOOGLE_REGISTRATION_MODE SAVED SUCCESS', response);

    //       toast.success(response.data.message);
    //     })
    //     .catch(error => {
    //       console.log('SAVE_TOOGLE_REGISTRATION_MODE SAVED ERROR', error);
    //       toast.error(error.response.data.error);
    //     });

    //   next(action);
    //   break;

    // case LOAD_SETTINGS:
    //   store.dispatch(enableLoader('settingsLoader'));
    //   axios({
    //     method: 'get',
    //     url: `${process.env.REACT_APP_API}/settings/getSettings/${isAuth().cityId}`,
    //     headers: {
    //       Authorization: `Bearer ${getCookie('token')}`
    //     }
    //   })
    //     .then(response => {
    //       console.log('GET_SETTINGS SUCCESS', response);
    //       store.dispatch(saveSettings(Object.entries(response.data.settings)));
    //       store.dispatch(disableLoader('settingsLoader'));
    //     })
    //     .catch(error => {
    //       store.dispatch(disableLoader('settingsLoader'));
    //       console.log('GET_SETTINGS ERROR', error);
    //     });

    //   next(action);
    //   break;

    //   case SAVE_DELAY_HOUR:
    //     console.log('passe')
    //     axios({
    //       method: 'post',
    //       url: `${process.env.REACT_APP_API}/settings/delayHour/`,
    //       data: {
    //         cityId: store.getState().auth.cityId,
    //         roleId: store.getState().auth.roleId,
    //         delayHour: action.delayHour,
    //       },
    //       headers: {
    //         Authorization: `Bearer ${getCookie('token')}`
    //       }
    //     })
    //       .then(response => {
    //         console.log('SAVE_DELAY_HOUR SAVED SUCCESS', response);
  
    //         toast.success(response.data.message);
    //       })
    //       .catch(error => {
    //         console.log('SAVE_DELAY_HOUR SAVED ERROR', error);
    //         toast.error(error.response.data.error);
    //       });
  
    //     next(action);
    //     break;

    default:
      next(action);
  }
};

export default notificationMiddleware;