
// set in cookie
export const getTheDay = (day) => {

    if (!(day instanceof Date)) {
        day = new Date(day);
    }
    const arrayDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    console.log(typeof day)
    return arrayDays[day.getDay()]
};

export const getTheMonth = (day) => {

    if (!(day instanceof Date)) {
        day = new Date(day);
    }
    const arrayDays = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ];
    return arrayDays[day.getMonth()]
};


export const getTheDayNum = (day) => {

    if (!(day instanceof Date)) {
        day = new Date(day);
    }

    return day.getDate();
};

export const today = () => {
    return new Date().toISOString().split('T')[0];
} 

export const tomorrow = () => {
    const tm = new Date()
	tm.setDate(tm.getDate() + 1);
	return tm.toISOString().split('T')[0];
} 

export const formatHour = (theHour) => (
    theHour.replace('H', ':')
)
    