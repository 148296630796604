import React, { useEffect } from 'react';
import Layout from 'components/Layout/LayoutContainer';
import Carrousel from 'components/Carrousel/CarrouselContainer';
import Map from 'components/Map/MapContainer';
import PropTypes from 'prop-types';

const Home = ({
    cityId,
    tripsData,
    weatherData,
    loadTrips,
    loadWeather,
    registerIdConnection,
    getClientVersion
}) => {

    // Checker la cersion client
    useEffect(() => {
        getClientVersion()
    }, [getClientVersion]);

    // On charge les sorties si ils ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadTrips();
        cityId !== null && registerIdConnection();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || tripsData === []]);

    useEffect(() => {
        cityId !== null && loadWeather();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null || weatherData === []]);

    return (
        <Layout>
            <div className='container-fluid px-0'>
                <Carrousel />

                <div className="container-map">
                    <Map
                        mapClass={'map-home'}
                        markers={'tripsMarkers'}
                        restrictedAreas={true}
                        wind={true}
                    />
                </div>
            </div>
        </Layout>
    );
};

Home.propTypes = {
    cityId: PropTypes.number,
    tripsData: PropTypes.arrayOf(PropTypes.object),
    weatherData: PropTypes.object,
    loadTrips: PropTypes.func,
    loadWeather: PropTypes.func,
    registerIdConnection: PropTypes.func,
};

export default Home;
