// == Action types ======================

export const CHANGE_ADMIN_VALUE = 'CHANGE_ADMIN_VALUE';
export const SAVE_NEW_LOCATION = 'SAVE_NEW_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const LOAD_MANAGEABLE_USER_PROFILE = 'LOAD_MANAGEABLE_USER_PROFILE';
export const SAVE_MANAGEABLE_USER_PROFILE = 'SAVE_MANAGEABLE_USER_PROFILE';
export const CHANGE_MANAGEABLE_USER_VALUE = 'CHANGE_MANAGEABLE_USER_VALUE';
export const RESET_MANAGEABLE_USER_VALUE = 'RESET_MANAGEABLE_USER_VALUE'; // Remettre à zero l'etat d'eun utilisateur
export const SUBMIT_MANAGEABLE_PROFILE = 'SUBMIT_MANAGEABLE_PROFILE'; // Sauvegarder un profil utilistauer
export const SIGNOUT = 'SIGNOUT'; // Sauvegarder un profil utilistauer

// == Action creators

export const changeValue = (name, value) => (
  {
  type: CHANGE_ADMIN_VALUE,
  name,
  value: value,
});

export const changeManageableUserValue = (name, value) => (
  {
  type: CHANGE_MANAGEABLE_USER_VALUE,
  name,
  value: value,
});

export const resetManageableUserValue = () => (
  {
  type: RESET_MANAGEABLE_USER_VALUE,
});

export const submitManageableProfile = () => ({
  type: SUBMIT_MANAGEABLE_PROFILE,
});

export const saveNewLocation = () => ({
  type: SAVE_NEW_LOCATION,
});

export const updateLocation = () => ({
  type: UPDATE_LOCATION,
});

export const loadManageableUserProfile = (manageableUserId) => ({
  type: LOAD_MANAGEABLE_USER_PROFILE,
  manageableUserId
});

////////////////////////////////// a controller utile ou non
export const saveManageableUserProfile = (manageableUserDatas) => ({
  type: SAVE_MANAGEABLE_USER_PROFILE,
  manageableUserDatas
});

// == Reducer ========================
const initialState = {
  newNameLocation: '',
  draggableLat: null,
  draggableLng: null,
  selectedLocationId: null,
  selectedNameLocation: '',
  locationActive: null,
  manageableUser: {
    id: null,
    roleId: null,
    firstname: '',
    lastname: '',
    licenseNumber: '',
    email: '',
    membershipId: null,
    phone: '',
    password: '',
    state: true,
  },
  addLocationChoice: 'Consulter'

};

function adminReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_ADMIN_VALUE:
      console.log(action.name)
      return {
        ...state,
        [action.name]: action.value,
      };

    case CHANGE_MANAGEABLE_USER_VALUE:
      console.log(action.name)
      return {
        ...state,
        manageableUser: {
          ...state.manageableUser,
          [action.name]: action.value,
        }
      };

    case SAVE_MANAGEABLE_USER_PROFILE:
      console.log(action.manageableUserDatas)
      return {
        ...state,
        manageableUser: {
          id: action.manageableUserDatas.id,
          roleId: action.manageableUserDatas.roleId,
          originalRoleId: action.manageableUserDatas.roleId,
          firstname: action.manageableUserDatas.firstname,
          lastname: action.manageableUserDatas.lastname,
          licenseNumber: action.manageableUserDatas.licenseNumber,
          email: action.manageableUserDatas.email,
          membershipId: action.manageableUserDatas.membershipId,
          phone: action.manageableUserDatas.phone,
          password: '',
          state: action.manageableUserDatas.state,
        }

      };

    case RESET_MANAGEABLE_USER_VALUE:
      return {
        ...state,
        manageableUser: {
          id: null,
          roleId: null,
          firstname: '',
          lastname: '',
          licenseNumber: '',
          email: '',
          membershipId: null,
          phone: '',
          password: '',
        }

      };

    case SIGNOUT:
      return initialState;
      
    default:
      return state;
  }
}

export default adminReducer;
