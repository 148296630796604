import React, { useEffect } from "react";

import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';
import Toggle from 'react-toggle';

import PropTypes from 'prop-types';

const AddLocationForm = ({
	cityLat,
	cityLng,
	addLocationChoice,
	selectedLocationId,
	selectedNameLocation,
	locationActive,
	locationsData,
	newNameLocation,
	changeValue,
	saveNewLocation,
	updateLocation
}) => {

	useEffect(() => {
		changeValue('draggableLat', cityLat);
		changeValue('draggableLng', cityLng);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cityLat]);

	// Reset du lieu choisi au demarrage de 'Modifier'
	useEffect(() => {
		if (addLocationChoice === 'Modifier') {
			changeValue('selectedLocationId', null);
			changeValue('selectedNameLocation', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addLocationChoice])


	/**
	 * Soumission du formulaire du nouveau lieu dit
	 * @param {} event 
	 */
	const handleSubmitMarker = (event) => {
		event.preventDefault();
		saveNewLocation();
	}

	/**
	 * Update du formulaire du lieu dit
	 * @param {} event 
	 */
	const handleUpdateMarker = (event) => {
		event.preventDefault();
		updateLocation();
	}

	/**
	 * Champ contrôlé
	 * @param {*} name 
	 */
	const handleChange = name => event => {

		changeValue(name, event.target.value)
	};

	////////SELECT////////

	// Fonction du champ controlé select
	const handleChangeSelectChoice = e => {
		changeValue('addLocationChoice', e.value);
	};

	// Fonction du champ controlé select
	const handleChangeSelectedLocation = e => {
		changeValue('selectedLocationId', e.value);
		const location = locationsData.find(location => location.id === e.value);
		changeValue('draggableLat', location.lat);
		changeValue('draggableLng', location.lng);
		changeValue('selectedNameLocation', location.name);
		changeValue('locationActive', location.active);
		console.log(location)
	};

	const selectChoice = [
		{ value: 'Consulter', label: 'Consulter' },
		{ value: "Ajouter", label: 'Ajouter' },
		{ value: 'Modifier', label: 'Modifier' },
	]

	const selectLocationsOptions = [];

	locationsData.map(location => {
		return selectLocationsOptions.push({ value: location.id, label: location.name });
	})

	return (
		<form>
			<div className="form-container">
				<label className="form-label">Action</label>
				<Select
					menuPlacement="top"
					options={selectChoice}
					value={selectChoice.filter(obj => obj.value === addLocationChoice)}
					isSearchable={false}
					theme={selectStyle}
					onChange={handleChangeSelectChoice}
				/>
			</div>
			<div className="linebreak"></div>

			{ addLocationChoice === "Ajouter" &&
				<>
					<div className="form-container">
						<label className="form-label">Nouveau Lieu-Dit</label>
						<input className="form-input" value={newNameLocation} onChange={handleChange('newNameLocation')} type="text" />
					</div>

					<button className="btn btn-primary" onClick={handleSubmitMarker}>
						Enregistrer
		 			 </button>
				</>
			}

			{ addLocationChoice === "Modifier" &&
				<>
					<div className="form-container">
						<label className="form-label">Lieu-dit</label>
						<Select
							options={selectLocationsOptions}
							// value={userSelectOptions.filter(obj => obj.value === userId)}selectedLocationId
							isSearchable={false}
							theme={selectStyle}
							onChange={handleChangeSelectedLocation}
						/>
					</div>
					<div className="form-container">
						<label className="form-label">Nom du lieu-dit</label>
						<input className="form-input" value={selectedNameLocation} onChange={handleChange('selectedNameLocation')} type="text" />
					</div>
					{ selectedLocationId !== null && (
						<>
							<div className="toggle-container">
								<label className=" form-label">{(locationActive === true) ? 'Lieu-dit Actif' : 'Lieu-dit Inactif'}</label>
								<Toggle
									className='ml-2'
									checked={locationActive}
									name='burritoIsReady'
									value={locationActive}
									onChange={() => {
										changeValue("locationActive", !locationActive)
									}}
								/>
							</div>
							<button className="btn btn-primary" onClick={handleUpdateMarker}>
								Enregistrer
		 					</button>
						</>
					)}
				</>
			}
		</form>
	);
};

AddLocationForm.propTypes = {
	cityLat: PropTypes.number,
	cityLng: PropTypes.number,
	addLocationChoice: PropTypes.string,
	selectedLocationId: PropTypes.number,
	selectedNameLocation: PropTypes.string,
	locationActive: PropTypes.bool,
	locationsData: PropTypes.arrayOf(
		PropTypes.object
	),
	newNameLocation: PropTypes.string,
	changeValue: PropTypes.func,
	saveNewLocation: PropTypes.func,
	updateLocation: PropTypes.func,
};

export default AddLocationForm;