/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadSettings } from 'services/ducks/modules/settings';
import { loadNotifications } from 'services/ducks/modules/notification';
import { loadTrips, loadDebriefings, loadRestrictedAreas, loadLocations, loadTakings, loadNews } from 'services/ducks/modules/dataList';
import { isAuth, authenticate, getCookie, updateUser, removeCookie, removeLocalStorage, setLocalStorage } from 'services/security/helpers';
import { enableLoader, disableLoader } from 'services/ducks/modules/loader';
import io from 'socket.io-client';

import {
  SUBMIT_SIGNUP,
  SUBMIT_ACTIVATE,
  SUBMIT_SIGNIN,
  SUBMIT_FORGOT,
  SUBMIT_RESET,
  LOAD_PROFILE,
  SUBMIT_PRIVATE_PROFILE,
  SIGNOUT,
  GET_CLIENT_VERSION,
  REGISTER_ID_CONNECTION,
  signout,
  changeValue,
  saveProfile,
  isSocketIoConnected,
} from 'services/ducks/modules/auth';

import {
  saveMapParams
} from 'services/ducks/modules/map';
// import { Router } from 'express'; //genere erreur

// let socket;
let socket = io(process.env.REACT_APP_WEBSOCKET);

const authMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case SUBMIT_SIGNUP:

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/signup`,
        data: {
          departmentId: store.getState().auth.departmentId,
          cityId: store.getState().auth.cityId,
          firstname: store.getState().auth.firstname,
          lastname: store.getState().auth.lastname,
          licenseNumber: store.getState().auth.licenseNumber,
          membershipId: store.getState().auth.membershipId,
          email: store.getState().auth.email,
          phone: store.getState().auth.phone,
          password: store.getState().auth.password,
          passwordConfirmation: store.getState().auth.passwordConfirmation,
        }
      })
        .then(response => {
          console.log('SIGNUP SUCCESS', response);
          store.dispatch(changeValue('departmentId', null));
          store.dispatch(changeValue('cityId', null));
          store.dispatch(changeValue('firstname', ''));
          store.dispatch(changeValue('lastname', ''));
          store.dispatch(changeValue('licenseNumber', ''));
          store.dispatch(changeValue('membershipId', null));
          store.dispatch(changeValue('email', ''));
          store.dispatch(changeValue('phone', ''));
          store.dispatch(changeValue('password', ''));
          store.dispatch(changeValue('buttonText', 'Submitted'));
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('SIGNUP ERROR', error.response.data.error);
          // setValues({ ...values, buttonText: 'Submit' });
          store.dispatch(changeValue('buttonText', 'Submit'));
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case SUBMIT_ACTIVATE:

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/account-activation`,
        data: { token: store.getState().externalUser.token }
      })
        .then(response => {
          console.log('ACCOUNT ACTIVATION', response);
          store.dispatch(changeValue('show', false));
          // setValues({ ...values, show: false });
          toast.success(response.data.message);
        })
        .catch(error => {
          console.log('ACCOUNT ACTIVATION ERROR', error.response.data.error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case SUBMIT_SIGNIN:

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/signin`,
        data: {
          departmentId: store.getState().auth.departmentId,
          cityId: store.getState().auth.cityId,
          email: store.getState().auth.email,
          password: store.getState().auth.password
        }
      })
        .then(response => {
          console.log('SIGNIN SUCCESS', response);
          // save the response (user, token) localstorage/cookie
          authenticate(response, () => {
            store.dispatch(changeValue('departmentId', ''));
            store.dispatch(changeValue('name', null));
            store.dispatch(changeValue('cityId', null));
            store.dispatch(changeValue('email', ''));
            store.dispatch(changeValue('password', ''));
            store.dispatch(changeValue('buttonText', 'Submitted'));
            // toast.success(`Hey ${response.data.user.name}, Welcome back!`);
            isAuth() && isAuth().role === 'admin' ? action.history.push('/admin') : action.history.push('/');
          });
        })
        .catch((error) => {
          console.log('SIGNIN ERROR', error.response);
          toast.error(error.response.data.error);
          store.dispatch(changeValue('buttonText', 'Connexion'));
          // setValues({ ...values, buttonText: 'Submit' });
          // toast.error(error.response.data.error);
        });

      next(action);
      break;

    case SUBMIT_FORGOT:

      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API}/forgot-password`,
        data: {
          cityId: store.getState().auth.cityId,
          email: store.getState().auth.email,
        }
      })
        .then(response => {
          console.log('FORGOT PASSWORD SUCCESS', response);
          toast.success(response.data.message);
          store.dispatch(changeValue('buttonText', 'Lien envoyé'));
          // BrowserRouter.push('/item')
          // BrowserHistory.push('/registrationStep2')

        })
        .catch(error => {
          console.log('FORGOT PASSWORD ERROR', error.response.data);
          toast.error(error.response.data.error);
          store.dispatch(changeValue('buttonText', 'Générér un lien de renouvellement de mot de passe'));
        });

      next(action);
      break;

    case SUBMIT_RESET:

      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API}/reset-password`,
        data: {
          newPassword: store.getState().auth.password,
          resetPasswordLink: store.getState().auth.token
        }
      })
        .then(response => {
          console.log('RESET PASSWORD SUCCESS', response);
          toast.success(response.data.message);
          store.dispatch(changeValue('buttonText', 'Connexion'));
          store.dispatch(changeValue('password', ''));
          store.dispatch(changeValue('path', 'reset'));
        })
        .catch(error => {
          console.log('RESET PASSWORD ERROR', error.response.data);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case LOAD_PROFILE:
      store.dispatch(enableLoader('profileLoader'));
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/user/${isAuth().id}`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('PRIVATE PROFILE LOADED', response);
          store.dispatch(saveProfile(response.data));
          store.dispatch(saveMapParams(response.data));
          store.dispatch(disableLoader('profileLoader'));

          socket.onAny((event, ...args) => {
            console.log(event, args);
          });
          socket.on('tripEvent', (respCityId) => {
            respCityId === response.data.city.id && store.dispatch(loadTrips());
          })
          socket.on('settingsEvent', (respCityId) => {
            respCityId === response.data.city.id && store.dispatch(loadSettings());
          })
          socket.on('restrictedAreaEvent', (respCityId) => {
            respCityId === response.data.city.id && store.dispatch(loadRestrictedAreas());
          })
          socket.on('debriefingEvent', (respCityId) => {
            respCityId === response.data.city.id && store.dispatch(loadDebriefings());
          })
          socket.on('locationsEvent', (respCityId) => {
            respCityId === response.data.city.id && store.dispatch(loadLocations());
          })
          socket.on('notificationEvent', (respCityId) => {
            if(respCityId === response.data.city.id) {
              store.dispatch(loadNotifications());
              store.dispatch(loadTakings());
              store.dispatch(loadNews());
            }
            
          })
          socket.on('connect', () => {
            store.dispatch(loadTrips());
            store.dispatch(loadSettings());
            store.dispatch(loadRestrictedAreas());
            store.dispatch(loadDebriefings());
            store.dispatch(loadLocations());
            store.dispatch(loadNotifications());
            store.dispatch(isSocketIoConnected(true));
            console.log('CONNECTED')
          });
          socket.on('disconnect', () => {
            store.dispatch(isSocketIoConnected(false));

            console.log('DISCONNECTED')
          });

          return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('tripEvent');
            socket.off('settingsEvent');
            socket.off('debriefingEvent');
            socket.off('locationEvent');
            socket.off('restrictedAreaEvent');
            socket.off('notificationEvent');
          };

        })
        .catch(error => {
          console.log('PRIVATE PROFILE LOADED ERROR', error);
          if (error.response.status === 401) {
            signout(() => {
              action.history.push('/');
            });
          }
        });

      next(action);
      break;

    case SUBMIT_PRIVATE_PROFILE:
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API}/user/update`,
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        },
        data: {
          id: isAuth().id,
          roleId: store.getState().auth.roleId,
          role: store.getState().auth.role,
          firstname: store.getState().auth.firstname,
          lastname: store.getState().auth.lastname,
          licenseNumber: store.getState().auth.licenseNumber,
          email: store.getState().auth.email,
          membershipId: store.getState().auth.membershipId,
          phone: store.getState().auth.phone,
          password: store.getState().auth.password,
          color: store.getState().auth.color,
          cityId: store.getState().auth.cityId,
          departmentId: store.getState().auth.departmentId,
        }
      })
        .then(response => {
          console.log('PRIVATE PROFILE UPDATE SUCCESS', response);
          updateUser(response, () => {
            store.dispatch(changeValue('buttonText', 'Submitted'))
            //store.dispatch(saveProfile(response.data));
            // store.dispatch(changeValue('name', 'Submitted'));
            toast.success('Le profil a été mis à jour');
          });
        })
        .catch(error => {
          console.log('PRIVATE PROFILE UPDATE ERROR', error.response.data.error);
          store.dispatch(changeValue('buttonText', 'Submit'));
          toast.error(error.response.data.error);
        });

      next(action);
      break;

    case SIGNOUT:
      // console.log('token' + action.token)
      removeCookie('token');
      removeLocalStorage('user');
      action.history.push('/');
      store.dispatch(changeValue('id', null));
      store.dispatch(changeValue('role', ''));
      store.dispatch(changeValue('departmentId', null));
      store.dispatch(changeValue('cityId', null));
      store.dispatch(changeValue('cityLat', null));
      store.dispatch(changeValue('cityLng', null));
      store.dispatch(changeValue('firstname', ''));
      store.dispatch(changeValue('lastname', ''));
      store.dispatch(changeValue('licenseNumber', ''));
      store.dispatch(changeValue('membershipId', null));
      store.dispatch(changeValue('phone', ''));
      store.dispatch(changeValue('email', ''));
      store.dispatch(changeValue('password', ''));
      store.dispatch(changeValue('passwordConfirmation', ''));

      next(action);
      break;

    case GET_CLIENT_VERSION:
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/client-version`,
        data: {
          id: isAuth().id,
        }
      })
        .then(response => {
          console.log('GET_CLIENT_VERSION SUCCESS', response);

          if (localStorage.getItem("clientVersion") === null) {
            setLocalStorage('clientVersion', {
              version: response.data.clientVersion
            });
            window.location.reload();
          } else if (localStorage.getItem("clientVersion")) {
            const cv = JSON.parse(localStorage.getItem("clientVersion"))
            if (response.data.clientVersion !== cv.version) {
              setLocalStorage('clientVersion', {
                version: response.data.clientVersion
              });
              window.location.reload();
            }
          }
        })
        .catch(error => {
          console.log('GET_CLIENT_VERSION ERROR', error);
        });

      next(action);
      break;

    case REGISTER_ID_CONNECTION:
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/register-id-connection`,
        data: {
          id: isAuth().id,
        }
      })
        // .then(response => {
        //   // console.log('GET_CLIENT_VERSION SUCCESS', response);

        //   // if (localStorage.getItem("clientVersion") === null) {
        //   //   setLocalStorage('clientVersion', {
        //   //     version: response.data.clientVersion
        //   //   });
        //   //   window.location.reload();
        //   // } else if (localStorage.getItem("clientVersion")) {
        //   //   const cv = JSON.parse(localStorage.getItem("clientVersion"))
        //   //   if (response.data.clientVersion !== cv.version) {
        //   //     setLocalStorage('clientVersion', {
        //   //       version: response.data.clientVersion
        //   //     });
        //   //     window.location.reload();
        //   //   }
        //   // }
        // })
        .catch(error => {
          console.log('GET_CLIENT_VERSION ERROR', error);
        });

      next(action);
      break;

    default:
      next(action);
  }
};

export default authMiddleware;