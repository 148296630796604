import React, { useEffect, useRef } from 'react';
import Footer from 'components/Footer/Footer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';

import { BallBeat } from 'react-pure-loaders';

import PropTypes from 'prop-types';

const Forgot = ({
    history,
    departmentsData,
    departmentId,
    citiesData,
    cityId,
    email,
    buttonText,
    changeValue,
    submitForgot,
    loadDepartments,
    loadCities,
    departmentsLoader,
    citiesLoader
}) => {

    // Charger la liste les départements au demarrage
    useEffect(() => {
        if (departmentsData.length === 0) {
            loadDepartments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Charger la liste des communes si un departement est selectionné
    useEffect(() => {
        if (departmentId !== null && citiesData.length === 0) {
            loadCities();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentId]);


    useEffect(() => {
        changeValue('buttonText', 'Générer un lien de renouvellement de mot de passe')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const btn = useRef();

    // Fonction du champ controlé
    const handleChange = name => event => {
        if (name === 'cityId' || name === 'departmentId') {
            return changeValue(name, parseInt(event.target.value))
        }
        changeValue(name, event.target.value)
        btn.current.removeAttribute("disabled");
    };

    // Liste des departements
    const departementSelectOptions = [];

    departmentsData.map(department =>
        departementSelectOptions.push({ value: department.id, label: department.name })
    )

    // Liste des villes par departement
    const citySelectOptions = [];

    citiesData.map(city =>
        citySelectOptions.push({ value: city.id, label: city.name })
    )

    // Soumettre le formulaire
    const clickSubmit = event => {
        event.preventDefault();
        btn.current.setAttribute("disabled", true);
        submitForgot(history);

    };

    // Fonction du champ controlé select
    const handleChangeSelect = name => event => {
        changeValue(name, parseInt(event.value))
    };

    const passwordForgotForm = () => (
        <form>
            <div className="form-container">
                <label className="form-label">Département</label>
                <div className="text-center">
                    < BallBeat loading={departmentsLoader} />
                </div>
                {!departmentsLoader &&
                    <Select
                        options={departementSelectOptions}
                        value={departementSelectOptions.filter(obj => obj.value === departmentId)}
                        isSearchable={false}
                        placeholder='Votre département'
                        theme={selectStyle}
                        onChange={handleChangeSelect('departmentId')}
                    />
                }
            </div>

            {departmentId != null && (
                <div className="form-container">
                    <label className="form-label">Commune</label>
                    <div className="text-center">
                        < BallBeat loading={citiesLoader} />
                    </div>
                    {!citiesLoader &&
                        <Select
                            options={citySelectOptions}
                            value={citySelectOptions.filter(obj => obj.value === cityId)}
                            isSearchable={false}
                            placeholder='Votre commune'
                            theme={selectStyle}
                            onChange={handleChangeSelect('cityId')}
                        />
                    }
                </div>
            )}

            {cityId != null && (<>
                <div className="form-container">
                    <label className="form-label">Email</label>
                    <input className="form-input" onChange={handleChange('email')} value={email} type="email" />
                </div>

                <div>
                    <button ref={btn} className="btn btn-primary" onClick={clickSubmit}>
                        {buttonText}
                    </button>
                </div>
            </>
            )}
        </form>
    );

    return (
        <>
            <div className="signin-container row justify-content-between">

                <div className="signin-title-container column col-md-4 ">
                    <Link to="/">
                        <h1 className="title">Chasse Connect</h1>
                    </Link>

                </div>

                <div className="col-md-6 offset-md-3">
                    <ToastContainer autoClose={3000} />
                    <h3 className="p-5 text-center">Mot de passe oublié</h3>
                    {passwordForgotForm()}
                </div>
                <Footer />
            </div>

        </>
    );
};

Forgot.propTypes = {
    departmentsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    departmentId: PropTypes.number,
    citiesData: PropTypes.arrayOf(
        PropTypes.object
    ),
    cityId: PropTypes.number,
    email: PropTypes.string,
    buttonText: PropTypes.string,
    changeValue: PropTypes.func,
    submitForgot: PropTypes.func,
    loadDepartments: PropTypes.func,
    loadCities: PropTypes.func
};

export default Forgot;
