import React, { useEffect } from 'react';
import Layout from 'components/Layout/LayoutContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Toggle from 'react-toggle';
import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';
import { BallBeat } from 'react-pure-loaders';
import PropTypes from 'prop-types';

const Administrate = ({
    firstname,
    manageableUser,
    membershipsData,
    rolesData,
    changeManageableUserValue,
    submitManageableProfile,
    profileLoader,
    membershipsLoader,
    rolesLoader,
    usersLoader,
    changeValue,
    saveManageableUserState,
    loadMemberships,
    loadRoles,
    loadUsers,
    loadManageableUserProfile,
    usersData,
    resetManageableUserValue
}) => {


    useEffect(() => {
        resetManageableUserValue(); // Mise à zéro des valeurs
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Chargement des types de membre une fois le profil dans le store
    useEffect(() => {
        loadMemberships();
        loadRoles();
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstname]);

    const handleChange = name => event => {
        changeManageableUserValue(name, event.target.value)
    };

    // Données des select user
    const userSelectOptions = [];

    usersData.map(user =>
        userSelectOptions.push({ value: user.id, label: `${user.lastname} ${user.firstname}` })
    )

    // Données des roles
    const rolesSelectOptions = [];

    rolesData.map(role =>
        rolesSelectOptions.push({ value: role.id, label: role.name })
    )

    console.log(rolesData);

    // Fonction du champ controlé select
    const handleChangeSelect = name => event => {

        if (name === 'loadManageableUser') {
            loadManageableUserProfile(event.value)
        }
        console.log(event.value);
        return changeManageableUserValue(name, event.value)
    };

    // Fonction pour soumettre le formulaire
    const clickSubmit = event => {
        event.preventDefault();
        submitManageableProfile()
    };


    // Données des select memberships
    const membershipsSelect = [];

    membershipsData.map(membership =>
        membershipsSelect.push({ value: membership.id, label: membership.name })
    )


    const updateForm = () => (
        <form>
            <div className="form-container">
                <label className="form-label">Selectionner un compte</label>
                <Select
                    options={userSelectOptions}
                    isSearchable={false}
                    theme={selectStyle}
                    onChange={handleChangeSelect('loadManageableUser')}
                />
            </div>
            {console.log(manageableUser)}
            {manageableUser.id != null &&
                <>
                    <div className="form-container">
                        <label className="form-label">Role</label>
                        <Select
                            options={rolesSelectOptions}
                            theme={selectStyle}
                            value={rolesSelectOptions.filter(obj => obj.value === manageableUser.roleId)}
                            isSearchable={false}
                            onChange={handleChangeSelect('roleId')}
                            disabled
                        />
                    </div>
                    <div className="form-container">
                        <label className="form-label">Prénom</label>
                        <input onChange={handleChange('firstname')} value={manageableUser.firstname} type="text" className="form-input" />
                    </div>

                    <div className="form-container">
                        <label className="form-label">Nom</label>
                        <input onChange={handleChange('lastname')} value={manageableUser.lastname} type="text" className="form-input" />
                    </div>
                    <div className="setting-toggle-container">
                        <label className="form-label">{manageableUser.state ? 'Actif' : 'Inactif'}</label>
                        <Toggle
                            checked={manageableUser.state}
                            name='morningLimitation'
                            value={manageableUser.state}
                            onChange={() => {
                                changeManageableUserValue('state', !manageableUser.state)
                            }}
                        />
                    </div>
                    <div className="form-container">
                        <label className="form-label">Numéro de permis</label>
                        <input onChange={handleChange('licenseNumber')} value={manageableUser.licenseNumber} type="text" className="form-input" />
                    </div>

                    <div className="form-container">
                        <label className="form-label">Email</label>
                        <input value={manageableUser.email} type="email" className="form-input" disabled />
                    </div>

                    <div className="form-container">
                        <label className="form-label">Type de membre</label>
                        <Select
                            options={membershipsSelect}
                            value={membershipsSelect.filter(obj => obj.value === manageableUser.membershipId)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeSelect('membershipId')}
                        />
                    </div>

                    <div className="form-container">
                        <label className="form-label">Téléphone</label>
                        <input onChange={handleChange('phone')} value={manageableUser.phone} type="text" className="form-input" />
                    </div>

                    <div className="form-container">
                        <label className="form-label">Mot de passe (modifier le mot de passe)</label>
                        <input onChange={handleChange('password')} value={manageableUser.password} type="password" className="form-input" />
                    </div>

                    <div>
                        <button className="btn btn-primary" onClick={clickSubmit}>
                            Modifier
                </button>
                    </div>
                </>
            }
        </form>
    );

    return (
        <Layout>
            <div className="container-fluid row flex-grow-2">
                <div className="col-md-6 offset-md-3">
                    <ToastContainer autoClose={1800} />
                    <h1 className="pt-5 text-center">Administrer un compte</h1>
                    <div className="loader">
                        < BallBeat margin={'auto'} loading={profileLoader || membershipsLoader || rolesLoader || usersLoader  ? true : false} />
                    </div>
                    {!profileLoader && !membershipsLoader && !rolesLoader && !usersLoader && updateForm()}
                </div>
            </div>
        </Layout>
    );
};

Administrate.propTypes = {
    firstname: PropTypes.string,
    manageableUser: PropTypes.object,
    membershipsData: PropTypes.arrayOf(
        PropTypes.object
    ),
    rolesData: PropTypes.arrayOf(
        PropTypes.object
    ),
    changeManageableUserValue: PropTypes.func,
    submitManageableProfile: PropTypes.func,
    profileLoader: PropTypes.bool,
    membershipsLoader: PropTypes.bool,
    rolesLoader: PropTypes.bool,
    usersLoader: PropTypes.bool,
    loadMemberships: PropTypes.func,
    loadRoles: PropTypes.func,
    loadUsers: PropTypes.func,
    loadManageableUserProfile: PropTypes.func,
    usersData: PropTypes.arrayOf(
        PropTypes.object
    ),
    resetManageableUserValue: PropTypes.func
};
export default Administrate;
