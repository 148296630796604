import React from 'react';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';

const arrowIcon = (usercolor) => (
    <svg  version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="30pt" height="30pt" viewBox="0 0 500 500" 
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill={usercolor} stroke="none">
   <path d="M222 4767 l3 -153 886 -882 887 -882 40 54 c38 49 149 136 176 136 6
   0 -412 423 -929 940 l-940 940 -63 0 -63 0 3 -153z"/>
   <path d="M2302 2877 c-49 -15 -122 -85 -148 -142 -45 -96 -25 -215 49 -294 99
   -104 276 -108 378 -9 56 54 80 113 80 193 0 155 -110 266 -260 264 -37 -1 -81
   -6 -99 -12z"/>
   <path d="M3841 2694 c-75 -51 -243 -129 -321 -150 -36 -9 -69 -20 -73 -24 -5
   -4 44 -81 109 -171 345 -482 555 -853 614 -1085 35 -134 24 -223 -36 -293
   -158 -184 -590 -26 -1380 505 -117 78 -217 145 -223 148 -6 4 -13 -5 -16 -21
   -27 -125 -96 -298 -159 -400 l-27 -42 73 -49 c216 -145 646 -365 953 -487 430
   -171 876 -291 1245 -334 47 -6 109 -13 138 -16 l52 -6 0 143 c0 587 -258 1337
   -708 2058 -95 151 -170 260 -181 260 -3 0 -31 -16 -60 -36z"/>
   <path d="M2846 2555 c-3 -9 -6 -24 -6 -34 0 -9 -16 -49 -35 -89 -56 -118 -157
   -205 -281 -242 l-40 -12 18 -82 c10 -44 21 -117 24 -161 l7 -81 76 -54 c658
   -463 1213 -754 1366 -716 23 6 25 11 25 57 0 140 -134 410 -405 819 -105 159
   -334 483 -379 537 -1 1 -44 7 -96 14 -85 10 -207 36 -251 54 -10 4 -19 0 -23
   -10z"/>
   </g>
   </svg>
);

const bulletIcon = (usercolor) => (
    <svg version="1.1" id="Capa_1" x="0px" y="0px"
    width="50pt" height="50pt" viewBox="0 0 500 500" 
    preserveAspectRatio="xMidYMid meet" fill={usercolor}>

	<path d="M305.336,107.5c-21.492,0-38.915,17.422-38.915,38.912c0,21.495,17.423,38.915,38.915,38.915
		c21.49,0,38.911-17.42,38.911-38.915C344.247,124.922,326.826,107.5,305.336,107.5z"/>
	<path d="M309.227,186.313c-10.602,0-21.818,7.267-21.818,7.267l-20.557-20.553c-2.876-2.876-3.857-3.915-6.746-3.915H145.701
		H65.539v-0.193c0-2.149-1.742-3.891-3.892-3.891c-2.149,0-3.892,1.742-3.892,3.891v0.193h-7.003c-4.298,0-7.782,3.484-7.782,7.783
		c0,4.298,3.484,7.783,7.782,7.783h87.166v9.081c0,4.298,3.484,7.783,7.782,7.783h36.863c-0.523,5.762,2.184,11.588,7.477,14.765
		l53.325,31.996c2.468,1.48,5.238,2.218,8.009,2.218c2.905,0,5.809-0.813,8.355-2.433l14.475-9.209V287.5h70.042v-66.164
		C344.247,201.992,328.567,186.313,309.227,186.313z M251.135,216.658L225.94,201.54h26.137l8.872,8.873L251.135,216.658z"/>
	<path d="M167.491,165.612h45.528c4.298,0,7.782-3.484,7.782-7.783s-3.484-7.783-7.782-7.783h-45.528
		c-4.298,0-7.782,3.484-7.782,7.783S163.193,165.612,167.491,165.612z"/>

</svg>
);

const shotgunIcon = (usercolor) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="25pt" height="25pt" viewBox="0 0 500 500"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill={usercolor} stroke="none">
<path d="M1020 5033 c-19 -2 -57 -11 -83 -20 -320 -103 -412 -508 -168 -736
78 -73 166 -110 274 -115 140 -7 241 30 334 124 173 173 172 446 -1 620 -95
94 -223 140 -356 127z"/>
<path d="M4175 4366 c-430 -20 -712 -29 -730 -23 -29 8 -283 2 -358 -9 -27 -4
-55 -20 -89 -51 -52 -46 -274 -163 -310 -163 -11 0 -44 11 -74 25 -30 14 -69
25 -87 25 -67 0 -658 -108 -683 -125 -14 -9 -32 -32 -40 -50 -9 -23 -22 -35
-41 -39 -16 -3 -93 -17 -172 -31 -135 -24 -147 -25 -183 -10 -52 20 -622 22
-680 1 -60 -21 -115 -72 -145 -134 l-28 -57 -5 -680 -5 -680 -268 -970 c-148
-533 -271 -991 -274 -1016 -13 -107 56 -225 159 -269 63 -28 154 -30 210 -7
54 23 123 91 146 145 11 26 129 446 263 934 l243 888 43 0 c24 0 43 -2 43 -4
0 -3 104 -411 230 -907 163 -637 238 -916 255 -946 47 -80 132 -125 232 -125
148 -1 264 113 266 261 0 42 -60 294 -251 1043 l-252 986 2 498 3 499 631 110
c347 61 652 119 676 128 52 20 111 75 141 132 18 33 22 56 21 128 0 79 1 87
17 82 57 -18 137 32 155 96 10 33 10 34 99 47 50 7 458 41 907 76 573 44 821
66 832 76 9 7 16 23 16 35 0 12 7 28 15 35 22 18 18 46 -7 64 -30 21 -71 20
-923 -18z m-985 -307 c0 -5 -11 -21 -25 -34 -31 -32 -78 -33 -99 -3 -20 29
-12 36 54 46 58 9 70 7 70 -9z"/>
</g>
</svg>
);

const locationIcon = (usercolor) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="25.000000pt" height="25.000000pt" viewBox="0 0 2000.000000 2000.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
fill={usercolor} stroke="none">
<path d="M9630 19989 c-1223 -71 -2402 -503 -3378 -1238 -239 -180 -398 -317
-611 -526 -271 -266 -430 -446 -636 -720 -981 -1305 -1407 -2911 -1204 -4545
74 -597 253 -1232 499 -1774 80 -176 5690 -11176 5700 -11176 3 0 1083 2111
2400 4692 1317 2581 2577 5048 2799 5483 529 1036 592 1172 736 1610 281 848
375 1761 274 2660 -124 1108 -539 2151 -1210 3045 -209 277 -367 457 -640 725
-740 725 -1631 1244 -2637 1535 -658 189 -1391 270 -2092 229z m770 -2509
c875 -103 1643 -471 2250 -1080 617 -617 984 -1391 1082 -2275 17 -156 17
-594 0 -750 -98 -888 -470 -1667 -1092 -2285 -538 -535 -1229 -895 -1974
-1030 -238 -43 -380 -54 -666 -54 -482 0 -858 66 -1300 227 -1012 370 -1842
1198 -2213 2207 -166 452 -232 829 -232 1325 1 352 29 584 111 909 240 957
867 1794 1724 2303 490 290 1041 465 1615 513 137 11 567 5 695 -10z"/>
</g>
</svg>
);

let iconAnch =[];

const theIcon = (ammunition, userColor) => {
    console.log(ammunition)
    if ( ammunition === 'flêche') {
        iconAnch = [25, 20];
        return arrowIcon(userColor);
    } else if (ammunition === 'balle') {
        iconAnch = [25, 20];
        return bulletIcon(userColor);
    } else {
        iconAnch = [17, 5];
        return shotgunIcon(userColor);
    }
};

export const icon = (
    userColor,
    ammunition
) => {
    return (
        new L.divIcon({
            html: renderToString(theIcon(ammunition, userColor)),
            className: 'dummy',
            iconAnchor: iconAnch, // point of the icon which will correspond to marker's location
        })
    );
};

export const iconLocation = (
    areaActive
) => {

    

    return (
        new L.divIcon({
            html: renderToString(locationIcon(areaActive ? '#A26C27' : 'grey')),
            className: 'dummy',
            iconAnchor: [17, 20], // point of the icon which will correspond to marker's location
        })
    );
};

