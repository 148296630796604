import React from 'react';

import 'react-toastify/dist/ReactToastify.min.css';
import CarrouselItem from 'components/Carrousel/CarrouselItem/CarrouselItem';
import { today, tomorrow } from 'services/helpers/date';
import moment from 'moment';
import PropTypes from 'prop-types';
import Slider from "react-slick";

const Carrousel = ({
    delayHour,
    tripsData,
    weatherData,
    changeValue,
    weatherLoader,
    tripsLoader,
}) => {


    // Paramêtres du slider
    var settings = {
        dots: true,
        infinite: true,
        initialSlide: moment().hour() <= 12 ? 0 : 1,
            // adaptiveHeight: true,
            speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "inner-carroussel",
        afterChange: function (index) {

            if (index === 0) {
                changeValue('meridiem', 'morning');
                changeValue('date', today());
            } else if (index === 1) {
                changeValue('meridiem', 'evening');
                changeValue('date', today());
            } else if (index === 2) {
                changeValue('meridiem', 'morning');
                changeValue('date', tomorrow());
            }
        }
    };



    const tm = new Date()
    tm.setDate(tm.getDate() + 1);

    const todayMorningTrips = tripsData.filter(trip => trip.date === today() && trip.meridiem === 'morning');
    const todayEveningTrips = tripsData.filter(trip => trip.date === today() && trip.meridiem === 'evening');
    const tomorrowMorningTrips = tripsData.filter(trip => trip.date === tomorrow() && trip.meridiem === 'morning');

    let huntStart = '';
    let huntEnd = '';

    if (weatherData.sunset !== undefined && delayHour !== null) {
        huntStart = (parseInt(weatherData.sunrise.substring(0, 2)) - delayHour) + weatherData.sunrise.substring(2, 5);
        huntEnd = (parseInt(weatherData.sunset.substring(0, 2)) + delayHour) + weatherData.sunset.substring(2, 5);
    }

    return (
        <Slider {...settings}>
            <CarrouselItem
                key={'Ce Matin'}
                title={'Ce Matin'}
                theDay={new Date()}
                theRise={'sunrise'}
                theRiseHour={weatherData.sunrise}
                weatherLoader={weatherLoader}
                tripsLoader={tripsLoader}
                settingsLoader={weatherData.settingsLoader}
                weatherData={weatherData.morning}
                trips={todayMorningTrips}
                huntLimit={huntStart}
            />
            <CarrouselItem
                key={'Ce Soir'}
                title={'Ce Soir'}
                theDay={new Date()}
                theRise={'sunset'}
                theRiseHour={weatherData.sunset}
                weatherLoader={weatherLoader}
                tripsLoader={tripsLoader}
                settingsLoader={weatherData.settingsLoader}
                weatherData={weatherData.evening}
                trips={todayEveningTrips}
                huntLimit={huntEnd}
            />
            <CarrouselItem
                key={'Demain Matin'}
                title={'Demain Matin'}
                theDay={tm}
                theRise={'sunrise'}
                theRiseHour={weatherData.sunrise}
                weatherLoader={weatherLoader}
                tripsLoader={tripsLoader}
                settingsLoader={weatherData.settingsLoader}
                weatherData={weatherData.morningNext}
                trips={tomorrowMorningTrips}
                huntLimit={huntStart}
            />
        </Slider>
    );
};

Carrousel.propTypes = {
    loadTrips: PropTypes.func,
    loadWeather: PropTypes.func,
    tripsData: PropTypes.arrayOf(PropTypes.object),
    citylat: PropTypes.number,
    cityId: PropTypes.number,
    weatherData: PropTypes.object,
    changeValue: PropTypes.func,
    loaders: PropTypes.objectOf(PropTypes.object)
};

export default Carrousel;
