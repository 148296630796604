import React, { useState, useEffect, Fragment } from 'react';
import Layout from 'components/Layout/LayoutContainer';

import moment from 'moment';
import localization from 'moment/locale/fr';
import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';

import Fade from 'react-reveal/Zoom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BallBeat } from 'react-pure-loaders';
import PropTypes from 'prop-types';

const AddNews = ({
    newsId,
    title,
    content,
    cityId,
    roleId,
    newsData,

    loadNews,
    changeValue,
    saveANews,
    updateANews,
    deleteANews,
    newsLoader
}) => {

    const [selectedType, setSelectedType] = useState('write');

    moment.updateLocale('fr', localization)

    useEffect(() => {
        loadNews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    useEffect(() => {
        if (roleId === 3) {
            setSelectedType('write')
        }
    }, [roleId]);


    /**
     * HANDLES
     */
    const handleChangeDebriefingType = (event) => {
        setSelectedType(event.value);
        changeValue('tripId', '');
    }

    const handleChangeSelectValue = event => {
        changeValue('newsId', event.value);
        const selectedNews = newsData.find(currentNews => currentNews.id === event.value)
        if (selectedNews != null) {
            changeValue('title', selectedNews.title);
            changeValue('content', selectedNews.content);
        }
    }

    const handleSaveNews = (event) => {
        event.preventDefault();
        saveANews();
    }

    const handleChangeInputValue = name => event => {
        changeValue(name, event.target.value)
    }

    const handleSubmitModify = (event) => {
        event.preventDefault();
        updateANews();
    }

    const handleSubmitDelete = (event) => {
        event.preventDefault();
        deleteANews();
    }
    /**
     * ARRAYS SELECT
     */
    const newsSelect = () => {

        const selectNewsChoice = [];
        newsData.map((currentNews) => {
            selectNewsChoice.push({
                value: currentNews.id, label:
                    <Fragment>
                        {moment(currentNews.date).format('Do MMMM YYYY')}{' - "'}
                        {currentNews.title}{'" '}
                    </Fragment>
            })
            return false;
        })
        return selectNewsChoice;
    }

    /**
     * JSX FUNCTIONS
     */
    const selectNewsType = () => {

        //Select du type
        const selectDebriefingChoice = [
            { value: 'modify', label: 'Modifier' },
            { value: 'write', label: 'Ecrire' },
        ]

        return (
            <Fade clear duration={500} delay={150}>
                <form >
                    <div className="form-container">
                        <label className="form-label">Mode</label>
                        <Select
                            menuPlacement="bottom"
                            menuPortalTarget={document.querySelector('body')}
                            options={selectDebriefingChoice}
                            value={selectDebriefingChoice.filter(obj => obj.value === selectedType)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={handleChangeDebriefingType}
                        />
                    </div>
                </form>
            </Fade>
        )
    }

    const addNews = () => (
        <Fade clear duration={500} delay={150}>
            <form >
                <div className="form-container">
                    <label className="form-label">Titre</label>
                    <input value={title} type="email" name="title" className="form-input" onChange={handleChangeInputValue('title')} />
                </div>
                {title !== '' &&
                    <>
                        <Fade clear duration={500} delay={250}>
                            <div className="form-container debrifing-text-arearea">
                                <label className="form-label">Commentaire</label>
                                <textarea className="form-input debriefing-input" type="text" value={content} onChange={handleChangeInputValue('content')} />
                            </div>
                        </Fade>
                        <Fade clear duration={500} delay={250}>
                            <button type='submit' className="btn btn-primary" onClick={handleSaveNews}>
                                Soumettre
				        </button>
                        </Fade>
                    </>
                }
            </form>
        </Fade >
    )

    const modifyNews = () => (
        <Fade clear duration={500} delay={150}>
            <form >
                <div className="form-container">
                    <label className="form-label">Choisir une news</label>
                    <Select
                        menuPlacement="bottom"
                        menuPortalTarget={document.querySelector('body')}
                        options={newsSelect()}
                        value={newsSelect().filter(obj => obj.value === newsId)}
                        isSearchable={false}
                        theme={selectStyle}
                        onChange={handleChangeSelectValue}
                    />
                </div>
                {newsId != null &&
                    <>
                        <Fade clear duration={500} delay={0}>
                            <div className="form-container">
                                <label className="form-label">Titre</label>
                                <input value={title} type="email" name="title" className="form-input" onChange={handleChangeInputValue('title')} />
                            </div>
                        </Fade>
                        <Fade clear duration={500} delay={250}>
                            <div className="form-container debrifing-text-arearea">
                                <label className="form-label">Modifier le commentaire</label>
                                <textarea className="form-input debriefing-input" type="text" value={content} onChange={handleChangeInputValue('content')} />
                            </div>
                        </Fade>
                        <div className="debriefing-btn-container">
                            <Fade clear duration={500} delay={320}>
                                <button type='submit' className="btn btn-delete" onClick={handleSubmitDelete}>
                                    Supprimer
				        </button>
                            </Fade>
                            <Fade clear duration={500} delay={250}>
                                <button type='submit' className="btn btn-primary" onClick={handleSubmitModify}>
                                    Modifier
                            </button>
                            </Fade>
                        </div>

                    </>
                }
            </form>
        </Fade>
    )

    return (
        <Layout>
            <div className="container-fluid col-md-6 offset-md-3 align-item-center flex-grow-2">
                <ToastContainer autoClose={1800} />
                <Fade clear duration={500} delay={150}>
                    <h1 className="p-2 text-center">Gestion des News</h1>
                </Fade>

                <div className="loader">
                    < BallBeat margin={'auto'} loading={newsLoader ? true : false} />
                </div>

                {(roleId === 1 || roleId === 2 || roleId === 4) && !newsLoader && selectNewsType()}
                {!newsLoader && selectedType === 'write' && addNews()}
                {!newsLoader && selectedType === 'modify' && modifyNews()}

            </div>
        </Layout>
    );
};

AddNews.propTypes = {
    tripId: PropTypes.number,
    tripsByIdData: PropTypes.array,
    debriefing: PropTypes.string,
    cityId: PropTypes.number,
    roleId: PropTypes.number,
    debriefingsData: PropTypes.array,

    loadTripsById: PropTypes.func,
    loadDebriefings: PropTypes.func,
    changeValue: PropTypes.func,
    saveDebriefing: PropTypes.func,
    saveCheckedDebriefings: PropTypes.func,

};

export default AddNews;
