import React, { useEffect } from "react";

import Layout from 'components/Layout/LayoutContainer';
import Map from 'components/Map/MapContainer';
import AddLocationForm from 'scenes/Admin/AddLocation/AddLocationForm/AddLocationFormContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { BallBeat } from 'react-pure-loaders';

const AddLocation = ({
    cityId,
    cityLat,
    locationsLoader,
    loadLocations,
    locationsData,
    addLocationChoice
}) => {

    // On charge les aires de restriction si elles ne sont pas dans le store
    useEffect(() => {
        cityId !== null && loadLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId !== null, locationsData === []]);

    return (
        <Layout>
            <div className="container-fluid flex-grow-2">
                <h1 className="p-2 text-center">Gestion des lieu-dits</h1>
                <ToastContainer autoClose={1800} />

                <div className="loader">
                    < BallBeat margin={'auto'} loading={locationsLoader ? true : false} />
                </div>
                {!locationsLoader &&
                    <div className="d-flex row" >
                        <div className="col-md-6 d-flex justify-content-center">
                            {/* {Attend d'avoir des coordonnées avant de charger la map sinon erreur} */}
                            {cityLat != null && <Map
                                mapClass={'map-location'}
                                markers={addLocationChoice}
                                restrictedAreas={true}
                                wind={false}
                            />}

                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            {/* {newLocationForm} */}
                            <AddLocationForm />
                        </div>
                    </div>
                }

            </div>
        </Layout>
    );
};

export default AddLocation;
