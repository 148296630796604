/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from 'services/security/helpers'
import { loadDebriefings } from 'services/ducks/modules/dataList';

import {
  SAVE_DEBRIEFING,
  SAVE_CHECKED_DEBRIEFING,
} from 'services/ducks/modules/debriefing';

const debriefingMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    case SAVE_DEBRIEFING:
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/debriefing/write/`,
        data: {
          cityId: store.getState().auth.cityId,
          tripId: store.getState().debriefing.tripId,
          comment: store.getState().debriefing.comment,
          boarObs: store.getState().debriefing.boarObs,
          foxObs: store.getState().debriefing.foxObs,
        },
        headers: {
          Authorization: `Bearer ${getCookie('token')}`
        }
      })
        .then(response => {
          console.log('SAVE_DEBRIEFING SUCCESS', response);
          // store.dispatch(saveSettings(Object.entries(response.data.setting)));
          toast.success(response.data.message);
          store.dispatch(loadDebriefings());
        })
        .catch(error => {
          console.log('SAVE_DEBRIEFING ERROR', error);
          toast.error(error.response.data.error);
        });

      next(action);
      break;

      case SAVE_CHECKED_DEBRIEFING:
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_API}/debriefing/check/`,
          data: {
            tripId: action.tripId,
          },
          headers: {
            Authorization: `Bearer ${getCookie('token')}`
          }
        })
          .then(response => {
            console.log('SAVE_DEBRIEFING SUCCESS', response);
            // store.dispatch(saveSettings(Object.entries(response.data.setting)));
            // toast.success(response.data.message);
            // store.dispatch(loadDebriefings());
          })
          .catch(error => {
            console.log('SAVE_DEBRIEFING ERROR', error);
            toast.error(error.response.data.error);
          });
  
        next(action);
        break;

    default:
      next(action);
  }
};

export default debriefingMiddleware;