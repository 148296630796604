import React from "react";
import { Marker, Tooltip } from 'react-leaflet'
import { iconLocation } from 'services/helpers/icons';

import PropTypes from 'prop-types';

const LocationsMarkers = ({
	locationsData,
}) => {

	return (
		locationsData.map((location) => {
			if (location.active === true) {
				return (<Marker key={`${location.name}${location.id}`} position={[location.lat, location.lng]} icon={iconLocation(true)} label='JD'>
					<Tooltip className="tooltip-marker" direction="center" offset={[0, -28]} opacity={0.81} permanent background-color="none">
						<span>{`${location.name}`}</span>
					</Tooltip>
				</Marker>
				)
			}
			return false;
		})
	);
};

LocationsMarkers.propTypes = {
	locationsData: PropTypes.arrayOf(
		PropTypes.object
	),
};

export default LocationsMarkers;