// == Action types ======================

export const CHANGE_CONTACT_VALUE = 'CHANGE_CONTACT_VALUE';
export const SIGNOUT = 'SIGNOUT';

// == Action creators

export const changeValue = (name, value) => (
  {
  type: CHANGE_CONTACT_VALUE,
  name,
  value: value,
});

const initialState = {
  contactId: null,
  typeId: null,
  card: null,
};

// == Reducer
function contactReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_CONTACT_VALUE:
      console.log(action.name)
      console.log(action.value)
      return {
        ...state,
        [action.name]: action.value,
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default contactReducer;
