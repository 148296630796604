// == Action types ======================

export const CHANGE_DEBRIEFING_VALUE = 'CHANGE_DEBRIEFING_VALUE';
export const SAVE_DEBRIEFING = 'SAVE_DEBRIEFING';
export const SAVE_CHECKED_DEBRIEFING = 'SAVE_CHECKED_DEBRIEFING';
export const SIGNOUT = 'SIGNOUT';


// == Action creators

export const changeValue = (name, value) => ({
  type: CHANGE_DEBRIEFING_VALUE,
  name,
  value,
});

export const saveDebriefing = () => ({
  type: SAVE_DEBRIEFING,
});

export const saveCheckedDebriefings = (tripId) => ({
  type: SAVE_CHECKED_DEBRIEFING,
  tripId
});

const initialState = {
  tripId: null,
  comment: '',
  boarObs: null,
  foxObs: null
  
};

// == Reducer
function debriefingReducer(state = initialState, action = {}) {

  switch (action.type) {

    case CHANGE_DEBRIEFING_VALUE:
      // console.log(action.name)
      return {
        ...state,
        [action.name]: action.value,
      };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}

export default debriefingReducer;
