import { connect } from 'react-redux';

import { loadConnectionReport } from 'services/ducks/modules/dataList';

import ConnectionReport from 'scenes/Admin/ConnectionReport/ConnectionReport';

const mapStateToProps = (state) => ({
  cityId: state.auth.cityId,
  connectionReportData: state.dataList.connectionReportData,
  connectionRecordLoader: state.loader.connectionRecordLoader
});

const mapDispatchToProps = (dispatch) => ({

  loadConnectionReport: () => {
    dispatch(loadConnectionReport());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectionReport);