import { connect } from 'react-redux';

// import { loadProfilId } from 'src/actions/profil';
import { changeValue, loadProfile, submitPrivateProfile } from 'services/ducks/modules/auth';
import { loadMemberships } from 'services/ducks/modules/dataList';

import Private from 'scenes/Core/Private/Private';

const mapStateToProps = (state) => ({
  role: state.auth.role,
  cityId: state.auth.cityId,
  firstname: state.auth.firstname,
  lastname: state.auth.lastname,
  licenseNumber: state.auth.licenseNumber,
  membershipId: state.auth.membershipId,
  membershipsData: state.dataList.membershipsData,
  color: state.auth.color,
  colorPicker: state.auth.colorPicker,
  colorBtnText: state.auth.colorBtnText,
  phone: state.auth.phone,
  email: state.auth.email,
  password: state.auth.password,
  buttonText: state.auth.buttonText,
  profileLoader: state.loader.profileLoader,
  membershipsLoader: state.loader.membershipsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  loadProfile: (history) => {
    dispatch(loadProfile(history));
  },
  loadMemberships: (history) => {
    dispatch(loadMemberships());
  },
  submitPrivateProfile: (history) => {
    dispatch(submitPrivateProfile(history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Private);