import { connect } from 'react-redux';

import { changeValue, submitForgot } from 'services/ducks/modules/auth';
import { loadDepartments, loadCities } from 'services/ducks/modules/dataList';

import Forgot from 'scenes/Auth/Forgot/Forgot';

const mapStateToProps = (state) => ({
  departmentsData: state.dataList.departmentsData,
  departmentId: state.auth.departmentId,
  citiesData: state.dataList.citiesData,
  cityId: state.auth.cityId,
  email: state.auth.email,
  buttonText: state.auth.buttonText,
  departmentsLoader: state.loader.departmentsLoader,
  citiesLoader: state.loader.citiesLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  submitForgot: (history) => {
    dispatch(submitForgot(history));
  },
  loadDepartments: () => {
    dispatch(loadDepartments());
  },
  loadCities: () => {
    dispatch(loadCities());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Forgot);