import { connect } from 'react-redux';

import { signout, loadProfile } from 'services/ducks/modules/auth';
import { loadSettings } from 'services/ducks/modules/settings';

import Layout from 'components/Layout/Layout';

const mapStateToProps = (state) => ({
  profileLoaded: state.auth.profileLoaded,
  city: state.auth.city,
  department: state.auth.department,
  roleId: state.auth.roleId,
  limitRegistration: state.settings.limitRegistration,
  settingsData: state.dataList.settingsData,
  notification: state.notification.notification,
  socketIoConnectedState: state.auth.socketIoConnectedState,
});

const mapDispatchToProps = (dispatch) => ({
  signout: (history) => {
    dispatch(signout(history));
  },
  loadProfile: () => {
    dispatch(loadProfile());
  },
  loadSettings: () => {
    dispatch(loadSettings());
  },

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);