import 'components/Map/WindDegreesLayer/leaflet-velocity-degrees';
import L from 'leaflet';
import { useMap, useMapEvents } from 'react-leaflet';
import dataLayer from 'components/Map/WindDegreesLayer/wind2.json'
import { useEffect, useState } from 'react';
import { today, tomorrow } from 'services/helpers/date';

import PropTypes from 'prop-types';

const WindDegreesLayer = ({
  meridiem,
  date,
  weatherData,

}) => {

  const [selectedWindColor, setSelectedWindColor] = useState('rgb(127, 82, 22)');

  const map = useMap()

  useMapEvents({
    baselayerchange: (event) => {
      if (event.name === 'OpenStreetMap') {
        console.log('passe layer')
        setSelectedWindColor('rgb(0,0,0)');
        map.removeLayer(velocityLayer);
      } else {
        console.log('passe layer')
        setSelectedWindColor('rgb(255,255,255)');
        map.removeLayer(velocityLayer);
      }
    },
  })

  let velocityLayer = L.velocityLayer({
    displayValues: false,
    displayOptions: {
      velocityType: 'Global Wind',
      position: 'bottomleft',
      emptyString: 'No velocity data',
      angleConvention: 'bearingCW',
      displayPosition: 'bottomleft',
      displayEmptyString: 'No velocity data',
      speedUnit: 'm'
    },

    // OPTIONAL
    minVelocity: 0,          // used to align color scale
    maxVelocity: 10,         // used to align color scale
    velocityScale: 0.005,    // modifier for particle animations, arbitrarily defaults to 0.005
    colorScale: [selectedWindColor],     // define your own array of hex/rgb colors
    particleMultiplier: 0.00022
  });

  velocityLayer.setData(dataLayer);

  if (Object.keys(weatherData).length !== 0 && meridiem === 'morning' && date === today()) {
    velocityLayer.setWind(weatherData.morning.dirWind, weatherData.morning.windSpeed);
  } else if (Object.keys(weatherData).length !== 0 && meridiem === 'evening' && date === today()) {
    velocityLayer.setWind(weatherData.evening.dirWind, weatherData.evening.windSpeed);
  } else if (Object.keys(weatherData).length !== 0 && meridiem === 'morning' && date === tomorrow()) {
    velocityLayer.setWind(weatherData.morningNext.dirWind, weatherData.morningNext.windSpeed);
  }

  useEffect(() => {
    map.addLayer(velocityLayer)

  }, [map, velocityLayer]);

  return () => {
    map.removeLayer(velocityLayer);
  };

}

WindDegreesLayer.propTypes = {
  meridiem: PropTypes.string,
  date: PropTypes.string,
  weatherData: PropTypes.object
}

export default WindDegreesLayer;  