import { connect } from 'react-redux';

import { changeValue } from 'services/ducks/modules/map';

import AddAreaPolygons from 'components/Map/AddAreaPolygons/AddAreaPolygons';

const mapStateToProps = (state) => ({
    restrictedAreasData: state.dataList.restrictedAreasData,
    restrictedAreaId: state.map.restrictedAreaId,
    areaName: state.map.areaName,
	  areaActive: state.map.areaActive,
	  areaCoordinates: state.map.areaCoordinates,
	  addAreaChoice: state.map.addAreaChoice,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAreaPolygons);