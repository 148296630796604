import { connect } from 'react-redux';

import { loadLocations } from 'services/ducks/modules/dataList';

import AddLocation from 'scenes/Admin/AddLocation/AddLocation';

const mapStateToProps = (state) => ({
  cityId: state.auth.cityId,
  cityLat: state.map.cityLat,
  addLocationChoice: state.admin.addLocationChoice,
  locationsData: state.dataList.locationsData,
  locationsLoader: state.loader.locationsLoader
});

const mapDispatchToProps = (dispatch) => ({
  loadLocations: () => {
    dispatch(loadLocations());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddLocation);