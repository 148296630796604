import React from 'react';

import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';

import HttpsRedirect from 'react-https-redirect';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
//Pickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import 'services/stylesheets/main.scss';
import Routes from './Routes';
// import store from './store';
import store from 'services/ducks/configureStore';


if (process.env.REACT_APP_ENV !== 'development') {
    console.log = function () { };
}

// ReactDOM.render(<Routes />, document.getElementById('root'));
const rootReactElement = (
    <ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
        <HttpsRedirect>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Provider store={store}>
                    <Routes />
                </Provider>
            </MuiPickersUtilsProvider>
        </HttpsRedirect>
    </ClearBrowserCacheBoundary>
);
// 2. La cible du DOM (là où la structure doit prendre vie dans le DOM)
const target = document.getElementById('root');
// 3. Déclenchement du rendu de React (virtuel) => DOM (page web)
render(rootReactElement, target);