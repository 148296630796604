import React, { useEffect } from 'react';
import Layout from 'components/Layout/LayoutContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import moment from 'moment';
import localization from 'moment/locale/fr';

import Toggle from 'react-toggle';
import { TimePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';

import { BallBeat } from 'react-pure-loaders';

import PropTypes from 'prop-types';

const Settings = ({
    eveningLimitation,
    eveningLimitationHour,
    morningLimitation,
    morningLimitationHour,
    limitRegistration,
    forceDebriefing,
    delayHour,
    changeValue,
    saveHourLimitation,
    saveToogleLimitation,
    saveToogleRegistrationMode,
    saveDelayHour,
    saveToogleForceDebriefing,
    settingsLoader,
    loadSettings
}) => {

    moment.updateLocale('fr', localization)
    
    useEffect(() => {
        loadSettings()
    }, [loadSettings]);

    const selectHoursArray = [
        { label: '1 heure', value: 1 },
        { label: '2 heures', value: 2 },
    ]

    const materialTheme = createMuiTheme({
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#C49D6D',
                },
            },
            MuiPickersClockPointer: {
                pointer: {
                    backgroundColor: '#C49D6D',
                },
                thumb: {
                    border: '14px solid #C49D6D',
                },
                noPoint: {
                    backgroundColor: '#C49D6D',
                }
            },
            MuiPickersClock: {
                pin: {
                    backgroundColor: '#C49D6D',
                },
            },
            MuiButton: {
                textPrimary: {
                    color: '#C49D6D',
                }
            },
            MuiInput: {
                underline: {
                    color: 'white',//input color focus of not  
                    backgroundColor: "#C49D6D",//background color of whole input 
                    border: 'none',
                    '&&&:before': {
                        borderBottom: 'none'
                    },
                    '&&&:after': {
                        borderBottom: 'none'
                    },
                    '&:hover': {
                        backgroundColor: '#edcda6'
                    },
                },
                input: {
                    textAlign: 'center',
                    cursor: 'pointer',
                    width: '80px',
                },
                root: {
                    borderRadius: '15px',
                    fontWeight: '600',
                }
            }
        },
    });

    const addMorningTripHourLimit = (
        <>
            <div className='setting-block'>
                <h4>Limitation des heures d'ajout pour le matin</h4>
                <h5>Si le paramêtre est activé, les sorties du matin sont systématiquement désactivées, l'heure correspond à la limite pour le lendemain matin</h5>
                <div className='setting-options'>
                    <div className="setting-toggle-container">
                        <Toggle
                            checked={morningLimitation}
                            name='morningLimitation'
                            value={morningLimitation}
                            onChange={() => {
                                changeValue('morningLimitation', !morningLimitation);
                                saveToogleLimitation('morningLimitation', !morningLimitation); // ! car pas le temps de se recharger
                            }}
                        />
                    </div>
                    <ThemeProvider theme={materialTheme}>
                        <TimePicker
                            disabled={!morningLimitation}
                            clearable
                            ampm={false}
                            // Date d'aujourdhui avec les heures et minutes de la bdd
                            value={moment().hours(morningLimitationHour.slice(0, 2)).minutes(morningLimitationHour.slice(2, 2))}
                            onChange={(hour) => {
                                console.log(hour)
                                changeValue('morningLimitationHour', hour.format('HH:mm'))
                                saveHourLimitation('morningLimitationHour', hour.format('HH:mm'))
                            }}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </>
    )

    const addEveningTripHourLimit = (
        <>
            <div className='setting-block'>
                <h4>Limitation des heures d'ajout pour le soir</h4>

                <div className='setting-options'>
                    <div className="setting-toggle-container">
                        <Toggle
                            checked={eveningLimitation}
                            name='eveningLimitation'
                            value={eveningLimitation}
                            onChange={() => {
                                console.log('test')
                                changeValue('eveningLimitation', !eveningLimitation);
                                saveToogleLimitation('eveningLimitation', !eveningLimitation); // ! car pas le temps de se recharger
                            }}
                        />
                    </div>
                    <ThemeProvider theme={materialTheme}>
                        <TimePicker
                            disabled={!eveningLimitation}
                            clearable
                            ampm={false}
                            value={moment().hours(eveningLimitationHour.slice(0, 2)).minutes(eveningLimitationHour.slice(2, 2))}
                            onChange={(hour) => {
                                console.log(hour)
                                changeValue('eveningLimitationHour', hour.format('HH:mm'))
                                saveHourLimitation('eveningLimitationHour',  hour.format('HH:mm'));
                            }}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </>
    )

    const addRegistrationMode = (
        <>
            <div className='setting-block'>
                <h4>Restreindre les inscriptions aux admins</h4>

                <div className='setting-options'>
                    <div className="setting-toggle-container">
                        <Toggle
                            checked={limitRegistration}
                            name='limitRegistration'
                            value={limitRegistration}
                            onChange={() => {
                                changeValue('limitRegistration', !limitRegistration);
                                saveToogleRegistrationMode(!limitRegistration); // ! car pas le temps de se recharger
                            }}
                        />
                    </div>

                </div>
            </div>
        </>
    )

    const setHuntingHourLimitation = (
        <>
            <div className='setting-block'>
                <h4>Heures de chasse avant/après</h4>

                <div className='setting-options'>
                    <div className="setting-select-container">
                        <Select
                            menuPortalTarget={document.querySelector('body')}
                            options={selectHoursArray}
                            value={selectHoursArray.filter(obj => obj.value === delayHour)}
                            isSearchable={false}
                            theme={selectStyle}
                            onChange={(event) => {
                                changeValue(event.value)
                                saveDelayHour(event.value)
                            }}
                        />
                    </div>

                </div>
            </div>
        </>
    )

    const setForceDebriefing = (
        <>
            <div className='setting-block'>
                <h4>Forcer les comptes rendus</h4>

                <div className='setting-options'>
                    <div className="setting-toggle-container">
                        <Toggle
                            checked={forceDebriefing}
                            name='forceDebriefing'
                            value={forceDebriefing}
                            onChange={() => {
                                changeValue('forceDebriefing', !forceDebriefing);
                                saveToogleForceDebriefing(!forceDebriefing); // ! car pas le temps de se recharger
                            }}
                        />
                    </div>

                </div>
            </div>
        </>
    )

    return (
        <Layout>
            <h2 className="pt-5 text-center">Paramêtres administrateur</h2>
            <div className="container-fluid row flex-grow-2">
                <div className="col-md-12 row settings-container">
                    <ToastContainer autoClose={3000} />
                    <div className="loader">
                        < BallBeat margin={'auto'} loading={settingsLoader ? true : false} />
                    </div>
                    <div className="offset-md-2 col-md-4 ">
                        {!settingsLoader && addMorningTripHourLimit}
                        {!settingsLoader && addEveningTripHourLimit}
                    </div>
                    <div className="col-md-4">
                        {!settingsLoader && addRegistrationMode}
                        {!settingsLoader && setHuntingHourLimitation}
                        {!settingsLoader && setForceDebriefing}
                    </div>
                    <div className="col-md-4">
                        
                    </div>


                </div>
            </div>
        </Layout>
    );
};

Settings.propTypes = {
    eveningLimitation: PropTypes.bool,
    eveningLimitationHour: PropTypes.string,
    morningLimitation: PropTypes.bool,
    morningLimitationHour: PropTypes.string,
    changeValue: PropTypes.func,
    saveHourLimitation: PropTypes.func,
    saveToogleLimitation: PropTypes.func,
    loadSettings: PropTypes.func,
};

export default Settings;
