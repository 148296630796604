import React from "react";

import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';
import { today, tomorrow } from 'services/helpers/date';

import Fade from 'react-reveal/Zoom';

import PropTypes from 'prop-types';

const ModifyForm = ({
	tripIndex,
	date,
	meridiem,
	ammunition,
	locationId,
	locationsData,
	changeValue,
	updateTrip,
	deleteTrip,
	state,
}) => {


	/**
	 * Update the trip
	 * @param {*} event 
	 * 
	 */
	const clickSubmitModify = (event) => {

		event.preventDefault();
		updateTrip(tripIndex);
	}

	/**
	 * Delete The trip
	 * @param {*} event 
	 * 
	 */
	const clickSubmitDelete = (event) => {

		event.preventDefault();
		deleteTrip(tripIndex);
	}

	/**
	 * Controlled field function
	 */
	const handleChange = name => event => {
		if (name === 'locationId') {
			console.log(tripIndex);
			console.log(name);
			console.log(parseInt(event.value));

			return changeValue(tripIndex, name, parseInt(event.value))
		} else if (name === 'meridiem') {
			const meridiemDate = event.value.split('@');
			changeValue(tripIndex, 'meridiem', meridiemDate[1])
			changeValue(tripIndex, 'date', meridiemDate[2])
		} else {
			changeValue(tripIndex, name, event.value)

		}
	};

	// Select des meridiems
	const selectMeridiemChoice = [
		{ value: `@morning@${today()}`, label: 'Ce matin' },
		{ value: `@evening@${today()}`, label: 'Ce soir' },
		{ value: `@morning@${tomorrow()}`, label: 'Demain matin' },
	]

	//Select des muns
	const selectAmmunitionChoice = [
		{ value: 'balle', label: 'Balle' },
		{ value: 'plomb', label: 'Plomb' },
		{ value: 'flêche', label: 'Flêche' },
	]

	// Données des select location
	const locationSelectOptions = [];

	// Mapping des lieu-dits
	locationsData.map(location =>
		locationSelectOptions.push({ value: location.id, label: location.name })
	)

	return (
			<form >
				<Fade clear duration={500} delay={50}>
					<div className="form-container">
						<label className="form-label">Période</label>
						<Select
							menuPortalTarget={document.querySelector('body')}
							options={selectMeridiemChoice}
							value={selectMeridiemChoice.filter(obj => obj.value === `@${meridiem}@${date}`)}
							isSearchable={false}
							theme={selectStyle}
							onChange={handleChange('meridiem')}
						/>
					</div>
				</Fade>

				<Fade clear duration={500} delay={100}>
					<div className="form-container">
						<label className="form-label">Type de munition</label>
						<Select
							menuPortalTarget={document.querySelector('body')}
							options={selectAmmunitionChoice}
							value={selectAmmunitionChoice.filter(obj => obj.value === ammunition)}
							isSearchable={false}
							theme={selectStyle}
							onChange={handleChange('ammunition')}
						/>
					</div>
				</Fade>
				<Fade clear duration={500} delay={150}>
					<div className="form-container">
						<label className="form-label">Lieu-dit</label>
						<Select
							menuPlacement="top"
							menuPortalTarget={document.querySelector('body')}
							options={locationSelectOptions}
							value={locationSelectOptions.filter(obj => obj.value === locationId)}
							isSearchable={false}
							theme={selectStyle}
							onChange={handleChange('locationId')}
						/>
					</div>
				</Fade>
				<div>
					<Fade clear duration={500} delay={320}>
						<button type='submit' className="btn btn-delete" onClick={clickSubmitDelete}>
							Supprimer
				        </button>
					</Fade>
					<Fade clear duration={500} delay={250}>
						<button type='submit' className="btn btn-primary" onClick={clickSubmitModify}>
						{state === 0 ? 'Valider' :'Modifier'}
				        </button>
					</Fade>
				</div>
			</form >
	)

};

ModifyForm.propTypes = {
	tripIndex: PropTypes.number,
	date: PropTypes.string,
	meridiem: PropTypes.string,
	ammunition: PropTypes.string,
	locationId: PropTypes.number,
	locationsData: PropTypes.arrayOf(
		PropTypes.object
	),
	changeValue: PropTypes.func,
	updateTrip: PropTypes.func,
	deleteTrip: PropTypes.func
};

export default ModifyForm;