import React from 'react';

import { Icon } from "@iconify/react";

import daysunny from '@iconify/icons-wi/day-sunny';
import snow from '@iconify/icons-wi/snow';
import daysleet from '@iconify/icons-wi/day-sleet';
import dayrain from '@iconify/icons-wi/day-rain';
import dayhail from '@iconify/icons-wi/day-hail';
import fog from '@iconify/icons-wi/fog';
import daycloudy from '@iconify/icons-wi/day-cloudy';
import rain from '@iconify/icons-wi/rain';
import cloud from '@iconify/icons-wi/cloud';
import sleet from '@iconify/icons-wi/sleet';
import daylightning from '@iconify/icons-wi/day-lightning';
import cloudy from '@iconify/icons-wi/cloudy';
import thunderstorm from '@iconify/icons-wi/thunderstorm';
import daysnow from '@iconify/icons-wi/day-snow';
import showers from '@iconify/icons-wi/showers';

import wind from '@iconify/icons-wi/wind-direction';

export const weatherIcons = {
    'averses-de-neige-faible': <Icon className="weather-icon" height={'1.5em'} icon={snow} />,
    'averses-de-pluie-faible': <Icon className="weather-icon" height={'1.5em'} icon={daysleet} />,
    'averses-de-pluie-forte': <Icon className="weather-icon" height={'1.5em'} icon={dayrain} />,
    'averses-de-pluie-moderee': <Icon className="weather-icon" height={'1.5em'} icon={dayhail} />,
    'brouillard': <Icon className="weather-icon" height={'1.5em'} icon={fog} />,
    'ciel-voile': <Icon className="weather-icon" height={'1.5em'} icon={daycloudy} />,
    'couvert-avec-averses': <Icon className="weather-icon" height={'1.5em'} icon={rain} />,
    'developpement-nuageux': <Icon className="weather-icon" height={'1.5em'} icon={cloud} />,
    'eclaircies': <Icon className="weather-icon" height={'1.5em'} icon={daycloudy} />,
    'ensoleille': <Icon className="weather-icon" height={'1.5em'} icon={daysunny} />,
    'faiblement-nuageux': <Icon className="weather-icon" height={'1.5em'} icon={daycloudy} />,
    'faiblement-orageux': <Icon className="weather-icon" height={'1.5em'} icon={daylightning} />,
    'faibles-passages-nuageux': <Icon className="weather-icon" height={'1.5em'} icon={daycloudy} />,
    'fortement-nuageux': <Icon className="weather-icon" height={'1.5em'} icon={cloudy} />,
    'fortement-orageux': <Icon className="weather-icon" height={'1.5em'} icon={thunderstorm} />,
    'neige-faible': <Icon className="weather-icon" height={'1.5em'} icon={daysnow} />,
    'neige-forte': <Icon className="weather-icon" height={'1.5em'} icon={daysnow} />,
    'neige-moderee': <Icon className="weather-icon" height={'1.5em'} icon={daysnow} />,
    'nuit-avec-averses-de-neige-faible': <Icon className="weather-icon" height={'1.5em'} icon={daysnow} />,
    'nuit-avec-averses': <Icon className="weather-icon" height={'1.5em'} icon={rain} />,
    'nuit-avec-developpement-nuageux': <Icon className="weather-icon" height={'1.5em'} icon={cloud} />,
    'nuit-bien-degagee': <Icon className="weather-icon" height={'1.5em'} icon={daysunny} />,
    'nuit-claire': <Icon className="weather-icon" height={'1.5em'} icon={daysunny} />,
    'nuit-claire-et-stratus': <Icon className="weather-icon" height={'1.5em'} icon={daycloudy} />,
    'nuit-faiblement-orageuse': <Icon className="weather-icon" height={'1.5em'} icon={daycloudy} />,
    'nuit-legerement-voilee': <Icon className="weather-icon" height={'1.5em'} icon={daycloudy} />,
    'nuit-nuageuse': <Icon className="weather-icon" height={'1.5em'} icon={cloud} />,
    'orage-modere': <Icon className="weather-icon" height={'1.5em'} icon={daylightning} />,
    'pluie-et-neige-melee': <Icon className="weather-icon" height={'1.5em'} icon={snow} />,
    'pluie-et-neige-melee-faible': <Icon className="weather-icon" height={'1.5em'} icon={snow} />,
    'pluie-et-neige-melee-moderee': <Icon className="weather-icon" height={'1.5em'} icon={snow} />,
    'pluie-faible': <Icon className="weather-icon" height={'1.5em'} icon={sleet} />,
    'pluie-forte': <Icon className="weather-icon" height={'1.5em'} icon={rain} />,
    'pluie-moderee': <Icon className="weather-icon" height={'1.5em'} icon={showers} />,
    'stratus': <Icon className="weather-icon" height={'1.5em'} icon={cloud} />,
    'stratus-se-dissipant': <Icon className="weather-icon" height={'1.5em'} icon={cloud} />
};


export const windDirection = (degrees) => {
    // return <Icon className="weather-icon" icon={wind} rotate={`${degrees}deg`} />
    const formattedDegrees = degrees + 180;
    return <Icon className="weather-icon" icon={wind} height={'1.5em'} style={{ transform: `rotate(${formattedDegrees}deg)` }} />
}