import { connect } from 'react-redux';

import { changeValue, updateTrip, deleteTrip } from 'services/ducks/modules/dataList';

import ModifyForm from 'scenes/Core/Modify/ModifyForm/ModifyForm';

const mapStateToProps = (state, ownProps) => {console.log(state.trip.locationId); return ({
  locationsData: state.dataList.locationsData,
})};

const mapDispatchToProps = (dispatch) => ({
  changeValue: (tripIndex, name, value) => {
    dispatch(changeValue(tripIndex, name, value));
  },
  updateTrip: (tripIndex) => {
    dispatch(updateTrip(tripIndex));
  },
  deleteTrip: (tripIndex) => {
    dispatch(deleteTrip(tripIndex));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModifyForm);