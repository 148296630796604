import React from 'react';
import PropTypes from 'prop-types';

const SubmitBtn = ({
    submitBtnText,
    tripId,
    saveTakings,
    saveTakingsInStore,
    takings
}) => {

    const clickSubmit = event => {
        event.preventDefault();
        saveTakings();
        saveTakingsInStore(tripId, takings);
    };

    return (
        <form>
            <div className='form-container'>
                <button className="btn btn-primary " onClick={clickSubmit}>
                    {submitBtnText}
                </button>
            </div>
        </form>
    );
};

SubmitBtn.propTypes = {
    changesaveTakingsValue: PropTypes.func,
};
export default SubmitBtn;
