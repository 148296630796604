import { connect } from 'react-redux';
import { changeValue } from 'services/ducks/modules/map';
import TripsMarkers from 'components/Map/TripsMarkers/TripsMarkers';

const mapStateToProps = (state) => ({
  meridiem: state.map.meridiem,
  date: state.map.date,
  tripsData: state.dataList.tripsData,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripsMarkers);