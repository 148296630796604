import { connect } from 'react-redux';

// import { loadProfilId } from 'src/actions/profil';
import { loadProfile } from 'services/ducks/modules/auth';
import { loadUsers, loadLocations, loadRestrictedAreas } from 'services/ducks/modules/dataList';
import { changeValue, submitTrip } from 'services/ducks/modules/trip';
import { loadSettings } from 'services/ducks/modules/settings';

import AddForm from 'scenes/Core/Add/AddForm/AddForm';

const mapStateToProps = (state) => ({
  ownUserId: state.auth.id,
  cityId: state.auth.cityId,
  userId: state.trip.userId,
  roleId: state.auth.roleId,
  meridiem: state.trip.meridiem,
  ammunition: state.trip.ammunition,
  locationId: state.trip.locationId,
  limitRegistration: state.settings.limitRegistration,
  usersData: state.dataList.usersData,
  locationsData: state.dataList.locationsData,
  settingsData: state.dataList.settingsData,
  usersLoader: state.loader.usersLoader,
  settingsLoader: state.loader.settingsLoader,
  locationsLoader: state.loader.locationsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  loadLocations: () => {
    dispatch(loadLocations());
  },
  loadRestrictedAreas: () => {
    dispatch(loadRestrictedAreas());
  },
  loadProfile: () => {
    dispatch(loadProfile());
  },
  loadUsers: () => {
    dispatch(loadUsers());
  },
  loadSettings: () => {
    dispatch(loadSettings());
  },
  submitTrip: () => {
    dispatch(submitTrip());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddForm);