import React, { useEffect } from 'react';
import Layout from 'components/Layout/LayoutContainer';
import Select from 'react-select';
import { selectStyle } from 'services/helpers/select';

import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { BallBeat } from 'react-pure-loaders';

import PropTypes from 'prop-types';

const Contacts = ({
    usersData,
    bloodHandlersData,
    cityId,
    departmentId,
    typeId,
    card,
    usersLoader,
    bloodHandlersLoader,
    userRole,

    loadUsers,
    loadBloodHandlers,
    changeValue
}) => {

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    useEffect(() => {
        loadBloodHandlers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentId]);

    // Remise à zero de l'id du contact si le typeId change
    useEffect(() => {
        changeValue('card', null)
        changeValue('contactId', null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeId]);

    let selectTypeChoice = [
        { value: 1, label: 'Chasseur' },
        { value: 2, label: 'Conducteur de chien de sang' },
    ]

    let selectUsersChoice = []
    if(userRole === 'User' ) {
        usersData.map(user => (
            user.state === true && selectUsersChoice.push({ value: user.id, label: `${user.lastname} ${user.firstname}` })
        ))
    } else {
        usersData.map(user => (
            user.state === true ?
            selectUsersChoice.push({ value: user.id, label: `${user.lastname} ${user.firstname}` }) :
            selectUsersChoice.push({ value: user.id, label: `${user.lastname} ${user.firstname} (Inactif)` })
        ))
    }
   
    let selectBloodHandlersChoice = []
    bloodHandlersData.map(bloodHandler => (
        selectBloodHandlersChoice.push({ value: bloodHandler.id, label: `${bloodHandler.lastname} ${bloodHandler.firstname}` })
    ))

    const handleChangeTypeId = (e) => {
        changeValue('typeId', e.value)
        changeValue('card', null);
    }

    const handleLoadUserCard = (e) => {
        changeValue('contactId', e.value);
        changeValue('card', usersData.find(user => user.id === e.value));
    }

    const handleLoadBloodHandlerCard = (e) => {
        changeValue('contactId', e.value);
        changeValue('card', bloodHandlersData.find(bloodHandler => bloodHandler.id === e.value));
    }

    const userTypeSelect = () => {

        return (
            <div className="form-container">
                <label className="form-label">Selectionner un type d'utilisateur</label>
                <Select
                    options={selectTypeChoice}
                    isSearchable={false}
                    theme={selectStyle}
                    onChange={handleChangeTypeId}
                />
            </div>
        )
    };

    const userSelect = () => {

        return (
            <div className=" mt-3 form-container">
                <label className="form-label">Selectionner un utilisateur</label>
                    <Select
                        options={selectUsersChoice}
                        isSearchable={false}
                        theme={selectStyle}
                        onChange={handleLoadUserCard}
                    />
            </div>
        )
    };

    const bloodDogHandlerSelect = () => {

        // let selectUsersChoice = []
        // usersData.map(user => (
        //     selectUsersChoice.push({ value: user.id, label: `${user.lastname} ${user.firstname}` })
        // ))

        return (
            <div className="form-container">
                <label className="form-label">Selectionner un conducteur de chien de sang</label>
                    <Select
                        options={selectBloodHandlersChoice}
                        isSearchable={false}
                        theme={selectStyle}
                        onChange={handleLoadBloodHandlerCard}
                    />
            </div>
        )
    };

    const contactCard = () => {
        let phoneFormatted = card.phone.toString().replace(/\d{2}(?=.)/g, '$& ');
        return (

            <div className='form-container block-contact text-center '>
                <h2 className='contacts-name'>{`${card.firstname} ${card.lastname}`}</h2>
                {typeId === 1 && <h4 className='contacts-member'>{`Membre ${card.membership.name}`}</h4>}
                {typeId === 2 && <h4 className='contacts-member'>{card.commune}</h4>}
                <div className={typeId === 2 ? "container-contacts b" : "container-contacts"}>
                    <FontAwesomeIcon color='#edcda6' icon={faPhone} />
                    <div className='contacts-elements'>
                        <a href={`tel:${card.phone}`}>{phoneFormatted}</a>
                    </div>
                </div>
                { typeId === 1 && <div className="container-contacts">
                    <FontAwesomeIcon color='#edcda6' icon={faEnvelope} />
                    <div className='contacts-elements'>
                        <a href={`mailto:${card.email}`}>{card.email}</a>
                    </div>
                </div>}
            </div>
        )
    };

    return (
        <Layout>
            <div className="container-fluid col-md-6 offset-md-3 flex-grow-2">
                <ToastContainer autoClose={1800} />
                <h1 className="p-2 text-center">Contacts</h1>
                <div className="loader">
                    < BallBeat margin={'auto'} loading={usersLoader || bloodHandlersLoader ? true : false} />
                </div>
                {!usersLoader && !bloodHandlersLoader &&
                    <form>
                        {userTypeSelect()}
                        {typeId === 1 && userSelect()}
                        {typeId === 2 && bloodDogHandlerSelect()}
                        {card !== null && contactCard()}
                    </form>
                }

            </div>
        </Layout>
    );
};

Contacts.propTypes = {
    usersData: PropTypes.arrayOf(
        PropTypes.object
    ),
    bloodHandlersData: PropTypes.arrayOf(
        PropTypes.object
    ),
    cityId: PropTypes.number,
    departmentId: PropTypes.number,
    typeId: PropTypes.number,
    card: PropTypes.object,

    loadUsers: PropTypes.func,
    loadBloodHandlers: PropTypes.func,
    changeValue: PropTypes.func,
    usersLoader: PropTypes.bool,
    bloodHandlersLoader: PropTypes.bool,
};

export default Contacts;
