import { connect } from 'react-redux';

// import { loadProfilId } from 'src/actions/profil';
import {changeValue, submitReset } from 'services/ducks/modules/auth';

import Reset from 'scenes/Auth/Reset/Reset';

const mapStateToProps = (state) => ({
  path: state.auth.path,
  city: state.auth.city,
  email: state.auth.email,
  token: state.auth.token,
  newPassword: state.auth.password,
  buttonText: state.auth.buttonText,
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: (name, value) => {
    dispatch(changeValue(name, value));
  },
  submitReset: (history) => {
    dispatch(submitReset(history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reset);